import React, { FC, useEffect } from 'react';
import { Toast } from '.';
import { useStores } from '../../stores/index';
import { IToast } from '../../types';
import { observer } from 'mobx-react';

const ToastProvider: FC = observer(() => {
  const {
    toastStore: { toasts, closeToast, closeToasts },
  } = useStores();
  useEffect(() => {
    return () => {
      closeToasts();
    };
  }, [closeToasts]);

  const onCloseToastClick = (index: number) => {
    closeToast(index);
  };

  return (
    <div>
      {toasts.map((toast: IToast, index: number) => (
        <Toast
          id={toast.id}
          onCloseClick={onCloseToastClick}
          key={toast.id}
          index={index}
          type={toast.type}
          msg={toast.msg}
        />
      ))}
    </div>
  );
});

export { ToastProvider };
