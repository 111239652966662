import React, { FC, useCallback } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { NotificationFormValue, NotificationTarget, ScreenAction } from '../../types';
import { NotificationForm } from './NotificationForm';

export const NewNotificationScreen: FC = observer(() => {
  const {
    notificationStore: { createNotification },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    async (values: NotificationFormValue) => {
      return createNotification(values).then((notificationId) => {
        if (notificationId) {
          skipCheck(() => history.push(ROUTE.notification.link(notificationId)));
        }
      });
    },
    [createNotification, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: NotificationFormValue = {
    submitType: 'draft',
    title: '',
    message: '',
    link: null,
    target: NotificationTarget.All,
    scheduledDateTime: DateTime.local().set({
      minute: Math.floor(DateTime.local().minute / 5) * 5,
      second: 0,
      millisecond: 0,
    }),
  };

  return (
    <Layout allowOverflow title={t('notification.addNew')} screenActions={actions}>
      <NotificationForm initial={initial} onSubmit={onSubmit} />
    </Layout>
  );
});
