import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, CircularProgress, styled } from '@material-ui/core';
import { Add, Delete, Edit, RestoreFromTrash, Star, StarOutline } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { QuestionHighlightDialog } from '../../components/Dialog';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { TagState, QuestionTagTableRow, MaterialTableCell, ScreenAction } from '../../types';

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const QuestionTagsScreen: FC = observer(() => {
  const {
    tagStore: { tags, fetchTagsIfNotFetched },
    questionStore: { questionTags, questionTagsByState, stateFilter, setStateFilter, updateTagInfo },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [toDelete, setToDelete] = useState<QuestionTagTableRow | null>(null);
  const [toHighlight, setToHighlight] = useState<QuestionTagTableRow | null>(null);

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  const fetching = questionTags.state === 'Fetching' || tags.state === 'Fetching';

  const data: QuestionTagTableRow[] = useMemo(() => {
    return questionTagsByState.state !== 'Fetched' || tags.state !== 'Fetched'
      ? []
      : questionTagsByState.data[stateFilter];
  }, [questionTagsByState, stateFilter, tags]);

  const actions: ScreenAction[] = [
    {
      label: t('questionTag.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newQuestionTag.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: t('questionTag.name'),
      },
      ...(stateFilter === TagState.Active
        ? [
            {
              accessor: 'scheduledDateTime',
              Header: t('questionTag.list.scheduledDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<QuestionTagTableRow, 'scheduledDateTime'>) => {
                return value ? value.toFormat("d.L.yyyy 'klo' H:mm") : '';
              },
            },
          ]
        : []),

      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<QuestionTagTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton
                size={40}
                icon={original.active ? Delete : RestoreFromTrash}
                onClick={() => setToDelete(original)}
              />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.questionTag.link(original.id))} />
              {original.active && (
                <IconButton
                  size={40}
                  icon={original.scheduledDateTime ? StarOutline : Star}
                  onClick={() => setToHighlight(original)}
                />
              )}
            </IconButtonRow>
          );
        },
      },
    ],
    [stateFilter, history, t],
  );

  const deleteModal =
    toDelete === null ? null : (
      <ConfirmDialog
        open
        title={t(`questionTag.list.${toDelete.active ? 'deactivate' : 'activate'}Message`)}
        message={toDelete.name}
        onAccept={() => {
          setToDelete(null);
          updateTagInfo(toDelete.id, { scheduledDateTime: null, active: !toDelete.active });
        }}
        onCancel={() => setToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}
      <QuestionHighlightDialog
        item={toHighlight}
        onClose={() => setToHighlight(null)}
        onAccept={(dateTime) => {
          setToHighlight(null);
          updateTagInfo(toHighlight!.id, { ...toHighlight, scheduledDateTime: dateTime });
        }}
      />

      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <Tabs value={stateFilter} onChange={(ev, value) => setStateFilter(value)}>
            <Tab label={t('questionTag.list.active')} value={TagState.Active} />
            <Tab label={t('questionTag.list.inactive')} value={TagState.Inactive} />
          </Tabs>
          <TableContainer>
            <MaterialTable
              data={data}
              columns={columns}
              onPageChange={() => {}}
              pagination={null}
              onSortingChange={() => {}}
              initialSortBy={[{ id: 'createdAt', desc: false }]}
            />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});
