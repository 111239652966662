import React, { FC, useEffect, useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import styled from '@material-ui/core/styles/styled';
import { Add, Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { PollId, PollListItem, ScreenAction } from '../../types';

interface MaterialTableCell<T, K extends keyof T | undefined = undefined> {
  cell: {
    value: K extends keyof T ? T[K] : undefined;
  };
  row: {
    original: T;
  };
}

interface PollTableRow {
  id: PollId;
  title: string;
}

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const PollsScreen: FC = observer(() => {
  const {
    pollStore: { polls, fetchPolls, deletePoll },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [pollToDelete, setPollToDelete] = useState<PollTableRow | null>(null);

  useEffect(() => {
    fetchPolls();
  }, [fetchPolls]);

  const data = polls.state === 'Fetched' ? polls.data : [];

  const fetching = polls.state === 'Fetching';

  const actions: ScreenAction[] = [
    {
      label: t('poll.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newPoll.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        Header: t('poll.title'),
      },
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<PollListItem>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setPollToDelete(original)} />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.poll.link(original.id))} />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t],
  );

  const deleteModal =
    pollToDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('poll.list.deleteMessage')}
        message={pollToDelete.title}
        onAccept={() => {
          setPollToDelete(null);
          deletePoll(pollToDelete.id);
        }}
        onCancel={() => setPollToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}
      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <TableContainer>
          <MaterialTable
            data={data}
            columns={columns}
            onPageChange={() => {}}
            pagination={null}
            onSortingChange={() => {}}
            initialSortBy={[{ id: 'createdAt', desc: false }]}
          />
        </TableContainer>
      )}
    </Layout>
  );
});
