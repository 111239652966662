import { AuthStore } from './AuthStore';
import { TagStore } from './TagStore';
import { TipStore } from './TipStore';
import { PollStore } from './PollStore';
import { SettingsStore } from './SettingsStore';
import { NotificationStore } from './NotificationStore';
import { HolidayTipStore } from './HolidayTipStore';
import { QuestionStore } from './QuestionStore';
import { PartnerStore } from './PartnerStore';
import { AdStore } from './AdStore';
import { MembershipBenefitStore } from './MembershipBenefitStore';
import ToastStore from './ToastStore';
import UnsavedWarningStore from './UnsavedWarningStore';

export class RootStore {
  public toastStore: typeof ToastStore;
  public unsavedWarningStore: UnsavedWarningStore;
  public authStore: AuthStore;
  public tipStore: TipStore;
  public tagStore: TagStore;
  public pollStore: PollStore;
  public settingsStore: SettingsStore;
  public notificationStore: NotificationStore;
  public holidayTipStore: HolidayTipStore;
  public questionStore: QuestionStore;
  public partnerStore: PartnerStore;
  public adStore: AdStore;
  public membershipBenefitStore: MembershipBenefitStore;

  constructor() {
    this.toastStore = ToastStore;
    this.unsavedWarningStore = new UnsavedWarningStore();
    this.authStore = new AuthStore(() => this.resetState());
    this.tagStore = new TagStore(this);
    this.tipStore = new TipStore();
    this.pollStore = new PollStore();
    this.settingsStore = new SettingsStore();
    this.notificationStore = new NotificationStore();
    this.holidayTipStore = new HolidayTipStore();
    this.questionStore = new QuestionStore(this);
    this.partnerStore = new PartnerStore();
    this.adStore = new AdStore();
    this.membershipBenefitStore = new MembershipBenefitStore();
  }

  resetState = () => {
    this.unsavedWarningStore = new UnsavedWarningStore();
    this.authStore = new AuthStore(() => this.resetState());
    this.tagStore = new TagStore(this);
    this.tipStore = new TipStore();
    this.pollStore = new PollStore();
    this.settingsStore = new SettingsStore();
    this.notificationStore = new NotificationStore();
    this.holidayTipStore = new HolidayTipStore();
    this.questionStore = new QuestionStore(this);
    this.partnerStore = new PartnerStore();
    this.adStore = new AdStore();
    this.membershipBenefitStore = new MembershipBenefitStore();
  };
}

const stores = new RootStore();
export default stores;
