import { fromPairs, groupBy } from 'lodash/fp';
import { DateTime } from 'luxon';

export enum TagCategory {
  Recipe = 'recipe',
  Tip = 'tip',
  HolidayTip = 'holidayTip',
}

export enum HolidayTagGroupKey {
  Christmas = 'christmas',
  Spring = 'spring',
  Summer = 'summer',
  Mayday = 'mayday',
  Laskiainen = 'laskiainen',
  Mothersday = 'mothersday',
  Fathersday = 'fathersday',
  Midsummer = 'midsummer',
  Independence = 'independence',
  Harvest = 'harvest',
  Forest = 'forest',
}

export enum TagGroupKey {
  Ingredient = 'ingredient',
  Diet = 'diet',
  Various = 'various',
  Home = 'home',
  Garden = 'garden',
  Question = 'question',
  Food = 'food',
  Christmas = 'christmas',
  NewYear = 'newyear',
  Easter = 'easter',
  MayDay = 'mayday',
  MothersDay = 'mothersday',
  Midsummer = 'midsummer',
  FathersDay = 'fathersday',
  IndependenceDay = 'independenceday',
  Children = 'childrensparty',
  Crafts = 'crafts',
}

export type TagGroupId = number;
export interface TagGroup {
  readonly id: TagGroupId;
  readonly key: TagGroupKey;
  readonly name: string;
  readonly category: TagCategory;
}

export type TagId = number;

export enum TagState {
  Active = 'active',
  Inactive = 'inactive',
}
export interface Tag {
  readonly id: TagId;
  readonly groupId: TagGroupId;
  readonly name: string;
  readonly image: string;
  readonly imageUrl: string;
  readonly active: boolean;
  readonly scheduledDateTime: DateTime | null;
}

export interface TagData {
  readonly tags: Tag[];
  readonly groups: TagGroup[];
  readonly groupsByGroupKey: Record<TagGroupKey, TagGroup>;
  readonly tagsByGroupKey: Record<TagGroupKey, Tag[]>;
}

export const makeTagData = (tags: Tag[], groups: TagGroup[]): TagData => ({
  groups,
  tags,
  groupsByGroupKey: fromPairs(groups.map((g) => [g.key, g])) as TagData['groupsByGroupKey'],
  tagsByGroupKey: groupBy((t) => groups.find((g) => t.groupId === g.id)?.key, tags) as TagData['tagsByGroupKey'],
});
