import { ApiClient } from '../client';
import { PATHS } from './paths';

interface LogoutResponse {
  logoutUrl: string;
}

// Logs user out from salesforce using iframe
export const logout = async (): Promise<void> => {
  const response = await ApiClient.get<LogoutResponse>(PATHS.auth.logout);

  // Hax: Does the salesforce logout in the iframe
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', response.data.logoutUrl);
  iframe.style.visibility = 'hidden';
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  /*setTimeout(() => {
    document.body.removeChild(iframe);
  }, 5000);*/
};
