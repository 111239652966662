import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { styled, TextField as MuiTextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';
import { COLORS } from '../../styles';

interface InputFieldProps {
  validate?: FieldValidator<any>;
  name: string;
  type?: string;
  id: string;
  label?: string;
  required?: boolean;
  rows?: number;
  placeholder?: string;
}

const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey,
  },
});

export const InputField: FC<InputFieldProps> = ({
  validate,
  name,
  id,
  label,
  required,
  rows,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Field name={name} required={required} validate={validate}>
      {({ input, meta }) => (
        <span>
          {label ? <Typography variant="h3">{`${label}${required ? '*' : ''}`}</Typography> : null}
          <TextField
            {...input}
            id={id}
            variant="outlined"
            fullWidth
            required={required}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error && t(meta.error)}
            rows={rows}
            multiline={!!rows}
            placeholder={placeholder}
            {...rest}
          />
        </span>
      )}
    </Field>
  );
};
