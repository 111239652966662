import { Poll, PollId, PollListItem } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getPolls = async (): Promise<PollListItem[]> => {
  const result = await ApiClient.get<PollListItem[]>(PATHS.polls.root);
  return result.data;
};

export const getPoll = async (id: PollId): Promise<Poll> => {
  const result = await ApiClient.get<Poll>(PATHS.polls.single(id));
  return result.data;
};

export interface PollUpdateRequest {
  readonly title: string;
  readonly infoText: string | null;
  readonly link: string | null;
  readonly options: string[];
}

export const createPoll = async (request: PollUpdateRequest): Promise<PollId> => {
  const result = await ApiClient.post<{ id: number }>(PATHS.polls.root, request);
  return result.data.id;
};

export const updatePoll = async (id: PollId, request: PollUpdateRequest): Promise<Poll> => {
  const result = await ApiClient.put<any>(PATHS.polls.single(id), request);
  return result.data;
};

export const deletePoll = async (id: PollId): Promise<void> => {
  await ApiClient.delete(PATHS.polls.single(id));
};
