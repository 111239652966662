import { DateTime } from 'luxon';
import { ExistingImage, FileWithPreview } from '../components/FormControls';

export enum PartnerState {
  Active = 'active',
  Scheduled = 'scheduled',
  Draft = 'draft',
  Finished = 'finished',
}

export type PartnerId = number;

export interface PartnerListItem {
  readonly id: PartnerId;
  readonly title: string;
  readonly scheduledStartDateTime: DateTime | null;
  readonly scheduledEndDateTime: DateTime | null;
}

export interface PartnerTableRow {
  id: PartnerId;
  title: string;
  scheduledStartDateTime: DateTime | null;
  scheduledEndDateTime: DateTime | null;
}

export type PartnerElement = {
  readonly order: number;
  readonly text: string;
  readonly image: string;
  readonly imageUrl: string;
};

export type PartnerAnalyticsSummary = {
  readonly seenTotal: number;
  readonly swipeTotal: number;
  readonly linkTotal: number;

  readonly seenUnique: number;
  readonly swipeUnique: number;
  readonly linkUnique: number;
};

export interface Partner {
  readonly id: PartnerId;
  readonly title: string;
  readonly shortDescription: string;
  readonly longDescription: string;
  readonly link: string;
  readonly linkText: string;
  readonly image: string;
  readonly imageUrl: string;
  readonly scheduledStartDateTime: DateTime | null;
  readonly scheduledEndDateTime: DateTime | null;
  readonly elements: PartnerElement[];
  readonly analyticsSummary: PartnerAnalyticsSummary;
}

export type PartnerFormValueElement = {
  readonly text: string;
  readonly image: FileWithPreview | ExistingImage | null;
};

export interface PartnerFormValue {
  readonly submitType: 'draft' | 'schedule';
  readonly title: string;
  readonly shortDescription: string;
  readonly longDescription: string;
  readonly link: string;
  readonly linkText: string;
  readonly image: FileWithPreview | ExistingImage | null;
  readonly scheduledStartDateTime: DateTime | null;
  readonly scheduledEndDateTime: DateTime | null;
  readonly elements: PartnerFormValueElement[];
}

export type ScheduledDateTimes = {
  readonly startDateTime: DateTime;
  readonly endDateTime: DateTime;
};

export type CopyPartnersParams = {
  readonly ids: PartnerId[];
};
