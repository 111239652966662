import { styled, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { COLORS } from '../../styles';
import { MembershipBenefitFormValue } from '../../types';

const Container = styled('div')({
  position: 'absolute',
  bottom: 20,
  right: 20,
});

const Card = styled('div')({
  backgroundColor: COLORS.primary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 320,
  minHeight: 194,
  position: 'relative',
  borderRadius: 5,
  filter: 'drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.25))',
});

const Image = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: 5,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: -1,
});

const ImagePlaceholder = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: 5,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const Inner = styled('div')({
  background: 'rgba(240, 199, 222, 0.85)',
  width: 260,
  minHeight: 126,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 4,
  padding: '26px 32px',
  margin: '34px 30px',
});

const Text = styled(Typography)({
  color: '#3E3E3E',
  fontFamily: 'Rooney',
  fontSize: 18,
  lineHeight: '115%',
  textAlign: 'center',
});

const Button = styled(Typography)({
  backgroundColor: COLORS.primary,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  color: COLORS.white,
  fontSize: 14,
  fontWeight: 700,
  padding: '6px 24px',
  marginTop: 12,
  borderRadius: 100,
  textAlign: 'center',
});

export const MembershipBenefitCardPreview: FC<MembershipBenefitFormValue> = ({ text, buttonText, image }) => (
  <Container>
    <Card>
      {image ? <Image src={image.valueType === 'existing' ? image.url : image.preview} /> : <ImagePlaceholder />}
      <Inner>
        <Text>{text}</Text>
        <Button>{buttonText}</Button>
      </Inner>
    </Card>
  </Container>
);
