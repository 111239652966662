import React, { FC } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { PartnerAnalyticsSummary } from '../../types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  tableContainer: {
    margin: '20px 0',
    width: '66.6%',
  },
  tableHead: {
    backgroundColor: '#fafafa',
    fontWeight: 'bold',
  },
  tableBody: {
    backgroundColor: '#fff',
  },
});

type Props = {
  analyticsSummary: PartnerAnalyticsSummary;
};

export const PartnerAnalyticsSummaryTable: FC<Props> = ({ analyticsSummary }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const rows = [
    { title: t('partner.analytics.seen'), total: analyticsSummary.seenTotal, unique: analyticsSummary.seenUnique },
    { title: t('partner.analytics.swipe'), total: analyticsSummary.swipeTotal, unique: analyticsSummary.swipeUnique },
    { title: t('partner.analytics.link'), total: analyticsSummary.linkTotal, unique: analyticsSummary.linkUnique },
  ];

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableHead}>
            <TableCell className={classes.tableHead} />
            <TableCell className={classes.tableHead}>{t('partner.analytics.total')}</TableCell>
            <TableCell className={classes.tableHead}>{t('partner.analytics.unique')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {rows.map((it) => (
            <TableRow key={it.title}>
              <TableCell>{it.title}</TableCell>
              <TableCell>{it.total}</TableCell>
              <TableCell>{it.unique}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
