import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFilters, useSortBy, useTable, useRowSelect } from 'react-table';
import { COLORS } from '../../styles';
import FilterField from './MaterialTableFilterField';
import TableHead from './MaterialTableHead';
import Pagination from './MaterialTablePagination';
import Spinner from './MaterialTableSpinner';

const useStyles = makeStyles({
  spinner: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  pagination: {
    backgroundColor: '#fff',
  },
  container: {
    maxHeight: '100%',
  },
  root: {
    width: '100%',
  },
});

const MaterialTable = ({
  data,
  columns,
  totalCount,
  onPageChange,
  onSortingChange,
  onFilteringChange,
  onRowSelect,
  isLoading,
  pagination,
  manualSortBy,
  initialSortBy,
}) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: FilterField,
    }),
    [],
  );

  const defaultSortBy = useMemo(() => initialSortBy, [initialSortBy]);
  const sortTypes = useMemo(
    () => ({
      luxondate: (a, b, column) => {
        const aVal = a.values[column];
        const bVal = b.values[column];
        return aVal.toMillis() - bVal.toMillis();
      },
    }),
    [],
  );

  const plugins = [];
  const initialState = {};

  if (onFilteringChange) {
    plugins.push(useFilters);
    initialState.filters = [];
  }

  if (onSortingChange) {
    plugins.push(useSortBy);
    initialState.sortBy = defaultSortBy;
  }

  if (onRowSelect) plugins.push(useRowSelect);

  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { sortBy, filters },
  } = useTable(
    {
      data,
      columns,
      manualSortBy: !!onSortingChange && manualSortBy,
      manualPagination: true,
      manualFilters: !!onFilteringChange,
      pageCount: totalCount,
      autoResetPage: false,
      defaultColumn,
      initialState,
      sortTypes,
    },
    ...plugins,
    (hooks) => {
      if (!onRowSelect) return;
      // Create selection column for header and all rows
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          width: '1%',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  /**
   * When sorting changes..
   */
  useEffect(() => {
    if (onSortingChange && sortBy.length) onSortingChange({ sortBy });
  }, [onSortingChange, sortBy]);

  /**
   * When filters changes ..
   */
  useEffect(() => {
    if (onFilteringChange) onFilteringChange(filters);
  }, [filters, onFilteringChange]);

  /**
   * When row selection changes ..
   */
  useEffect(() => {
    if (onRowSelect) onRowSelect(selectedFlatRows);
  }, [selectedFlatRows, onRowSelect]);

  const setPageSize = useCallback((size) => onPageChange({ pageSize: size, pageIndex: pagination.pageIndex }), [
    pagination,
    onPageChange,
  ]);
  const gotoPage = useCallback((page) => onPageChange({ pageSize: pagination.pageSize, pageIndex: page }), [
    pagination,
    onPageChange,
  ]);

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader aria-label="sticky table" {...getTableProps()} className={classes.table}>
          <TableHead
            onFilteringChange={onFilteringChange}
            onSortingChange={onSortingChange}
            headerGroups={headerGroups}
          />
          <TableBody {...getTableBodyProps()}>
            {isLoading && <Spinner />}
            {!isLoading &&
              rows.map((row) => {
                prepareRow(row);
                const backgroundColor =
                  row.original.scheduledDateTime && row.original.active ? COLORS.secondaryLighter : '#fff';
                return (
                  <TableRow hover key={row.id} {...row.getRowProps()} style={{ backgroundColor }}>
                    {row.cells.map((cell) => (
                      <TableCell
                        width={cell.column.width}
                        key={`${cell.column.id}-${cell.row.id}`}
                        className={cell.column.className}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination ? (
        <Pagination
          totalCount={totalCount}
          page={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          className={classes.pagination}
        />
      ) : null}
    </div>
  );
};

MaterialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func,
  onFilteringChange: PropTypes.func,
  onRowSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  defaultPageSize: PropTypes.number,
  manualSortBy: PropTypes.bool,
  initialSortBy: PropTypes.arrayOf(PropTypes.shape({})),
};

MaterialTable.defaultProps = {
  data: [],
  columns: [],
  onSortingChange: null,
  onFilteringChange: null,
  onRowSelect: null,
  isLoading: false,
  totalCount: 0,
  defaultPageSize: 10,
  manualSortBy: false,
  initialSortBy: [],
};

export default MaterialTable;
