import React, { FC } from 'react';
import styled from '@material-ui/core/styles/styled';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PollOption } from '../../types/Poll';
import { COLORS } from '../../styles';

const Container = styled('div')({
  position: 'absolute',
  bottom: 50,
  right: 50,
  backgroundColor: COLORS.boxBackgroundGrey,
  borderRadius: 5,
  padding: 20,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.33)',
});

type Props = {
  options: PollOption[];
};

export const Results: FC<Props> = ({ options }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Typography variant="h3">{t('poll.results')}</Typography>
      <ol>
        {options.map((it, i) => (
          <li key={i}>
            <Typography>{`${it?.title || ''}: ${it?.answers || ''} (${it?.percentage ? Number(it?.percentage)?.toFixed(1) : 0}%)`}</Typography>
          </li>
        ))}
      </ol>
    </Container>
  );
};
