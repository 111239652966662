import { observer } from 'mobx-react';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { useStores } from '../../stores';

export const BaseForm = ({
  children,
  ...props
}: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) => {
  return (
    <form {...props}>
      <UnsavedWarningFormSpy />
      {children}
    </form>
  );
};

export const UnsavedWarningFormSpy = observer(() => {
  const {
    unsavedWarningStore: { setUnsavedWarningState },
  } = useStores();

  return <FormSpy subscription={{ pristine: true }} onChange={(props) => setUnsavedWarningState(!props.pristine)} />;
});
