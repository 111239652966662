import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Divider, TextField, Typography } from '@material-ui/core';
import { ComboBox } from '../../components';
import { holidayOptions, HolidayTags } from './EditTagGroupScreen';
import { HolidayCardPreview } from './HolidayCardPreview';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TagGroupWithTags } from './EditTagGroupsScreen';
import { ApiClient } from '../../services/client';
import { history } from '../../App';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';

export const TextInput = (props: {
  label: string;
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
}) => {
  return (
    <span>
      {props.label ? <Typography variant="h3">{props.label}</Typography> : null}
      <TextField
        size="small"
        variant="outlined"
        fullWidth
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </span>
  );
};

const mutateNewGroup = async (data: { body: Partial<TagGroupWithTags>; id: string }) => {
  const response = await ApiClient.put<TagGroupWithTags>(`/admin/tagGroups/v1/group`, data.body);
  return response.data;
};

const mutateGroup = async (data: { body: Partial<TagGroupWithTags>; id: string }) => {
  const response = await ApiClient.patch<TagGroupWithTags>(`/admin/tagGroups/v1/group/${data.id}`, data.body);
  return response.data;
};

const TagGroupForm = (props: { body?: Partial<TagGroupWithTags>; id?: string; create: boolean }) => {
  const queryClient = useQueryClient();
  const { tagStore } = useStores();
  const { t } = useTranslation();

  const [errors, setErrors] = useState<string[]>([]);
  const [name, setName] = useState(props.body?.name ?? '');
  const [title, setTitle] = useState(props.body?.title ?? '');
  const [text, setText] = useState(props.body?.text ?? '');
  const [button, setButton] = useState(props.body?.button ?? '');
  const [selectedKey, setSelectedKey] = useState<HolidayTags | { key: null; text: string; id: number }>({
    key: props.body?.key ?? null,
    text: holidayOptions.find((a) => a.key === props.body?.key)?.text ?? 'Valitse',
    id: 999999999999999,
  });

  useEffect(() => {
    setName(props.body?.name ?? '');
    setTitle(props.body?.title ?? '');
    setText(props.body?.text ?? '');
    setButton(props.body?.button ?? '');
    setSelectedKey({
      key: props.body?.key ?? null,
      text: holidayOptions.find((a) => a.key === props.body?.key)?.text ?? 'Valitse',
      id: 128287,
    });
  }, [props.body]);

  const mutation = useMutation({
    mutationFn: props.create ? mutateNewGroup : mutateGroup,
    onSuccess: (data) => {
      if (props.create) {
        history.push(ROUTE.editTagGroup.link(data.id));
      }
      tagStore.refetchTags();
      queryClient.invalidateQueries(['tagGroup', data.id]);
    },
  });

  const handleSave = async () => {
    setErrors([]);
    let errors: string[] = [];
    if (name.length < 1) errors.push(t('editTagGroup.validation.name'));
    if (title.length < 1) errors.push(t('editTagGroup.validation.title'));
    if (text.length < 1) errors.push(t('editTagGroup.validation.lead'));
    if (button.length < 1) errors.push(t('editTagGroup.validation.button'));
    if (selectedKey.key === null) errors.push(t('editTagGroup.validation.selectedKey'));

    if (errors.length > 0) {
      return setErrors(errors);
    }

    mutation.mutate({
      id: props.id ?? 'ei ole',
      body: {
        name,
        title,
        text,
        button,
        key: selectedKey.key ?? undefined,
        image: 'placeholder.png',
      },
    });
  };

  const setHoliday = (holiday: HolidayTags | { key: null; text: string; id: number } | null) => {
    if (holiday === null) return;
    setSelectedKey(holiday);
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '35px' }}>
        <div style={{ display: 'flex', gap: '15px', flexDirection: 'column', width: '80%', maxWidth: '500px' }}>
          <TextInput
            label={t('editTagGroup.form.categoryName')}
            onChange={(text) => setName(text)}
            placeholder={t('editTagGroup.form.categoryNamePlaceholder')}
            value={name}
          />
          <Divider style={{ marginTop: 10 }} />
          <span>
            <Typography variant="h3">
              {t('editTagGroup.form.holidayStyle')}
            </Typography>
            <ComboBox
              placeholder={t('editTagGroup.form.holidayStylePlaceholder')}
              value={selectedKey}
              options={holidayOptions}
              getOptionSelected={(a, b) => a?.key === b?.key}
              getOptionLabel={(g) => g.text}
              onChange={(value) => setHoliday(value ?? null)}
              narrow
            />
          </span>
          <TextInput
            onChange={(text) => setTitle(text)}
            label={t('editTagGroup.form.title')}
            value={title}
            placeholder={t('editTagGroup.form.titlePlaceholder')}
          />
          <TextInput
            onChange={(text) => setText(text)}
            label={t('editTagGroup.form.lead')}
            value={text}
            placeholder={t('editTagGroup.form.leadPlaceholder')}
          />
          <TextInput
            onChange={(text) => setButton(text)}
            label={t('editTagGroup.form.button')}
            value={button}
            placeholder={t('editTagGroup.form.buttonPlaceholder')}
          />
          {errors.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 20 }}>
              {errors.map((a) => (
                <Typography variant="h3" color="error">
                  {a}
                </Typography>
              ))}
            </div>
          )}
          <Button
            size="large"
            type={'button'}
            variant="contained"
            color="primary"
            disabled={mutation.isLoading}
            disableElevation
            onClick={handleSave}
          >
            {mutation.isLoading && <CircularProgress size={24} color="primary" />}
            {props.create ? t('editTagGroup.form.create') : t('editTagGroup.form.save')}
          </Button>
        </div>
        {selectedKey.key !== null && (
          <HolidayCardPreview activeGroup={selectedKey.key} title={title} text={text} button={button} />
        )}
      </div>
    </div>
  );
};

export { TagGroupForm };
