import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';

import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { QuestionTagFormValue } from '../../types';
import { QuestionTagForm } from './QuestionTagForm';

export const QuestionTagScreen: FC = observer(() => {
  const {
    questionStore: { questionTag, fetchQuestionTag, updateQuestionTag },
    tagStore: { tags, fetchTagsIfNotFetched },
  } = useStores();

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  useEffect(() => {
    if (id) fetchQuestionTag(id);
  }, [id, fetchQuestionTag]);

  const onSubmit = useCallback(
    async (values: QuestionTagFormValue): Promise<void> => {
      if (id) {
        return await updateQuestionTag(id, values);
      }
    },
    [id, updateQuestionTag],
  );

  const fetching = questionTag.state === 'Fetching' || tags.state === 'Fetching';

  const formData: [QuestionTagFormValue] | null = useMemo(() => {
    if (questionTag.state !== 'Fetched') return null;

    const questionTagFormValue: QuestionTagFormValue = {
      name: questionTag.data.name,
      image: { valueType: 'existing', name: questionTag.data.image, url: questionTag.data.imageUrl },
    };

    return [questionTagFormValue];
  }, [questionTag]);

  return (
    <Layout allowOverflow title={questionTag.state === 'Fetched' ? questionTag.data.name : ''}>
      {(() => {
        if (fetching) return <CircularProgress size="3rem" />;
        if (id === null || formData === null) return null;

        return (
          <>
            <QuestionTagForm initial={formData[0]} onSubmit={onSubmit} />
          </>
        );
      })()}
    </Layout>
  );
});
