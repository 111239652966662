import {
  CakeOutlined,
  CakeTwoTone,
  CardMembershipOutlined,
  EmojiObjectsOutlined,
  FlashOnOutlined,
  HomeOutlined,
  LinkOutlined,
  NotificationsOutlined,
  PeopleOutlined,
  StorefrontOutlined,
  WebOutlined,
} from '@material-ui/icons';
import { FrontPageScreen } from '../screens/frontPage/FrontPageScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { NewPollScreen, PollScreen, PollsScreen } from '../screens/polls';
import { NewNotificationScreen, NotificationScreen, NotificationsScreen } from '../screens/notifications';
import { NewTipScreen, TipScreen, TipsScreen } from '../screens/tips';
import { HolidayTipScreen, HolidayTipsScreen, NewHolidayTipScreen } from '../screens/holidayTips';
import { NewQuestionTagScreen, QuestionTagScreen, QuestionTagsScreen } from '../screens/question';
import { NewPartnerScreen, PartnerScreen, PartnersScreen } from '../screens/partners';
import { DynamicLinkScreen } from '../screens';
import { AdScreen, AdsScreen, NewAdScreen } from '../screens/ads';
import {
  MembershipBenefitScreen,
  MembershipBenefitsScreen,
  NewMembershipBenefitScreen,
} from '../screens/membershipBenefits';

import { AdminRole, Route } from '../types';
import { EditTagGroupsScreen } from '../screens/editTags/EditTagGroupsScreen';
import { EditTagGroupScreen } from '../screens/editTags/EditTagGroupScreen';
import { NewTagGroupScreen } from '../screens/editTags/NewTagGroupScreen';

export const ROUTE = {
  login: Route.create({
    name: 'login',
    path: '/login',
    component: LoginScreen,
  }),
  newTip: Route.create({
    name: 'tip',
    path: '/vinkit/uusi',
    component: NewTipScreen,
  }),
  tip: Route.create({
    name: 'tip',
    path: '/vinkit',
    params: ['id'],
    component: TipScreen,
  }),
  tips: Route.create({
    name: 'tips',
    path: '/vinkit',
    component: TipsScreen,
    icon: EmojiObjectsOutlined,
    menuItem: true,
  }),
  newHolidayTip: Route.create({
    name: 'holidayTip',
    path: '/juhlat/uusi',
    component: NewHolidayTipScreen,
  }),
  holidayTip: Route.create({
    name: 'holidayTip',
    path: '/juhlat',
    params: ['id'],
    component: HolidayTipScreen,
  }),
  holidayTips: Route.create({
    name: 'holidayTips',
    path: '/juhlat',
    component: HolidayTipsScreen,
    icon: CakeOutlined,
    menuItem: true,
  }),
  editTags: Route.create({
    name: 'editTagGroups',
    path: '/kategoriat',
    component: EditTagGroupsScreen,
    icon: CakeTwoTone,
    menuItem: true,
  }),
  newPoll: Route.create({
    name: 'poll',
    path: '/pikakyselyt/uusi',
    component: NewPollScreen,
  }),
  poll: Route.create({
    name: 'poll',
    path: '/pikakyselyt',
    params: ['id'],
    component: PollScreen,
  }),
  polls: Route.create({
    name: 'polls',
    path: '/pikakyselyt',
    component: PollsScreen,
    icon: FlashOnOutlined,
    menuItem: true,
  }),
  frontPage: Route.create({
    name: 'frontPage',
    path: '/etusivu',
    component: FrontPageScreen,
    icon: HomeOutlined,
    menuItem: true,
    managerOnly: true,
  }),
  newNotification: Route.create({
    name: 'notification',
    path: '/push-viestit/uusi',
    component: NewNotificationScreen,
  }),
  notification: Route.create({
    name: 'notification',
    path: '/push-viestit',
    params: ['id'],
    component: NotificationScreen,
  }),
  notifications: Route.create({
    name: 'notifications',
    path: '/push-viestit',
    component: NotificationsScreen,
    icon: NotificationsOutlined,
    menuItem: true,
  }),
  newQuestionTag: Route.create({
    name: 'questionTag',
    path: '/yhteiso/uusi',
    component: NewQuestionTagScreen,
  }),
  questionTag: Route.create({
    name: 'questionTag',
    path: '/yhteiso',
    params: ['id'],
    component: QuestionTagScreen,
  }),
  questionTags: Route.create({
    name: 'questionTags',
    path: '/yhteiso',
    component: QuestionTagsScreen,
    icon: PeopleOutlined,
    menuItem: true,
  }),
  newPartner: Route.create({
    name: 'partner',
    path: '/kumppanisisalto/uusi',
    component: NewPartnerScreen,
  }),
  partner: Route.create({
    name: 'partner',
    path: '/kumppanisisalto',
    params: ['id'],
    component: PartnerScreen,
  }),
  partners: Route.create({
    name: 'partners',
    path: '/kumppanisisalto',
    component: PartnersScreen,
    icon: StorefrontOutlined,
    menuItem: true,
  }),
  dynamicLink: Route.create({
    name: 'dynamicLinkBuilder',
    path: '/linkitystyokalu',
    component: DynamicLinkScreen,
    icon: LinkOutlined,
    menuItem: true,
  }),
  ads: Route.create({
    name: 'ads',
    path: '/mainokset',
    component: AdsScreen,
    icon: WebOutlined,
    menuItem: true,
  }),
  ad: Route.create({
    name: 'ad',
    path: '/mainos',
    params: ['id'],
    component: AdScreen,
  }),
  newAd: Route.create({
    name: 'newAd',
    path: '/mainos',
    component: NewAdScreen,
  }),
  membershipBenefits: Route.create({
    name: 'membershipBenefits',
    path: '/jasenedut',
    component: MembershipBenefitsScreen,
    icon: CardMembershipOutlined,
    menuItem: true,
  }),
  membershipBenefit: Route.create({
    name: 'membershipBenefit',
    path: '/jasenetu',
    params: ['id'],
    component: MembershipBenefitScreen,
  }),
  newMembershipBenefit: Route.create({
    name: 'newMembershipBenefit',
    path: '/jasenetu',
    component: NewMembershipBenefitScreen,
  }),
  newTagGroup: Route.create({
    name: 'newTagGroup',
    path: '/kategoria/uusi',
    component: NewTagGroupScreen,
    menuItem: false,
  }),
  editTagGroup: Route.create({
    name: 'editTagGroup',
    path: '/kategoria',
    params: ['id'],
    component: EditTagGroupScreen,
    menuItem: false,
  }),
};

const ALL_ROUTES = Object.keys(ROUTE).map((k) => (ROUTE as any)[k]);
const ROUTES_FOR_EDITOR = ALL_ROUTES.filter((v) => !v.managerOnly);

export const ROUTES: (role: AdminRole) => Route[] = (role: AdminRole) =>
  role === AdminRole.Manager ? ALL_ROUTES : ROUTES_FOR_EDITOR;

export const MENU_ROUTES: (role: AdminRole) => Route[] = (role) => ROUTES(role).filter((r) => r.menuItem);
