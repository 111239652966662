import { AxiosError } from 'axios';
import { rootStore } from '../stores';
import ToastStore from '../stores/ToastStore';

const apiErrorHandler = (error: AxiosError) => {
  let msg: string | null = error.message;
  if (error.response) {
    const {
      response: { status },
    } = error;

    switch (status) {
      case 401:
        msg = null;
        rootStore.authStore.clearSession();
        break;
      case 400:
        break;
      case 500:
      case 404:
        break;
      default:
        break;
    }
  }
  if (msg) ToastStore.showError(msg);
  throw error;
};

export default apiErrorHandler;
