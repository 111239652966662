import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { MembershipBenefitFormValue, MembershipBenefitRole, ScreenAction } from '../../types';
import { MembershipBenefitForm } from './MembershipBenefitForm';

export const NewMembershipBenefitScreen: FC = observer(() => {
  const {
    membershipBenefitStore: { createMembershipBenefit },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: MembershipBenefitFormValue) => {
      return createMembershipBenefit(values).then((id) => {
        if (id) {
          skipCheck(() => history.push(ROUTE.membershipBenefit.link(id)));
        }
      });
    },
    [createMembershipBenefit, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: MembershipBenefitFormValue = {
    text: '',
    link: '',
    buttonText: '',
    role: MembershipBenefitRole.All,
    active: false,
    image: null,
  };

  return (
    <Layout allowOverflow title={t('membershipBenefit.addNew')} screenActions={actions}>
      <MembershipBenefitForm initial={initial} onSubmit={onSubmit} />
    </Layout>
  );
});
