import { createMuiTheme } from '@material-ui/core';
import { darken, lighten } from 'polished';

export const PRIMARY = '#286FB7';
export const SECONDARY = '#F0C7DE';
export const GREY = '#9E9E9E';
export const DARK_GREY = '#3E3E3E';

export const COLORS = {
  primary: PRIMARY,
  primaryDarker: darken(0.05, PRIMARY),
  primaryDarkest: darken(0.1, PRIMARY),
  primaryLighter: lighten(0.05, PRIMARY),
  primaryLightest: lighten(0.1, PRIMARY),
  primarySelected: '#A3BDE3',
  secondary: SECONDARY,
  secondaryDarker: darken(0.05, SECONDARY),
  secondaryDarkest: darken(0.1, SECONDARY),
  secondaryLighter: lighten(0.05, SECONDARY),
  secondaryLightest: lighten(0.1, SECONDARY),
  grey: GREY,
  greyDarker: darken(0.05, GREY),
  greyDarkest: darken(0.1, GREY),
  greyLighter: lighten(0.05, GREY),
  greyLightest: '#e1e1e1',
  boxBackgroundGrey: '#E9E8E4',
  white: '#ffffff',
  black: '#222222',
  toastGreen: '#4BB543',
  toastRed: '#da2b2b',
  orange: '#EB5C2E',
};

export const MUI_THEME = createMuiTheme({
  typography: {
    fontFamily: 'Nunito',
    htmlFontSize: 18,
    fontSize: 18,
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.33rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginBottom: '0.25rem',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: COLORS.primary,
      light: COLORS.primaryLighter,
      dark: COLORS.primaryDarker,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.secondary,
      light: COLORS.secondaryLighter,
      dark: COLORS.secondaryDarker,
      contrastText: COLORS.white,
    },
    text: {},
    grey: {
      50: lighten(0.1, GREY),
      100: lighten(0.05, GREY),
      200: GREY,
      300: darken(0.05, GREY),
      400: darken(0.1, GREY),
      500: darken(0.15, GREY),
      600: darken(0.2, GREY),
      700: darken(0.25, GREY),
      800: '#424242',
      900: '#212121',
      A100: GREY,
      A200: darken(0.05, GREY),
      A400: darken(0.1, GREY),
      A700: darken(0.15, GREY),
    },
  },
  overrides: {
    MuiTab: {
      root: {
        marginRight: '10px',
        backgroundColor: COLORS.greyLightest,
        opacity: '1 !important',
        '&$selected': {
          backgroundColor: COLORS.primarySelected,
        },
        width: '222px',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiButton: {
      outlined: {
        color: COLORS.white,
        backgroundColor: COLORS.primary,
        padding: '10px 28px',
        fontSize: '1rem',
        lineHeight: '25px',
        '&:hover': {
          backgroundColor: COLORS.primaryLightest,
        },
      },
    },
    MuiSelect: {
      outlined: {
        padding: '14px 14px',
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: 'none',
        border: `1px solid ${COLORS.grey}`,
        borderRadius: 0,
        '& .cellBorderLeft': {
          borderLeft: `1px solid ${COLORS.grey}`,
        },
      },
    },
    MuiTableHead: {
      root: {},
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Nunito',
        fontSize: '1rem',
        borderBottom: `1px solid ${COLORS.grey}`,
      },
      body: {
        fontWeight: 'bold',
      },
      head: {
        backgroundColor: COLORS.greyLighter,
        color: COLORS.greyDarkest,
        border: 'none',
      },
    },
    MuiTextField: {
      root: {
        fontFamily: 'Nunito',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.grey}`,
        '& :hover': {
          borderColor: `1px solid ${COLORS.grey}`,
        },
      },
    },
  },
  props: {
    MuiTypography: {},
  },
});
