import { EditorState } from 'draft-js';
import React, { CSSProperties } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Field, FieldRenderProps } from 'react-final-form';
import { COLORS } from '../../styles';

export const editorStyles = {
  editorStyle: {
    fontFamily: 'Nunito',
    backgroundColor: COLORS.white,
    color: COLORS.black,
    minHeight: '200px',
    paddingLeft: '1em',
    paddingRight: '1em',
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${COLORS.grey}`,
    borderTop: 0,
  } as CSSProperties,
  toolbarStyle: {
    margin: 0,
    border: `1px solid ${COLORS.grey}`,
    borderBottom: 0,
    borderRadius: '4px 4px 0 0',
  } as object,
};

export const editorToolbar = {
  options: ['blockType', 'emoji', 'inline', 'list', 'link'],
  blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'] },
  inline: { options: ['bold', 'italic'] },
};

interface Props {
  name: string;
  validate?: any;
  required?: boolean;
}

export const Wysiwyg: (props: Props) => React.ReactElement<Props> = ({ name, validate, required = false }) => {
  const render = ({ input: { value, onChange } }: FieldRenderProps<EditorState, HTMLElement>) => (
    <Editor toolbar={editorToolbar} editorState={value} onEditorStateChange={onChange} {...editorStyles} />
  );

  return <Field name={name} validate={validate} required={required} render={render} />;
};
