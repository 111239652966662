import React, { FC, useCallback } from 'react';
import { styled, Typography } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  CheckBoxField,
  ComboBoxField,
  ImageUploaderField,
  InputField,
  MultiSelectComboBoxField,
  SubmitButton,
} from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { AdFormValue, AdRole, ContentType } from '../../types';
import { validators } from '../../utils';
import { AdCardPreview } from './AdCardPreview';

export interface AdFormProps {
  initial: AdFormValue;
  contentTypes: ContentType[];
  onSubmit: (c: AdFormValue) => Promise<void>;
}

const TipBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
});

export const AdForm: FC<AdFormProps> = ({ initial, contentTypes, onSubmit }) => {
  const { t } = useTranslation();

  const roleOptions = Object.values(AdRole).map((it) => it);

  const submit = (value: AdFormValue) => new Promise((resolve) => resolve(onSubmit(value)));
  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, values }: FormRenderProps<AdFormValue>) => {
      return (
        <TipBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="text"
                name="text"
                label={t('ad.text')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(40))}
              />
              <InputField
                id="link"
                name="link"
                label={t('ad.link')}
                required={true}
                validate={validators.and(validators.required, validators.isLink, validators.maximumLength(1024))}
              />
              <InputField
                id="tagText"
                name="tagText"
                label={t('ad.tagText')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(20))}
              />
              <ImageUploaderField
                name="image"
                label={t('ad.image')}
                required={true}
                validate={validators.and(validators.required, validators.maximumFilesizeMb(1))}
              />
            </FormBox>
            <FormBox flexDirection="column" flexSpacing="40px">
              <ComboBoxField
                required
                name="role"
                label={t('ad.role')}
                options={roleOptions}
                validate={validators.required}
                getOptionSelected={(a, b) => a === b}
                getOptionLabel={(g) => (g ? t(`notification.filter.${g.toLowerCase()}`) : '')}
              />
              <MultiSelectComboBoxField
                required
                name="contentTypeIds"
                label={t('ad.contentTypeIds')}
                placeholder={''}
                options={contentTypes.map((c) => c.id)}
                getOptionLabel={(v) => contentTypes.find((c) => c.id === v)?.name ?? ''}
                validate={validators.required}
              />
              <CheckBoxField id="active" name="active" label={t('ad.status')} />
            </FormBox>
          </FormBoxGroup>
          <StateToolRow>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <SubmitButton label={t('ad.save')} loading={submitting} onClick={handleSubmit} />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('tip.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
          <AdCardPreview {...values} />
        </TipBaseForm>
      );
    },
    [t, contentTypes, roleOptions],
  );

  return (
    <>
      <Form initialValues={initial} mutators={{ ...arrayMutators }} onSubmit={submit} render={render} />
    </>
  );
};
