import { DateTime, Interval } from 'luxon';

export const withNextHour = (d: DateTime): DateTime =>
  d.set({ minute: 0, second: 0, millisecond: 0 }).plus({ hour: 1 });

// Get first date that doesn't conflict with schedule intervals
export const getFirstAvailableDateTime = (d: DateTime, invalidIntervals: Interval[]): DateTime => {
  const next = d.plus({ day: 1 });

  if (invalidIntervals.some((i) => i.contains(d))) {
    return getFirstAvailableDateTime(next, invalidIntervals);
  }

  return d;
};
