import { FileWithPreview } from '../../components/FormControls';
import { Ad, AdId, AdTableRow, AdRole, ContentTypeId, ContentType } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getAds = async (): Promise<AdTableRow[]> => {
  const result = await ApiClient.get<AdTableRow[]>(PATHS.ads.root);
  return result.data;
};

export const getAd = async (id: AdId): Promise<Ad> => {
  const result = await ApiClient.get<any>(PATHS.ads.single(id));
  return result.data;
};

export interface AdUpdateRequest {
  readonly text: string;
  readonly image: string;
  readonly link: string;
  readonly tagText: string;
  readonly active: boolean;
  readonly role: AdRole;
  readonly contentTypeIds: ContentTypeId[];
}

export const createAd = async (request: AdUpdateRequest, image: FileWithPreview): Promise<AdId> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  data.append('image', image.file);

  const result = await ApiClient.post<{ id: number }>(PATHS.ads.root, data);
  return result.data.id;
};

export const updateAd = async (id: AdId, request: AdUpdateRequest, image: FileWithPreview | null): Promise<Ad> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  if (image) data.append('image', image.file);

  const result = await ApiClient.put<any>(PATHS.ads.single(id), data);
  return result.data;
};

export const deleteAd = async (id: AdId): Promise<void> => {
  await ApiClient.delete(PATHS.ads.single(id));
};

export const getContentTypes = async (): Promise<ContentType[]> => {
  const result = await ApiClient.get<ContentType[]>(PATHS.ads.contentTypes);
  return result.data;
};
