import { DateTime } from 'luxon';
import { FileWithPreview } from '../../components/FormControls';
import {
  CopyToTipsParams,
  MassHolidayTipUpdateParams,
  MassTipUpdateValues,
  TagId,
  Tip,
  TipElementType,
  TipId,
  TipListItem,
  TipReminderFrequency,
  TipSeasonKey, 
} from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getTips = async (): Promise<TipListItem[]> => {
  const result = await ApiClient.get<TipListItem[]>(PATHS.tips.root);
  result.data
    .sort((a, b) => a.id - b.id)
    .reverse()
    .forEach((data: any) => {
      data.scheduledDateTime = data.scheduledDateTime ? DateTime.fromISO(data.scheduledDateTime) : null;
      data.publishedDateTime = data.publishedDateTime ? DateTime.fromISO(data.publishedDateTime) : null;
    });

  return result.data;
};

export const getTip = async (id: TipId): Promise<Tip> => {
  const result = await ApiClient.get<any>(PATHS.tips.single(id));
  result.data.scheduledDateTime = result.data.scheduledDateTime
    ? DateTime.fromISO(result.data.scheduledDateTime)
    : null;
  result.data.publishedDateTime = result.data.publishedDateTime
    ? DateTime.fromISO(result.data.publishedDateTime)
    : null;
  result.data.reminder.defaultDateTime = DateTime.fromISO(result.data.reminder.defaultDateTime);
  return result.data;
};

export interface TipUpdateRequest {
  readonly title: string;
  readonly image: string;
  readonly primaryTagId: TagId;
  readonly secondaryTagIds: TagId[];
  readonly scheduledDateTime: DateTime | null;
  readonly didYouKnow: {
    readonly text: string | null;
    readonly value: string | null;
  };
  readonly season: {
    readonly key: TipSeasonKey[] | null;
    readonly title: string | null;
    readonly text: string | null;
    readonly image: string | null;
  };
  readonly reminder: {
    readonly enabled: boolean;
    readonly title: string | null;
    readonly text: string | null;
    readonly frequency: TipReminderFrequency;
    readonly defaultDateTime: string;
  };
  readonly elements: TipUpdateRequestElement[];
  readonly keywords: string | null;
}

export interface TipUpdateRequestElement {
  readonly type: TipElementType;
  readonly content?: string;
  readonly image?: string;
  readonly videoId?: string;
}

export const createTip = async (
  request: TipUpdateRequest,
  images: FileWithPreview[],
  isHolidayTip: boolean,
): Promise<TipId> => {
  const data = new FormData();
  data.append('request', JSON.stringify({ ...request, isHolidayTip }));
  images.forEach((image) => data.append('images[]', image.file));

  const result = await ApiClient.post<{ id: number }>(PATHS.tips.root, data);
  return result.data.id;
};

export const updateTip = async (
  id: TipId,
  request: TipUpdateRequest,
  images: FileWithPreview[],
  isHolidayTip: boolean,
): Promise<Tip> => {
  const data = new FormData();
  data.append('request', JSON.stringify({ ...request, isHolidayTip }));
  images.forEach((image) => data.append('images[]', image.file));

  const result = await ApiClient.put<any>(PATHS.tips.single(id), data);
  result.data.scheduledDateTime = result.data.scheduledDateTime
    ? DateTime.fromISO(result.data.scheduledDateTime)
    : null;
  result.data.publishedDateTime = result.data.publishedDateTime
    ? DateTime.fromISO(result.data.publishedDateTime)
    : null;
  result.data.reminder.defaultDateTime = DateTime.fromISO(result.data.reminder.defaultDateTime);
  return result.data;
};

export const deleteTip = async (id: TipId): Promise<void> => {
  await ApiClient.delete(PATHS.tips.single(id));
};

export const getHolidayTips = async (): Promise<TipListItem[]> => {
  const result = await ApiClient.get<TipListItem[]>(PATHS.tips.root, { params: { onlyHoliday: true } });
  result.data
    .sort((a, b) => a.id - b.id)
    .reverse()
    .forEach((data: any) => {
      data.scheduledDateTime = data.scheduledDateTime ? DateTime.fromISO(data.scheduledDateTime) : null;
      data.publishedDateTime = data.publishedDateTime ? DateTime.fromISO(data.publishedDateTime) : null;
    });
  return result.data;
};

export const massUpdateTips = async (data: MassTipUpdateValues): Promise<void> => {
  await ApiClient.patch(PATHS.tips.massUpdate, data);
};

export const massUpdateHolidayTips = async (data: MassHolidayTipUpdateParams): Promise<void> => {
  await ApiClient.put(PATHS.tips.massUpdate, data);
};

export const copyToTips = async (data: CopyToTipsParams): Promise<void> => {
  await ApiClient.post(PATHS.tips.copy, data);
};

/**
 * Triggers a push notification for the tip with the given id.
 * @param id 
 */
export const sendPushNotification = async(id: TipId): Promise<void> => {
  await ApiClient.post(PATHS.tips.pushNotification(id));
}