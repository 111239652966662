import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FieldValidator } from 'final-form';
import React, { FC, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../styles/muiTheme';

export interface FileWithPreview {
  readonly valueType: 'file';
  readonly name: string;
  readonly file: File;
  readonly preview: string;
}

export interface ExistingImage {
  readonly valueType: 'existing';
  readonly name: string;
  readonly url: string;
}

export interface ImageUploaderProps {
  required?: boolean;
  value: FileWithPreview | ExistingImage | null;
  label?: string;
  error?: string;
  onChange: (event: FileWithPreview) => void;
}

const DropzoneContainer = styled('div')({
  width: '250px',
  border: `1px solid ${COLORS.grey}`,
  backgroundColor: COLORS.white,
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: '16px',
});

const ThumbBox = styled('div')({
  maxWidth: '250px',
  '& > *': {
    maxWidth: '100%',
    objectFit: 'contain',
  },
});

const UploadTextContainer = styled('div')({
  margin: '20px 84px 20px 84px',
  whiteSpace: 'nowrap',
});

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const ImageUploader: FC<ImageUploaderProps> = ({ required, value, label, error, onChange }) => {
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      onChange({ valueType: 'file', name: file.name, file, preview: URL.createObjectURL(file) });
    },
    multiple: false,
  });

  const imageSrc = !value ? null : value.valueType === 'existing' ? value.url : value.preview;

  const thumbImage =
    imageSrc !== null ? (
      <ThumbBox>
        <img src={imageSrc} alt="Uploaded icon" />
      </ThumbBox>
    ) : null;

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (value && value.valueType === 'file') {
        URL.revokeObjectURL(value.preview);
      }
    },
    [value],
  );

  const uploadText =
    imageSrc === null ? (
      <UploadTextContainer>
        <Typography variant="body1">Lisää kuva</Typography>
      </UploadTextContainer>
    ) : null;

  return (
    <span>
      {label ? <Typography variant="h3">{`${label}${required ? '*' : ''}`}</Typography> : null}
      <Container>
        <DropzoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {thumbImage}
          {uploadText}
        </DropzoneContainer>
      </Container>
      {error ? (
        <Typography variant="body1" color="error">
          {t(error)}
        </Typography>
      ) : null}
    </span>
  );
};

interface ImageUploaderFieldProps {
  name: string;
  required?: boolean;
  label?: string;
  validate?: FieldValidator<FileWithPreview | null>;
}

export const ImageUploaderField: FC<ImageUploaderFieldProps> = ({ name, required, label, validate }) => {
  const render = (props: FieldRenderProps<any, HTMLElement>) => (
    <ImageUploader
      required={required}
      value={props.input.value}
      onChange={props.input.onChange}
      error={props.meta.touched ? props.meta.error : undefined}
      label={label}
    />
  );

  return <Field name={name} required={required} render={render} validate={validate} allowNull={true} />;
};
