export enum DynamicLinkType {
  Recipe = 'recipe',
  Tip = 'tip',
  QuestionCategory = 'questionCategory',
  Question = 'community',
  Screen = 'screen',
}

export type DynamicLinkParams = {
  type: DynamicLinkType;
  id: number | string;
};

type AppScreen = {
  id: number;
  name: string;
};

// NOTE: Do not change existing id-name pairs. The ids are used in the app to navigate to the corresponding
// screen, and because all of the navigation has to be implemented manually, changes would break old links.
export const AppScreens: AppScreen[] = [
  {
    id: 1,
    name: 'Etusivu',
  },
  {
    id: 2,
    name: 'Keskustelu',
  },
  {
    id: 3,
    name: 'Haku',
  },
  {
    id: 4,
    name: 'Ruokaohjeet - Sinulle',
  },
  {
    id: 5,
    name: 'Ruokaohjeet - Ohjehaku',
  },
  {
    id: 6,
    name: 'Ruokaohjeet - Suosituimmat',
  },
  {
    id: 7,
    name: 'Ruokaohjeet - Viikon lista',
  },
  {
    id: 8,
    name: 'Tapahtumakalenteri',
  },
  {
    id: 9,
    name: 'Vinkit - Kodin vinkit',
  },
  {
    id: 10,
    name: 'Vinkit - Kotipiha ja huonekasvit',
  },
  {
    id: 11,
    name: 'Vinkit - Käsityöt',
  },
  {
    id: 12,
    name: 'Vinkit - Ruoka ja ravitsemus',
  },
  {
    id: 13,
    name: 'Vinkit - Muistutukset',
  },
  {
    id: 14,
    name: 'Omat - Suosikit',
  },
  {
    id: 15,
    name: 'Omat - Oma ruokalista',
  },
  {
    id: 16,
    name: 'Profiili - Omat tiedot',
  },
  {
    id: 17,
    name: 'Profiili - Asetukset',
  },
  {
    id: 18,
    name: 'Profiili - Edut',
  },
  {
    id: 19,
    name: 'Profiili - Ilmoitukset',
  },
  {
    id: 20,
    name: 'Juhlasesonki',
  },
  {
    id: 21,
    name: 'Yhdistykset'
  },
  {
    id: 22,
    name: 'Jäsenyyden infosivu',
  },
  {
    id: 23,
    name: 'Jäsenyyden liittäminen',
  },
];
