import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Grid, styled } from '@material-ui/core';

import { ComboBox } from '../FormControls/ComboBox';
import { useStores } from '../../stores';
import { Tag, TagGroupKey, TipSeasonKey, tipSeasonKeys, TagGroup, TagId } from '../../types';
import { ConfirmDialog } from './ConfirmDialog';
import { MultiSelectComboBox } from '../FormControls';

const ControlsGrid = styled(Grid)({
  marginBottom: '35px',
  '& > :not(:last-child)': {
    marginBottom: '16px',
  },
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0 20px',
});

type Props = {
  isVisible: boolean;
  onAccept: (tagId: TagId, toSeasons?: TipSeasonKey[]) => void;
  onClose: () => void;
};

export const CopyToTipDialog: FC<Props> = observer(({ isVisible, onAccept, onClose }) => {
  const {
    tagStore: { tags },
  } = useStores();
  const { t } = useTranslation();

  const [toCategory, setToCategory] = useState<TagGroup | null>(null);
  const [toSubCategory, setToSubCategory] = useState<Tag | null>(null);
  const [toSeasons, setToSeasons] = useState<TipSeasonKey[]>([]);

  const allowedTagGroups = useMemo(() => {
    return tags.state === 'Fetched'
      ? tags.data.groups.filter((g) =>
          [TagGroupKey.Home, TagGroupKey.Garden, TagGroupKey.Food, TagGroupKey.Crafts].includes(g.key),
        )
      : [];
  }, [tags]);

  return (
    <>
      <ConfirmDialog
        open={isVisible}
        title={t('massActions.copy.title')}
        onAccept={() => {
          if (!toSubCategory) return;
          onAccept(toSubCategory.id, toSeasons);
        }}
        onCancel={onClose}
        acceptDisabled={!toSubCategory || !toSeasons.length}
        acceptOption={t('common.ok')}
        cancelOption={t('common.cancel')}
      >
        <ControlsGrid container direction="column">
          <ComboBox
            placeholder={t('tip.list.selectCategory')}
            value={toCategory}
            options={allowedTagGroups}
            getOptionSelected={(a, b) => a?.id === b?.id}
            getOptionLabel={(g) => g.name}
            onChange={(value) => setToCategory(value ?? null)}
            narrow
          />
          <ComboBox<Tag>
            placeholder={t('tip.list.selectSubCategory')}
            disabled={toCategory === null}
            value={toSubCategory}
            options={tags.state === 'Fetched' && toCategory ? tags.data.tagsByGroupKey[toCategory.key] ?? [] : []}
            getOptionSelected={(a, b) => a?.id === b?.id}
            getOptionLabel={(t) => t?.name ?? ''}
            onChange={(value) => setToSubCategory(value)}
            narrow
          />
          <MultiSelectComboBox<TipSeasonKey>
            placeholder={t('tip.list.selectSeason')}
            options={tipSeasonKeys}
            value={toSeasons}
            getOptionLabel={(v) => t(`tip.list.seasonLabel.${v}`)}
            onChange={(value) => setToSeasons(value)}
            narrow
          />
        </ControlsGrid>
      </ConfirmDialog>
    </>
  );
});
