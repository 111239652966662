import React, { FC, useCallback } from 'react';
import { styled, Typography } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ImageUploaderField, InputField, SubmitButton } from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { QuestionTagFormValue } from '../../types';
import { validators } from '../../utils';

export interface CategoryFormProps {
  initial: QuestionTagFormValue;
  onSubmit: (c: QuestionTagFormValue) => Promise<void>;
}

const TipBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
});

export const QuestionTagForm: FC<CategoryFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const submit = (value: QuestionTagFormValue) => new Promise((resolve) => resolve(onSubmit(value)));
  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid }: FormRenderProps<QuestionTagFormValue>) => {
      return (
        <TipBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="name"
                name="name"
                label={t('questionTag.name')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(255))}
              />
              <ImageUploaderField
                name="image"
                label={t('questionTag.image')}
                required={true}
                validate={validators.and(validators.required, validators.maximumFilesizeMb(2))}
              />
            </FormBox>
          </FormBoxGroup>

          <StateToolRow>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <SubmitButton label={t('questionTag.save')} loading={submitting} onClick={handleSubmit} />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('tip.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
        </TipBaseForm>
      );
    },
    [t],
  );

  return (
    <>
      <Form
        initialValues={initial}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={submit}
        render={render}
      />
    </>
  );
};
