import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';

import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { AdFormValue } from '../../types';
import { AdForm } from './AdForm';

export const AdScreen: FC = observer(() => {
  const {
    adStore: { ad, contentTypes, fetchAd, fetchContentTypesIfNotFetched, updateAd },
  } = useStores();

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    fetchContentTypesIfNotFetched();
  }, [fetchContentTypesIfNotFetched]);

  useEffect(() => {
    if (id) fetchAd(id);
  }, [id, fetchAd]);

  const onSubmit = useCallback(
    async (values: AdFormValue): Promise<void> => {
      if (id) {
        return await updateAd(id, values);
      }
    },
    [id, updateAd],
  );

  const fetching = ad.state === 'Fetching' || contentTypes.state === 'Fetching';

  const formData: [AdFormValue] | null = useMemo(() => {
    if (ad.state !== 'Fetched') return null;

    const { image, ...advertisement } = ad.data;

    const adFormValue: AdFormValue = {
      ...advertisement,
      contentTypeIds: ad.data.contentTypeIds,
      image: { valueType: 'existing', name: ad.data.image, url: ad.data.imageUrl },
    };

    return [adFormValue];
  }, [ad]);

  return (
    <Layout allowOverflow title={ad.state === 'Fetched' ? ad.data.text : ''}>
      {(() => {
        if (fetching) return <CircularProgress size="3rem" />;
        if (id === null || formData === null || contentTypes.state !== 'Fetched') return null;

        return (
          <>
            <AdForm initial={formData[0]} onSubmit={onSubmit} contentTypes={contentTypes.data} />
          </>
        );
      })()}
    </Layout>
  );
});
