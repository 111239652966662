import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { Button } from '../FormControls';

interface Props {
  open: boolean;
  title: string;
  message?: ReactNode;
  acceptOption?: string;
  cancelOption?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  acceptDisabled?: boolean;
}

export const ConfirmDialog: FC<Props> = ({
  open,
  title,
  message,
  acceptOption: acceptOptionOrUndefined,
  cancelOption: cancelOptionOrUndefined,
  onAccept,
  onCancel,
  acceptDisabled = false,
  children,
}) => {
  const { t } = useTranslation();
  const acceptOption = acceptOptionOrUndefined || t('common.yes');
  const cancelOption = cancelOptionOrUndefined || t('common.no');

  return (
    <MaterialDialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      {!message ? null : (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      {children}
      <DialogActions>
        {onAccept !== undefined && <Button label={acceptOption} onClick={onAccept} disabled={acceptDisabled} />}
        {onCancel !== undefined && <Button label={cancelOption} onClick={onCancel} />}
      </DialogActions>
    </MaterialDialog>
  );
};
