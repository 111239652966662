import { DateTime } from 'luxon';

export enum NotificationTarget {
  All = 'all',
  Members = 'members',
  Users = 'users',
}

export enum NotificationState {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Published = 'published',
}

export type NotificationId = number;

export interface Notification {
  readonly id: NotificationId;
  readonly title: string;
  readonly link: string | null;
  readonly message: string;
  readonly target: NotificationTarget;
  readonly scheduledDateTime: DateTime | null;
  readonly publishedDateTime: DateTime | null;
}

export interface NotificationFormValue {
  readonly submitType: 'draft' | 'schedule';
  readonly title: string;
  readonly link: string | null;
  readonly message: string;
  readonly target: NotificationTarget;
  readonly scheduledDateTime: DateTime | null;
}

export interface NotificationListItem {
  readonly id: NotificationId;
  readonly title: string;
  readonly target: NotificationTarget
  readonly scheduledDateTime: DateTime | null;
  readonly publishedDateTime: DateTime | null;
}