import React, { FC } from 'react';
import styled from '@material-ui/core/styles/styled';
import { ArrowRightAlt } from '@material-ui/icons';
import { DateTime, Interval } from 'luxon';
import { useField } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../components/FormControls';
import { validators } from '../../utils';

const ScheduleDatePickerField = styled(DatePicker)({
  '& .MuiInputBase-input': {
    margin: '0 5px',
    padding: '14px 13px',
    width: '100px',
    border: 'none',
  },
  '& .MuiButtonBase-root': {
    padding: '0',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
});

type Props = {
  invalidIntervals?: Interval[];
  initialStart: DateTime | null;
  initialEnd: DateTime | null;
};

export const PartnerScheduleFields: FC<Props> = ({ invalidIntervals, initialStart, initialEnd }) => {
  const { t } = useTranslation();

  const { input: startInput, meta: startMeta } = useField<DateTime>('scheduledStartDateTime', {
    validate: initialStart && initialStart <= DateTime.local() ? undefined : validators.dateShouldBeInTheFuture,
  });
  const { input: endInput, meta: endMeta } = useField('scheduledEndDateTime', {
    validate: validators.dateShouldBeInTheFuture,
  });

  const handleShouldDisableStartDate = (date: DateTime | null) => {
    if (!invalidIntervals?.length || !date) return false;
    // Check whether the date conflicts with existing schedules
    return invalidIntervals.some((it) => it.contains(date));
  };

  const handleShouldDisableEndDate = (date: DateTime | null) => {
    if (!startInput.value) return true;
    if (!invalidIntervals?.length || !date) return false;
    // Find the next conflicting date
    const datesAfter = invalidIntervals
      .filter((it) => it.start > startInput.value!)
      .sort((a, b) => a.start.diff(b.start).toObject().milliseconds!);
    if (!datesAfter.length) return false;
    return date >= datesAfter[0].start;
  };

  const handleStartChange = (date: DateTime | null) => {
    if (date) {
      startInput.onChange(date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      endInput.onChange(date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }));
    }
  };

  return (
    <>
      <ScheduleDatePickerField
        {...startInput}
        onChange={handleStartChange}
        required
        id="scheduledStartDateTime"
        name="scheduledStartDateTime"
        shouldDisableDate={handleShouldDisableStartDate}
        error={startMeta.touched && !!startMeta.error}
        helperText={startMeta.touched && startMeta.error && t(startMeta.error)}
      />
      <ArrowRightAlt />
      <ScheduleDatePickerField
        {...endInput}
        required
        id="scheduledEndDateTime"
        name="scheduledEndDateTime"
        shouldDisableDate={handleShouldDisableEndDate}
        minDate={startInput.value ?? undefined}
        error={endMeta.touched && !!endMeta.error}
        helperText={endMeta.touched && endMeta.error && t(endMeta.error)}
      />
    </>
  );
};
