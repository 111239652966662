import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { CircularProgress, styled, Tab, Tabs } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { flow } from 'lodash/fp';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { ScreenAction, TipListItem, TipState, TipTableRow } from '../../types';
import { collect } from '../../utils';
import { TipListActions } from './TipListActions';

interface MaterialTableCell<T, K extends keyof T | undefined = undefined> {
  cell: {
    value: K extends keyof T ? T[K] : undefined;
  };
  row: {
    original: T;
  };
}

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const TipsScreen: FC = observer(() => {
  const {
    tipStore: {
      tipsByState,
      listingFilters: { state: stateFilter, group: groupFilter, tag: tagFilter, season: seasonFilter, keyword: keywordFilter },
      setListingStateFilter,
      fetchTips,
      deleteTip,
      setSelectedTipRows,
    },
    tagStore: { tags, fetchTagsIfNotFetched },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [tipToDelete, setTipToDelete] = useState<TipTableRow | null>(null);

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  useEffect(() => {
    fetchTips();
  }, [fetchTips]);

  const fetching = tipsByState.state === 'Fetching' || tags.state === 'Fetching';

  const data: TipTableRow[] = useMemo(() => {
    return tipsByState.state !== 'Fetched' || tags.state !== 'Fetched'
      ? []
      : flow(
          collect((tip: TipListItem): TipTableRow | undefined => {
            const tag = tags.data.tags.find((tag) => tip.tagId === tag.id);
            const group = tags.data.groups.find((group) => group.id === tag?.groupId);

            if (groupFilter !== null && groupFilter.id !== group?.id) {
              return undefined;
            } else if (tagFilter !== null && tagFilter.id !== tag?.id) {
              return undefined;
            } else if (seasonFilter !== null && !tip.season?.includes(seasonFilter)) {
              return undefined;
            } else if (keywordFilter && !tip.title.toLowerCase().includes(keywordFilter.toLowerCase())) {
              return undefined;
            } 

            return {
              id: tip.id,
              title: tip.title,
              season: tip.season,
              mainCategory: group?.name ?? '',
              subCategory: tag?.name ?? '',
              publishedDateTime: tip.publishedDateTime,
              scheduledDateTime: tip.scheduledDateTime,
            };
          }),
        )(tipsByState.data[stateFilter]);
  }, [tipsByState, tags, stateFilter, groupFilter, tagFilter, seasonFilter, keywordFilter]);

  const actions: ScreenAction[] = [
    {
      label: t('tip.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newTip.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        Header: t('tip.title'),
      },
      ...(stateFilter === TipState.Scheduled
        ? [
            {
              accessor: 'scheduledDateTime',
              Header: t('tip.list.scheduledDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<TipTableRow, 'scheduledDateTime'>) =>
                value ? value.toFormat("d.L.yyyy 'klo' H:mm") : '',
              sortType: 'luxondate',
            },
          ]
        : []),
      {
        accessor: 'mainCategory',
        Header: t('tip.category'),
        width: '15%',
      },
      {
        accessor: 'subCategory',
        Header: t('tip.subCategory'),
        width: '15%',
      },
      {
        accessor: 'season',
        Header: t('tip.list.season'),
        width: '15%',
        Cell: ({ cell: { value } }: MaterialTableCell<TipTableRow, 'season'>) => {
          if (!value) return '';
          if (value instanceof Array) return value.map((season) => t(`tip.list.seasonLabel.${season}`)).join(', ');
          return t(`tip.list.seasonLabel.${value}`);
        },
      },
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<TipTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setTipToDelete(original)} />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.tip.link(original.id))} />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t, stateFilter],
  );

  const deleteModal =
    tipToDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('tip.list.deleteMessage')}
        message={tipToDelete.title}
        onAccept={() => {
          setTipToDelete(null);
          deleteTip(tipToDelete.id);
        }}
        onCancel={() => setTipToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}

      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <TipListActions />
          <Tabs value={stateFilter} onChange={(ev, value) => setListingStateFilter(value)}>
            <Tab label={t('tip.list.published')} value={TipState.Published} />
            <Tab label={t('tip.list.scheduled')} value={TipState.Scheduled} />
            <Tab label={t('tip.list.drafts')} value={TipState.Draft} />
          </Tabs>
          <TableContainer>
            <MaterialTable
              data={data}
              columns={columns}
              onPageChange={() => {}}
              onRowSelect={(rows: { original: TipTableRow }[]) => setSelectedTipRows(rows)}
              onSortingChange={() => {}}
              pagination={null}
              initialSortBy={
                stateFilter === TipState.Scheduled ? [{ id: 'scheduledDateTime', desc: false }] : undefined
              }
            />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});
