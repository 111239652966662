import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, styled } from '@material-ui/core';

import { QuestionTagTableRow } from '../../types';
import { ConfirmDialog } from './ConfirmDialog';
import { DateTimePicker } from '../FormControls';
import { DateTime } from 'luxon';

const ScheduleBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '20px',
});

const ScheduleDatePickerField = styled(DateTimePicker)({
  '& .MuiInputBase-input': {
    padding: '14px 13px',
    width: '160px',
    border: 'none',
  },
  '& .MuiButtonBase-root': {
    padding: '0',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
});

type Props = {
  item: QuestionTagTableRow | null;
  onAccept: (dateTime: DateTime | null) => void;
  onClose: () => void;
};

export const QuestionHighlightDialog: FC<Props> = ({ item, onAccept, onClose }) => {
  const { t } = useTranslation();

  const [highlightSchedule, setHighlightSchedule] = useState<DateTime | null>(DateTime.local());

  if (!item) return null;

  const handleSchedule = () => {
    onAccept(item.scheduledDateTime ? null : highlightSchedule);
    onClose();
  };

  const renderContent = () => {
    if (item.scheduledDateTime) {
      return <p>{t('')}</p>;
    }
    return (
      <ScheduleBox>
        <ScheduleDatePickerField
          name="scheduledDateTime"
          required
          value={highlightSchedule ?? DateTime.local()}
          disablePast
          minutesStep={5}
          onChange={setHighlightSchedule}
        />
      </ScheduleBox>
    );
  };

  return (
    <>
      <ConfirmDialog
        open
        title={t(item.scheduledDateTime ? 'questionTag.list.alreadyHighlighted' : 'questionTag.list.highlightMessage', {
          category: item.name,
        })}
        onAccept={handleSchedule}
        onCancel={onClose}
        acceptOption={item.scheduledDateTime ? undefined : t('common.submit')}
        cancelOption={item.scheduledDateTime ? undefined : t('common.cancel')}
      >
        <DialogContent>{renderContent()}</DialogContent>
      </ConfirmDialog>
    </>
  );
};
