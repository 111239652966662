import { ExistingImage, FileWithPreview } from '../components';

export type MembershipBenefitId = number;

export enum MembershipBenefitRole {
  Users = 'users',
  Members = 'members',
  All = 'all',
}

export enum MembershipBenefitState {
  Active = 'active',
  Inactive = 'inactive',
}

export type MembershipBenefit = {
  readonly id: MembershipBenefitId;
  readonly text: string;
  readonly image: string;
  readonly imageUrl: string;
  readonly link: string;
  readonly buttonText: string;
  readonly active: boolean;
  readonly role: MembershipBenefitRole;
};

export type MembershipBenefitTableRow = {
  readonly id: MembershipBenefitId;
  readonly text: string;
  readonly active: boolean;
  readonly role: MembershipBenefitRole;
};

export type MembershipBenefitFormValue = {
  readonly text: string;
  readonly link: string;
  readonly buttonText: string;
  readonly active: boolean;
  readonly role: MembershipBenefitRole;
  readonly image: FileWithPreview | ExistingImage | null;
};
