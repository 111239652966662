import { Button, CircularProgress, styled } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SubmitButtonProps {
  loading: boolean;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

const ButtonContainer = styled('div')({});

export const SubmitButton: FC<SubmitButtonProps> = ({ label, loading, disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <Button
        size="large"
        type={onClick ? 'submit' : undefined}
        variant="contained"
        color="primary"
        disabled={disabled || loading}
        disableElevation
        onClick={onClick}
      >
        {loading && <CircularProgress size={24} color="primary" />}
        {t(label)}
      </Button>
    </ButtonContainer>
  );
};
