import { DateTime } from 'luxon';
import { FileWithPreview } from '../../components/FormControls';
import { CopyPartnersParams, Partner, PartnerId, PartnerListItem } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getPartners = async (): Promise<PartnerListItem[]> => {
  const result = await ApiClient.get<PartnerListItem[]>(PATHS.partner.root);
  result.data.forEach((data: any) => {
    data.scheduledStartDateTime = data.scheduledStartDateTime ? DateTime.fromISO(data.scheduledStartDateTime) : null;
    data.scheduledEndDateTime = data.scheduledEndDateTime ? DateTime.fromISO(data.scheduledEndDateTime) : null;
  });
  return result.data;
};

export const getPartner = async (id: PartnerId): Promise<Partner> => {
  const result = await ApiClient.get<any>(PATHS.partner.single(id));
  result.data.scheduledStartDateTime = result.data.scheduledStartDateTime
    ? DateTime.fromISO(result.data.scheduledStartDateTime)
    : null;
  result.data.scheduledEndDateTime = result.data.scheduledEndDateTime
    ? DateTime.fromISO(result.data.scheduledEndDateTime)
    : null;
  return result.data;
};

export interface PartnerUpdateRequest {
  readonly title: string;
  readonly shortDescription: string;
  readonly longDescription: string;
  readonly link: string;
  readonly linkText: string;
  readonly image: string;
  readonly scheduledStartDateTime: DateTime | null;
  readonly scheduledEndDateTime: DateTime | null;
  readonly elements: PartnerUpdateRequestElement[];
}

export interface PartnerUpdateRequestElement {
  readonly text: string;
  readonly image: string;
}

export const createPartner = async (request: PartnerUpdateRequest, images: FileWithPreview[]): Promise<PartnerId> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  images.forEach((image) => data.append('images[]', image.file));

  const result = await ApiClient.post<{ id: number }>(PATHS.partner.root, data);
  return result.data.id;
};

export const updatePartner = async (
  id: PartnerId,
  request: PartnerUpdateRequest,
  images: FileWithPreview[],
): Promise<Partner> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  images.forEach((image) => data.append('images[]', image.file));

  const result = await ApiClient.put<any>(PATHS.partner.single(id), data);

  result.data.scheduledStartDateTime = result.data.scheduledStartDateTime
    ? DateTime.fromISO(result.data.scheduledStartDateTime)
    : null;
  result.data.scheduledEndDateTime = result.data.scheduledEndDateTime
    ? DateTime.fromISO(result.data.scheduledEndDateTime)
    : null;
  return result.data;
};

export const deletePartner = async (id: PartnerId): Promise<void> => {
  await ApiClient.delete(PATHS.partner.single(id));
};

export const copyPartners = async (data: CopyPartnersParams): Promise<void> => {
  await ApiClient.post(PATHS.partner.copy, data);
};
