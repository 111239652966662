import { Button as MuiButton, ButtonProps, styled } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import React, { FC } from 'react';

export interface IconButtonProps {
  size: number;
  icon: SvgIconComponent;
  onClick: () => void;
}

const BaseIconButton = styled(MuiButton)(({ s }: ButtonProps & { s: number }) => ({
  borderRadius: 5,
  width: `${s}px`,
  height: `${s}px`,
  minWidth: `${s}px`,
  padding: 0,
  border: 'none',
}));

export const IconButton: FC<IconButtonProps> = ({ size, icon: Icon, onClick }) => (
  <BaseIconButton variant="outlined" s={size} onClick={onClick}>
    <Icon fontSize="small" />
  </BaseIconButton>
);

export const IconButtonRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '& > :not(:last-child)': {
    marginRight: '15px',
  },
});
