import ChristmasImage from '../images/holiday/christmas_1.jpeg';
import ChristmasDecorationImage from '../images/holiday/christmas_decoration.png';
import SpringImage from '../images/holiday/spring_1.png';
import SpringDecorationImage from '../images/holiday/spring_decoration.png';
import SummerImage from '../images/holiday/summer_1.jpg';
import SummerDecorationImageVertical from '../images/holiday/summer_decoration_vertical.png';
import SummerDecorationImageHorizontal from '../images/holiday/summer_decoration_horizontal.png';
import MaydayImage from '../images/holiday/mayday_1.jpg';
import MaydayDecorationImage from '../images/holiday/mayday_decoration.png';
import LaskiainenImage from '../images/holiday/laskiainen_1.jpg';
import LaskiainenDecorationImageVertical from '../images/holiday/laskiainen_decoration_vertical.png';
import LaskiainenDecorationImageHorizontal from '../images/holiday/laskiainen_decoration_horizontal.png';
import MothersdayImage from '../images/holiday/mothersday_1.jpg';
import MothersdayDecorationImageVertical from '../images/holiday/mothersday_decoration_vertical.png';
import MothersdayDecorationImageHorizontal from '../images/holiday/mothersday_decoration_horizontal.png';
import FathersdayImage from '../images/holiday/fathersday_1.png';
import FathersdayDecorationImageVertical from '../images/holiday/fathersday_decoration_vertical.png';
import FathersdayDecorationImageHorizontal from '../images/holiday/fathersday_decoration_horizontal.png';
import MidsummerImage from '../images/holiday/midsummer_1.png';
import MidsummerDecorationImageVertical from '../images/holiday/midsummer_decoration_vertical.png';
import MidsummerDecorationImageHorizontal from '../images/holiday/midsummer_decoration_horizontal.png';
import IndependencedayImage from '../images/holiday/independence_1.jpeg';
import IndependencedayDecorationImageVertical from '../images/holiday/independence_decoration_vertical.png';
import IndependencedayDecorationImageHorizontal from '../images/holiday/independence_decoration_horizontal.png';
import HarvestImage from '../images/holiday/harvest_1.png';
import HarvestDecorationImageVertical from '../images/holiday/harvest_decoration_vertical.png';
import HarvestDecorationImageHorizontal from '../images/holiday/harvest_decoration_horizontal.png';
import ForestImage from '../images/holiday/forest_1.jpg';

export enum HolidayTagGroupKeyEnum {
  Christmas = 'christmas',
  Spring = 'spring',
  Summer = 'summer',
  Mayday = 'mayday',
  Laskiainen = 'laskiainen',
  Mothersday = 'mothersday',
  Fathersday = 'fathersday',
  Midsummer = 'midsummer',
  Independenceday = 'independenceday',
  Harvest = 'harvest',
  Forest = 'forest'
}

export type Logo = 'gingham' | 'cross' | 'crown' | 'round' | 'round-green' | 'cursive';

export type HolidayTheme = {
  frontPageBackground: string;
  frontPageButtonBackground: string;
  primaryBackground: string;
  secondaryBackground: string;
  menuHeaderBackground: string;
  titleColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  comments: string;
  frontPageLogo: Logo;
  frontPageLogoColor: string;
  menuLogo: Logo;
};

export const holidayThemes: { [key in HolidayTagGroupKeyEnum]: HolidayTheme } = {
  christmas: {
    frontPageBackground: '#721717',
    frontPageButtonBackground: '#EFC2E1',
    primaryBackground: '#721717',
    secondaryBackground: '#EFC2E1',
    menuHeaderBackground: '#721717',
    titleColor: '#721717',
    primaryTextColor: '#721717',
    secondaryTextColor: '#fff',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#EFC2E1',
    menuLogo: 'cross',
  },
  spring: {
    frontPageBackground: '#F0C7DE',
    frontPageButtonBackground: '#1A845D',
    primaryBackground: '#1A845D',
    secondaryBackground: '#F0C7DE',
    menuHeaderBackground: '#22AE7A',
    titleColor: '#1A845D',
    primaryTextColor: '#FFF',
    secondaryTextColor: '#fff',
    comments: '#FFF',
    frontPageLogo: 'round-green',
    frontPageLogoColor: '#22AE7A',
    menuLogo: 'cross',
  },
  summer: {
    frontPageBackground: '#9EA6E8',
    frontPageButtonBackground: '#B8FADA',
    primaryBackground: '#9EA6E8',
    secondaryBackground: '#B8FADA',
    menuHeaderBackground: '#9EA6E8',
    titleColor: '#47194B',
    primaryTextColor: '#3E3E3E',
    secondaryTextColor: '#47194B',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#B8FADA',
    menuLogo: 'cross',
  },
  mayday: {
    frontPageBackground: '#FFB1F3',
    frontPageButtonBackground: '#FFE769',
    primaryBackground: '#FFE769',
    secondaryBackground: '#FFB1F3',
    menuHeaderBackground: '#FFE769',
    titleColor: '#47194B',
    primaryTextColor: '#3E3E3E',
    secondaryTextColor: '#47194B',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#FFE769',
    menuLogo: 'cross',
  },
  laskiainen: {
    frontPageBackground: '#B8F6FA',
    frontPageButtonBackground: '#286FB7',
    primaryBackground: '#286FB7',
    secondaryBackground: '#B8F6FA',
    menuHeaderBackground: '#B8F6FA',
    titleColor: '#286FB7',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#8DB9FA',
    menuLogo: 'cross',
  },
  mothersday: {
    frontPageBackground: '#FDA4AF',
    frontPageButtonBackground: '#C94013',
    primaryBackground: '#C94013',
    secondaryBackground: '#FDA4AF',
    menuHeaderBackground: '#FDA4AF',
    titleColor: '#C94013',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#C94013',
    menuLogo: 'cross',
  },
  midsummer: {
    frontPageBackground: '#B8E3B7',
    frontPageButtonBackground: '#008051',
    primaryBackground: '#008051',
    secondaryBackground: '#B8E3B7',
    menuHeaderBackground: '#B8E3B7',
    titleColor: '#008051',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#008051',
    menuLogo: 'cross',
  },
  fathersday: {
    frontPageBackground: '#FDA4AF',
    frontPageButtonBackground: '#EB5C2E',
    primaryBackground: '#FDA4AF',
    secondaryBackground: '#C94013',
    menuHeaderBackground: '#FDA4AF',
    titleColor: '#47194B',
    primaryTextColor: '#3E3E3E',
    secondaryTextColor: '#47194B',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#EB5C2E',
    menuLogo: 'cross',
  },
  independenceday: {
    frontPageBackground: '#286FB7',
    frontPageButtonBackground: '#FFFFFF',
    primaryBackground: '#286FB7',
    secondaryBackground: '#FFFFFF',
    menuHeaderBackground: '#286FB7',
    titleColor: '#286FB7',
    primaryTextColor: '#3E3E3E',
    secondaryTextColor: '#19444b',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#286FB7',
    menuLogo: 'cross',
  },
  harvest: {
    frontPageBackground: '#BD6E24',
    frontPageButtonBackground: '#FFE6A6',
    primaryBackground: '#BD6E24',
    secondaryBackground: '#FFE6A6',
    menuHeaderBackground: '#BD6E24',
    titleColor: '#BD6E24',
    primaryTextColor: '#721717',
    secondaryTextColor: '#BD6E24',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#EFC2E1',
    menuLogo: 'cross',
  },
  forest: {
    frontPageBackground: '#008051',
    frontPageButtonBackground: '#B8E3B7',
    primaryBackground: '#B8E3B7',
    secondaryBackground: '#008051',
    menuHeaderBackground: '#008051',
    titleColor: '#008051',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    comments: '#FFF',
    frontPageLogo: 'round',
    frontPageLogoColor: '#008051',
    menuLogo: 'cross',
  },
};

type ImageSourcePropType = { default: string }; // ????

export type HolidayImage = {
  [key in HolidayTagGroupKeyEnum]: {
    frontPage: ImageSourcePropType;
    decorationHorizontal: ImageSourcePropType;
    decorationVertical: ImageSourcePropType;
  };
};

export const holidayImages: any = {
  christmas: {
    frontPage: ChristmasImage,
    decorationVertical: ChristmasDecorationImage,
    decorationHorizontal: ChristmasDecorationImage,
  },
  spring: {
    frontPage: SpringImage,
    decorationVertical: SpringDecorationImage,
    decorationHorizontal: SpringDecorationImage,
  },
  summer: {
    frontPage: SummerImage,
    decorationVertical: SummerDecorationImageVertical,
    decorationHorizontal: SummerDecorationImageHorizontal,
  },
  mayday: {
    frontPage: MaydayImage,
    decorationVertical: MaydayDecorationImage,
    decorationHorizontal: MaydayDecorationImage,
  },
  laskiainen: {
    frontPage: LaskiainenImage,
    decorationVertical: LaskiainenDecorationImageVertical,
    decorationHorizontal: LaskiainenDecorationImageHorizontal,
  },
  mothersday: {
    frontPage: MothersdayImage,
    decorationVertical: MothersdayDecorationImageVertical,
    decorationHorizontal: MothersdayDecorationImageHorizontal,
  },
  fathersday: {
    frontPage: FathersdayImage,
    decorationVertical: FathersdayDecorationImageVertical,
    decorationHorizontal: FathersdayDecorationImageHorizontal
  },
  midsummer: {
    frontPage: MidsummerImage,
    decorationVertical: MidsummerDecorationImageVertical,
    decorationHorizontal: MidsummerDecorationImageHorizontal,
  },
  independenceday: {
    frontPage: IndependencedayImage,
    decorationVertical: IndependencedayDecorationImageVertical,
    decorationHorizontal: IndependencedayDecorationImageHorizontal,
  },
  harvest: {
    frontPage: HarvestImage,
    decorationVertical: HarvestDecorationImageVertical,
    decorationHorizontal: HarvestDecorationImageHorizontal,
  },
  forest: {
    frontPage: ForestImage,
    decorationVertical: ChristmasDecorationImage,
    decorationHorizontal: ChristmasDecorationImage,
  },
};

export const getDecorationStyles = (
  holidayKey: HolidayTagGroupKeyEnum,
): {
  verticalStyle: { [key: string]: any };
  horizontalStyle: { [key: string]: any };
} => {
  switch (holidayKey) {
    case 'christmas':
      return {
        verticalStyle: {
          width: '100%',
          height: 130,
          marginRight: -25,
          position: 'absolute',
          top: 130,
          right: 25 - 130,
          transform: [{ rotate: '90deg' }],
        },
        horizontalStyle: {
          left: 25,
          width: '100%',
          height: 130,
          marginLeft: -25,
        },
      };
    case 'spring':
      return {
        verticalStyle: {
          width: '80%',
          height: 100,
          marginRight: -35,
          position: 'absolute',
          top: 80,
          right: 25 - 130,
          transform: [{ rotate: '90deg' }],
        },
        horizontalStyle: {
          left: -25,
          top: -25,
          width: '70%',
          height: 130,
          marginLeft: -25,
          zIndex: -1,
        },
      };
    case 'mayday':
      return {
        verticalStyle: {
          position: 'absolute',
          right: -50,
          top: 50,
          width: '32.5%',
          height: 250,
          transform: [{ rotate: '10deg' }],
        },
        horizontalStyle: {
          left: '15%',
          top: -125,
          marginLeft: -45,
          width: 200,
          height: 297,
          marginBottom: -145,
          zIndex: -1,
          transform: [{ rotate: '-90deg' }],
        },
      };

    case 'laskiainen':
      return {
        verticalStyle: {
          width: 83,
          height: 138,
          marginRight: -35,
          position: 'absolute',
          top: 80,
          right: -5,
        },
        horizontalStyle: {
          left: -25,
          top: -25,
          width: '70%',
          height: 130,
          marginLeft: -25,
          zIndex: -1,
        },
      };

    case 'midsummer':
      return {
        verticalStyle: {
          position: 'absolute',
          right: -70,
          top: 20,
          width: '45.5%',
          height: 290,
          transform: [{ rotate: '0deg' }],
        },
        horizontalStyle: {
          left: '20%',
          top: -25,
          marginBottom: 0,
          width: '70%',
          height: 190,
          marginLeft: -25,
          zIndex: -1,
        },
      };

    case 'harvest':
      return {
        verticalStyle: {
          position: 'absolute',
          right: -100,
          top: -25,
          width: '45.5%',
          height: 290,
          transform: [{ rotate: '0deg' }],
        },
        horizontalStyle: {
          left: -35,
          top: -25,
          width: '70%',
          height: 190,
          marginLeft: -25,
          zIndex: -1,
        },
      };

    case 'mothersday':
      return {
        verticalStyle: {
          width: 200,
          height: 280,
          position: 'absolute',
          top: 80,
          right: -90,
        },
        horizontalStyle: {
          left: '15%',
          top: -40,
          height: 190,
          marginTop: -45,
          marginLeft: -25,
          zIndex: -1,
        },
      };

    case 'independenceday':
      return {
        verticalStyle: {
          width: 100,
          height: 150,
          position: 'absolute',
          top: 60,
          right: -20,
        },
        horizontalStyle: {
          left: '10%',
          top: -10,
          height: 140,
          width: '100%',
          resizeMode: 'contain',
          marginLeft: 0,
          zIndex: -1,
        },
      };

    case 'forest':
      return {
        verticalStyle: {
          width: '100%',
          height: 130,
          position: 'absolute',
          top: 100,
          right: -140,
          transform: `rotate(90deg)`,
        },
        horizontalStyle: {
          left: 25,
          width: '100%',
          height: 130,
          marginLeft: -10,
        },
      };

    default:
      return {
        verticalStyle: {
          width: '80%',
          height: 100,
          marginRight: -35,
          position: 'absolute',
          top: 80,
          right: 25 - 130,
          transform: [{ rotate: '90deg' }],
        },
        horizontalStyle: {
          left: -25,
          top: -25,
          width: '70%',
          height: 130,
          marginLeft: -25,
          zIndex: -1,
        },
      };
  }
};
