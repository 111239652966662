import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { AdFormValue, AdRole, ScreenAction } from '../../types';
import { AdForm } from './AdForm';

export const NewAdScreen: FC = observer(() => {
  const {
    adStore: { createAd, contentTypes, fetchContentTypesIfNotFetched },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  useEffect(() => {
    fetchContentTypesIfNotFetched();
  }, [fetchContentTypesIfNotFetched]);

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: AdFormValue) => {
      return createAd(values).then((id) => {
        if (id) {
          skipCheck(() => history.push(ROUTE.ad.link(id)));
        }
      });
    },
    [createAd, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: AdFormValue = {
    text: '',
    tagText: '',
    link: '',
    role: AdRole.All,
    active: false,
    image: null,
    contentTypeIds: [],
  };

  return (
    <Layout allowOverflow title={t('ad.addNew')} screenActions={actions}>
      {contentTypes.state === 'Fetched' && (
        <AdForm initial={initial} onSubmit={onSubmit} contentTypes={contentTypes.data} />
      )}
    </Layout>
  );
});
