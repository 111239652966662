import { DateTime } from 'luxon';
import { Notification, NotificationId, NotificationListItem, NotificationTarget } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getNotifications = async (): Promise<NotificationListItem[]> => {
  const result = await ApiClient.get<NotificationListItem[]>(PATHS.notifications.root);
  result.data.forEach((data: any) => {
    data.scheduledDateTime = data.scheduledDateTime ? DateTime.fromISO(data.scheduledDateTime) : null;
    data.publishedDateTime = data.publishedDateTime ? DateTime.fromISO(data.publishedDateTime) : null;
  });
  return result.data;
};

export const getNotification = async (id: NotificationId): Promise<Notification> => {
  const result = await ApiClient.get<any>(PATHS.notifications.single(id));
  result.data.scheduledDateTime = result.data.scheduledDateTime
    ? DateTime.fromISO(result.data.scheduledDateTime)
    : null;
  result.data.publishedDateTime = result.data.publishedDateTime
    ? DateTime.fromISO(result.data.publishedDateTime)
    : null;
  return result.data;
};

export interface NotificationUpdateRequest {
  readonly title: string;
  readonly link: string | null;
  readonly message: string;
  readonly target: NotificationTarget;
  readonly scheduledDateTime: DateTime | null;
}

export const createNotification = async (request: NotificationUpdateRequest): Promise<NotificationId> => {
  const result = await ApiClient.post<{ id: number }>(PATHS.notifications.root, request);
  return result.data.id;
};

export const updateNotification = async (id: NotificationId, request: NotificationUpdateRequest): Promise<Notification> => {
  const result = await ApiClient.put<any>(PATHS.notifications.single(id), request);
  result.data.scheduledDateTime = result.data.scheduledDateTime
    ? DateTime.fromISO(result.data.scheduledDateTime)
    : null;
  result.data.publishedDateTime = result.data.publishedDateTime
    ? DateTime.fromISO(result.data.publishedDateTime)
    : null;
  return result.data;
};

export const deleteNotification = async (id: NotificationId): Promise<void> => {
  await ApiClient.delete(PATHS.notifications.single(id));
};
