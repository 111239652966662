import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography/Typography';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { FC, useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { InputField, SubmitButton } from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { COLORS } from '../../styles';
import { PollFormValue } from '../../types';
import { validators } from '../../utils';

export interface PollFormProps {
  initial: PollFormValue;
  onSubmit: (poll: PollFormValue) => Promise<void>;
}

const PollBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const ImgBox = styled('div')({
  backgroundColor: COLORS.white,
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
});

const InputBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
});

const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '25px',
  marginBottom: '25px',
});

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

export const PollForm: FC<PollFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, pristine, error }: FormRenderProps<PollFormValue>) => {
      return (
        <PollBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="title"
                name="title"
                label={t('poll.form.title')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(100))}
              />
              <InputField
                id="infoText"
                name="infoText"
                label={t('poll.form.infoText')}
                rows={5}
                validate={validators.maximumLength(1024)}
              />
              <InputField
                id="link"
                name="link"
                label={t('poll.form.link')}
                rows={1}
                validate={validators.and(validators.isLink, validators.maximumLength(255))}
              />
            </FormBox>
          </FormBoxGroup>

          <TitleContainer>
            <Typography variant="h2">{t('poll.form.answerHeader')}</Typography>
            <Typography variant="body1">{t('poll.form.answerInstructions')}</Typography>
          </TitleContainer>

          <FormBox flexDirection="row" flexSpacing="40px">
            <InputBox>
              <FieldArray name="options">
                {({ fields }) => (
                  <>
                    {[0, 1, 2, 3].map((i) => {
                      return (
                        <InputField
                          key={i}
                          id={`options[${i}]`}
                          name={`options[${i}]`}
                          placeholder={t(`poll.form.placeholder${i}`)}
                        />
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </InputBox>
            <ImgBox>
              <img src="/pikakysely.png" alt={t('poll.form.alt')} />
            </ImgBox>
          </FormBox>

          <StateToolRow>
            <StateToolButtonBox>
              <SubmitButton
                label={t('poll.form.save')}
                loading={submitting}
                disabled={pristine}
                onClick={handleSubmit}
              />
              {submitFailed && invalid ? (
                <Typography variant="body1" color="error">
                  {error ? t(error) : t('poll.form.errors')}
                </Typography>
              ) : null}
            </StateToolButtonBox>
          </StateToolRow>
        </PollBaseForm>
      );
    },
    [t],
  );

  return (
    <>
      <Form
        initialValues={initial}
        onSubmit={onSubmit}
        render={render}
        mutators={{ ...arrayMutators }}
        validate={(props) => {
          const notEnoughOptions =
            props.options.filter((v) => v !== null && v !== undefined && v.trim() !== '').length < 2;
          return notEnoughOptions ? { [FORM_ERROR]: t('poll.form.atLeastOneOption') } : undefined;
        }}
      />
    </>
  );
};
