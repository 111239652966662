import { Button, CircularProgress, Divider, IconButton } from '@material-ui/core';
import { MapOrEntries, useMap } from 'usehooks-ts';
import { Tag as TagType, TagGroupWithTags } from './EditTagGroupsScreen';
import { useCallback, useEffect, useState } from 'react';
import { TextInput } from './TagGroupForm';
import { Delete } from '@material-ui/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiClient } from '../../services/client';
import { useStores } from '../../stores';
import { ConfirmDialog, ExistingImage, FileWithPreview, ImageUploader } from '../../components';
import { useTranslation } from 'react-i18next';

// Anteeksi tästä ->
type TagGroupWithNonNullTag = Omit<TagGroupWithTags, 'tags'> & {
  tags: TagType[];
};
export type ITag = Omit<
  TagGroupWithNonNullTag['tags'][0],
  'id' | 'updatedDateTime' | 'createdDateTime' | 'groupId' | 'image'
> & {
  groupId: number | undefined;
  id?: number;
  image?: FileWithPreview | null;
};
// <- Anteeksi ohi

interface TagProps {
  tag: ITag;
  id: string;
  handleChange: (id: string, newState: ITag) => void;
  deleteSelf: (id: string) => void;
}

function Tag(props: TagProps) {
  const { t } = useTranslation();
  function handleChange(key: string, value: string | boolean | FileWithPreview) {
    props.handleChange(props.id, {
      ...props.tag,
      id: props.tag.id,
      [key]: value,
    });
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center' }}>
        <TextInput
          label={t('editTags.form.name')}
          onChange={(e) => handleChange('name', e)}
          value={props.tag.name}
          placeholder={t('editTags.form.namePlaceholder')}
        />
        <span style={{ display: 'flex', gap: 10, marginTop: 20 }}>
          <p style={{ margin: 0 }}>{t('editTags.form.active')}</p>
          <input
            type="checkbox"
            checked={props.tag.active}
            onChange={(e) => handleChange('active', e.target.checked)}
          />
        </span>

        <ImageUploader
          required
          value={(props.tag.image as unknown) as ExistingImage}
          onChange={(e) => handleChange('image', e)}
          label={t('editTags.form.image')}
        />

        {props.tag.id && (
          <span style={{ marginLeft: 20, marginTop: 20 }}>
            <IconButton size="small" onClick={() => props.deleteSelf(props.id)}>
              <Delete />
            </IconButton>
          </span>
        )}
      </div>

      <Divider style={{ marginBottom: 20, marginTop: 20 }} />
    </div>
  );
}

interface TagFormProps {
  tags?: MapOrEntries<string, ITag>;
  tagGroupId: string;
}

const mutateTag = async (data: { body: { tags: ITag[] }; forId: string }) => {
  const form = new FormData();
  form.append('request', JSON.stringify({ ...data.body }));
  data.body.tags.forEach((tag) => {
    if (!tag.image) return;
    if (!tag.image.file) return;
    const file = new File([tag.image.file], `-_-${tag.name}-_-${tag.image.name}`, { type: tag.image.file.type });
    form.append('images[]', file);
  });

  const response = await ApiClient.put<TagGroupWithTags>(`/admin/tagGroups/v1/group/${data.forId}/tags`, form);
  return response.data;
};

const deleteTag = async (data: { tagId: string }) => {
  const response = await ApiClient.delete<number>(`/admin/tagGroups/v1/tag/${data.tagId}`);
  return response.data;
};

const TagForm = (props: TagFormProps) => {
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState<string[]>([]);
  const [confirmVisible, setConfirmVisible] = useState<string | false>(false);
  const { tagStore } = useStores();
  const { t } = useTranslation();
  const [map, actions] = useMap<string, ITag>(props.tags);

  const mutation = useMutation({
    mutationFn: mutateTag,
    onSuccess: async () => {
      queryClient.refetchQueries(['tagGroup', String(props.tagGroupId)]);
      await tagStore.refetchTags();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteTag,
    onSuccess: async () => {
      queryClient.refetchQueries(['tagGroup', props.tagGroupId]);
      await tagStore.refetchTags();
    },
  });

  useEffect(() => {
    if (props.tags) {
      actions.reset();
      actions.setAll(props.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tags]);

  const handleNew = useCallback(() => {
    actions.set(String(Date.now()), {
      sfInterest: null,
      groupId: Number(props.tagGroupId),
      active: true,
      image: null,
      name: '',
      scheduledDateTime: null,
      id: undefined,
    });
  }, [actions, props.tagGroupId]);

  useEffect(() => {
    if (map.size === 0) handleNew();
  }, [map.size, handleNew]);

  const handleChange = (id: string, newState: ITag) => {
    actions.set(id, newState);
    if (mutation.isSuccess) mutation.reset();
  };
  const handleDelete = (id: string) => {
    deleteMutation.reset();
    if (!confirmVisible) return setConfirmVisible(id);
    setConfirmVisible(false);
    deleteMutation.mutate({ tagId: id });
    actions.remove(id);
    if (mutation.isSuccess) mutation.reset();
  };

  const handleSave = () => {
    setErrors([]);
    if (mutation.isSuccess) mutation.reset();
    const tags = Array.from(map.values());

    let errors: string[] = [];
    tags.forEach((tag) => {
      if (tag.name === '') errors.push(t('editTags.validation.name'));
      if (tag.image === null) errors.push(t('editTags.validation.image'));
    });

    if (errors.length !== 0) return setErrors(errors);

    mutation.mutate({ body: { tags }, forId: props.tagGroupId });
  };

  const ConfirmModal = () =>
    confirmVisible !== false ? (
      <ConfirmDialog
        open
        title={t('editTags.alert.title')}
        message={t('editTags.alert.message')}
        acceptOption="Poista"
        cancelOption="Ei poisteta"
        onAccept={() => {
          handleDelete(confirmVisible);
        }}
        onCancel={() => setConfirmVisible(false)}
      />
    ) : null;

  return (
    <div>
      {Array.from(map.entries()).map(([key, value]) => (
        <Tag deleteSelf={handleDelete} handleChange={handleChange} key={key} id={key} tag={value} />
      ))}

      <ConfirmModal />

      {errors.length !== 0 && (
        <div style={{ marginTop: 20 }}>
          {errors.map((error) => (
            <p style={{ color: 'red' }}>{error}</p>
          ))}
        </div>
      )}

      {mutation.isSuccess && <p style={{ color: 'green' }}>{t('editTags.saved')}</p>}
      {deleteMutation.isSuccess && <p style={{ color: 'green' }}>{t('editTags.removed')}</p>}
      <span style={{ display: 'flex' }}>
        <Button size="small" type="button" variant="contained" color="primary" disableElevation onClick={handleNew}>
          {t('editTags.new')}
        </Button>

        <Button
          size="small"
          type="button"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSave}
          style={{ marginLeft: 10 }}
          disabled={map.size === 0}
        >
          {t('editTags.save')}
        </Button>

        {mutation.isLoading && <CircularProgress style={{ marginLeft: 20 }} />}
      </span>
    </div>
  );
};

export { TagForm };
