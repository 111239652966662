import { flow, makeAutoObservable } from 'mobx';
import { tipApi } from '../services/api';
import {
  mapRemoteData,
  MassTipUpdateValues,
  RemoteData,
  RequestState,
  Tag,
  TagGroup,
  Tip,
  TipId,
  TipListItem,
  TipState,
  TipTableRow,
} from '../types';
import { executeRequest, fetchRemoteData } from './storeUtils';

interface HolidayTipListingFilters {
  state: TipState;
  group: TagGroup | null;
  tag: Tag | null;
  keyword: string | null;
}

export class HolidayTipStore {
  tips: RemoteData<TipListItem[]> = { state: 'NotFetched' };
  listingFilters: HolidayTipListingFilters = { state: TipState.Published, group: null, tag: null, keyword: null };
  selectedTipRows: TipId[] = [];

  tip: RemoteData<Tip> = { state: 'NotFetched' };
  savingTip: RequestState = 'Initial';
  deletingTip: RequestState = 'Initial';

  get holidayTipsByState(): RemoteData<Record<TipState, TipListItem[]>> {
    return mapRemoteData(
      this.tips,
      (tips): Record<TipState, TipListItem[]> =>
        tips.reduce(
          (acc: Record<TipState, TipListItem[]>, tip: TipListItem) => {
            const state: TipState =
              tip.publishedDateTime !== null
                ? TipState.Published
                : tip.scheduledDateTime !== null
                ? TipState.Scheduled
                : TipState.Draft;

            return { ...acc, [state]: [...acc[state], tip] };
          },
          { [TipState.Draft]: [], [TipState.Scheduled]: [], [TipState.Published]: [] },
        ),
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  setListingStateFilter = (state: TipState) => {
    this.listingFilters.state = state;
  };

  setListingKeywordFilter = (keyword: string | null) => {
    this.listingFilters.keyword = keyword;
  }

  setListingGroupFilter = (group: TagGroup | null) => {
    this.listingFilters.group = group;
    this.listingFilters.tag = null;
  };

  setListingTagFilter = (tag: Tag | null) => {
    this.listingFilters.tag = tag;
  };

  setSelectedTipRows = (tipRows?: { original: TipTableRow }[]) => {
    this.selectedTipRows = (tipRows ?? []).map(({ original }) => original.id);
  };

  fetchHolidayTips: () => Promise<void> = flow(function* (this: HolidayTipStore) {
    yield fetchRemoteData(this, 'tips', async () => await tipApi.getHolidayTips());
  }).bind(this);

  fetchHolidayTip: (id: TipId) => Promise<void> = flow(function* (this: HolidayTipStore, id: TipId) {
    yield fetchRemoteData(this, 'tip', () => tipApi.getTip(id));
  }).bind(this);

  massUpdateHolidayTips: (params: MassTipUpdateValues) => Promise<void> = flow(function* (
    this: HolidayTipStore,
    params: MassTipUpdateValues,
  ) {
    this.tips = { state: 'Fetching' };
    yield executeRequest(this, 'savingTip', () => tipApi.massUpdateTips(params));
    yield this.fetchHolidayTips();
  }).bind(this);
}
