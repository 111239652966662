import { Box, styled } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { IconButton } from '../FormControls';
import { useStores } from '../../stores';
import { COLORS } from '../../styles';

interface UserControlsProps {}

const UserControlsContainer = styled('div')({
  marginTop: '27px',
});

const UserControls: FC<UserControlsProps> = observer(() => {
  const {
    authStore: { logout },
  } = useStores();

  return (
    <UserControlsContainer>
      <IconButton
        size={50}
        aria-label="delete"
        icon={ExitToApp}
        onClick={async () => {
          logout();
        }}
      />
    </UserControlsContainer>
  );
});

interface HeaderProps {}
const HeaderBox = styled(Box)({
  color: '#fff',
  height: '105px',
  borderBottom: `1px solid ${COLORS.primary}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

const HeaderBoxItem = styled('div')({
  color: COLORS.black,
  height: '104px',
  paddingLeft: '50px',
  paddingRight: '50px',
  borderLeft: `1px solid ${COLORS.primary}`,
});

export const Header: FC<HeaderProps> = () => {
  return (
    <HeaderBox pl={4} pr={4}>
      <HeaderBoxItem>
        <UserControls />
      </HeaderBoxItem>
    </HeaderBox>
  );
};
