import React from 'react';
import { IconButton, IconButtonRow, Layout } from '../../components';
import { useTranslation } from 'react-i18next';
import { MaterialTableCell, ScreenAction, TipTableRow } from '../../types';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../services/client';
import { history } from '../../App';
import { ROUTE } from '../../constants';
import { HolidayTagGroupKeyEnum } from '../../constants/holidayThemes';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { Edit } from '@material-ui/icons';

const actions: ScreenAction[] = [
  {
    label: 'Luo uusi kategoria',
    handler: () => history.push(ROUTE.newTagGroup.link()),
  },
];

export interface TagGroupWithTags {
  id: number;
  name: string;
  category: Category;
  createdDateTime: Date;
  updatedDateTime: Date;
  key: HolidayTagGroupKeyEnum;
  image: string;
  tags: Tag[] | null;
  button: string | null;
  text: string | null;
  title: string | null;
}

export enum Category {
  HolidayTip = 'holidayTip',
}

export interface Tag {
  id: number;
  groupId: number;
  name: string;
  image: string | null;
  createdDateTime: Date;
  updatedDateTime: Date;
  sfInterest: null;
  active: boolean;
  scheduledDateTime: null;
}

const tagFetcher = async () => {
  const response = await ApiClient.get<TagGroupWithTags[]>('/admin/tagGroups/v1/groups');
  return response.data;
};

const EditTagGroupsScreen = () => {
  const { t } = useTranslation();

  const query = useQuery({
    queryKey: ['tagGroups'],
    queryFn: tagFetcher,
  });

  const columns = [
    {
      accessor: 'name',
      Header: t('editTagGroups.category'),
    },
    {
      accessor: 'title',
      Header: t('editTagGroups.title'),
      width: '20%',
    },
    {
      accessor: 'tools',
      className: 'cellBorderLeft',
      width: '12%',
      Cell: ({ row: { original } }: MaterialTableCell<TipTableRow>) => {
        return (
          <IconButtonRow>
            <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.editTagGroup.link(original.id))} />
          </IconButtonRow>
        );
      },
    },
  ];

  const data = query.data;

  return (
    <Layout allowOverflow title={t('editTags.title')} screenActions={actions}>
      <MaterialTable
        data={data}
        columns={columns}
        onPageChange={() => {}}
        pagination={null}
        onSortingChange={() => {}}
      />
    </Layout>
  );
};

export { EditTagGroupsScreen };
