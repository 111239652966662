import React, { FC, useCallback, useState } from 'react';
import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography/Typography';
import { Add } from '@material-ui/icons';
import arrayMutators from 'final-form-arrays';
import { DateTime, Interval } from 'luxon';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import {
  Button,
  ExistingImage,
  FileWithPreview,
  ImageUploaderField,
  InputField,
  SubmitButton,
} from '../../components/FormControls';
import { FormBox, FormBoxGroup, BaseForm } from '../../components/FormLayout';
import { PartnerFormValue, PartnerFormValueElement } from '../../types';
import { validators } from '../../utils';
import { PartnerElementEditorField } from './PartnerElementEditor';
import { PartnerScheduleFields } from './PartnerScheduleFields';

export interface PartnerFormProps {
  initial: PartnerFormValue;
  scheduled?: boolean;
  onSubmit: (partner: PartnerFormValue) => Promise<void>;
  invalidIntervals?: Interval[];
}

const PartnerBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const PartnerForm: FC<PartnerFormProps> = ({ initial, scheduled = false, onSubmit, invalidIntervals }) => {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState<{
    state: 'toDraft' | 'reschedule';
    resolve: () => void;
    value: PartnerFormValue;
  } | null>();

  const renderConfirmModal = () => {
    if (!confirm) {
      return null;
    }
    return (
      <ConfirmDialog
        open
        title={t(confirm.state === 'toDraft' ? 'partner.form.confirmToDraft' : 'partner.form.confirmReschedule')}
        onAccept={async () => {
          await onSubmit(confirm.value);
          setConfirm(null);
          confirm.resolve();
        }}
        onCancel={() => {
          setConfirm(null);
          confirm.resolve();
        }}
      />
    );
  };

  const submit = (value: PartnerFormValue) =>
    new Promise((resolve) => {
      const scheduledDateTimeChanged = (): boolean =>
        value.scheduledStartDateTime !== null && initial.scheduledStartDateTime !== null
          ? !value.scheduledStartDateTime.equals(initial.scheduledStartDateTime)
          : !value.scheduledStartDateTime !== !initial.scheduledStartDateTime;

      if (scheduled && value.submitType === 'draft') {
        setConfirm({
          state: 'toDraft',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else if (scheduled && scheduledDateTimeChanged()) {
        setConfirm({
          state: 'reschedule',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else {
        resolve(onSubmit(value));
      }
    });

  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, values, form }: FormRenderProps<PartnerFormValue>) => {
      const getSubmitButtonText = () => {
        // Handle first scenario when there is no scheduling actions by user
        if (
          values.scheduledStartDateTime === null ||
          initial.scheduledStartDateTime?.equals(values.scheduledStartDateTime)
        ) {
          return scheduled ? 'partner.form.update' : 'partner.form.schedule';
        }

        // Check if really scheduled to the future, use publish as default if somebody scheduling to the past
        return values.scheduledStartDateTime > DateTime.local() ? 'partner.form.schedule' : 'partner.form.publish';
      };

      return (
        <PartnerBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="title"
                name="title"
                label={t('partner.title')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(100))}
              />
              <InputField
                id="shortDescription"
                name="shortDescription"
                label={t('partner.form.shortDescription')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(100))}
              />
              <ImageUploaderField
                name="image"
                label={t('partner.form.logo')}
                required={true}
                validate={validators.and(validators.required, validators.maximumFilesizeMb(1))}
              />
            </FormBox>

            <FieldArray name="elements">
              {({ fields }) =>
                fields.map((name, ix) => (
                  <FormBox key={name} title={ix === 0 ? t('partner.form.content') : undefined}>
                    <PartnerElementEditorField
                      key={name}
                      name={name}
                      onRemove={fields.length === 1 ? undefined : () => form.mutators.removeElement(ix)}
                      onMove={(direction) => form.mutators.onMove(ix, direction)}
                    />
                  </FormBox>
                ))
              }
            </FieldArray>
            <Button
              label={t('partner.form.addBlock')}
              iconRight={Add}
              onClick={form.mutators.addElement}
              disabled={values.elements.length >= 5}
            />

            <FormBox flexDirection="column" flexSpacing="40px" title={t('partner.form.lastSlide')}>
              <InputField
                id="longDescription"
                name="longDescription"
                label={t('partner.form.longDescription')}
                rows={7}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(400))}
              />
              <InputField
                id="linkText"
                name="linkText"
                label={t('partner.form.linkText')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(100))}
              />
              <InputField
                id="link"
                name="link"
                label={t('partner.form.link')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(2000), validators.isLink)}
              />
            </FormBox>
          </FormBoxGroup>

          <StateToolRow>
            <StateToolButtonBox>
              <SubmitButton
                label={t(scheduled ? 'partner.form.toDraft' : 'partner.form.saveAsDraft')}
                loading={submitting}
                onClick={() => {
                  form.mutators.setSubmitType('draft');
                  handleSubmit();
                }}
              />
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('partner.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <PartnerScheduleFields
                  invalidIntervals={invalidIntervals}
                  initialStart={initial.scheduledStartDateTime}
                  initialEnd={initial.scheduledEndDateTime}
                />
                <SubmitButton
                  label={t(getSubmitButtonText())}
                  loading={submitting}
                  onClick={() => {
                    form.mutators.setSubmitType('schedule');
                    handleSubmit();
                  }}
                />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('partner.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
        </PartnerBaseForm>
      );
    },
    [t, scheduled, initial, invalidIntervals],
  );

  return (
    <>
      <Form
        initialValues={initial}
        mutators={{
          setSubmitType: ([submitType], state, utils) => {
            utils.changeValue(state, 'submitType', () => submitType);
          },
          onMove: ([ix, direction], state, utils) => {
            const elements: PartnerFormValueElement[] = (state.formState.values as any).elements.concat();
            const nextIx = ix + (direction === 'up' ? -1 : 1);
            if (nextIx < 0 || nextIx >= elements.length) {
              return;
            }

            // Update object URL when image is moved. Component frees old url on unmount
            const process = (element: PartnerFormValueElement) => {
              const image: FileWithPreview | ExistingImage | null =
                element.image?.valueType === 'file'
                  ? {
                      valueType: 'file',
                      name: element.image.name,
                      file: element.image.file,
                      preview: URL.createObjectURL(element.image.file),
                    }
                  : element.image;

              return {
                text: element.text,
                image,
              };
            };

            const value = process(elements[ix]);
            const other = process(elements[nextIx]);

            elements[ix] = other;
            elements[nextIx] = value;
            utils.changeValue(state, 'elements', () => elements);
          },
          addElement: (args, state, utils) =>
            utils.changeValue(state, 'elements', () => [
              ...(state.formState.values as any).elements,
              { text: '', image: null },
            ]),
          removeElement: ([ix], state, utils) =>
            utils.changeValue(state, 'elements', () => {
              const value = (state.formState.values as any).elements;
              return [...value.slice(0, ix), ...value.slice(ix + 1)];
            }),
          ...arrayMutators,
        }}
        onSubmit={submit}
        render={render}
      />
      {renderConfirmModal()}
    </>
  );
};
