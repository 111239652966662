import { EditorState } from 'draft-js';
import { values } from 'lodash/fp';
import { ExistingImage, FileWithPreview } from '../components/FormControls';
import { Tag, TagGroup, TagId } from './Tag';
import { DateTime } from 'luxon';

export type TipId = number;

export interface TipListItem {
  readonly id: TipId;
  readonly title: string;
  readonly tagId: TagId;
  readonly season: TipSeasonKey | TipSeasonKey[];
  readonly scheduledDateTime: DateTime | null;
  readonly publishedDateTime: DateTime | null;
}

export interface TipTableRow {
  id: TipId;
  title: string;
  season: TipSeasonKey | TipSeasonKey[];
  mainCategory: string;
  subCategory: string;
  publishedDateTime: DateTime | null;
  scheduledDateTime: DateTime | null;
}

export enum TipElementType {
  Text = 'text',
  Image = 'image',
  Youtube = 'youtube',
}

export enum TipState {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Published = 'published',
}

export const tipElementTypes = values(TipElementType);

export type TipElement =
  | {
      readonly type: TipElementType.Text;
      readonly content: string;
    }
  | {
      readonly type: TipElementType.Image;
      readonly image: string;
      readonly imageUrl: string;
    }
  | {
      readonly type: TipElementType.Youtube;
      readonly videoId: string;
    };

export enum TipReminderFrequency {
  OnceAWeek = 'once_a_week',
  EveryOtherWeek = 'every_other_week',
  OnceAMonth = 'once_a_month',
  EveryOtherMonth = 'every_other_month',
  FourTimesAYear = 'four_times_a_year',
  ThreeTimesAYear = 'three_times_a_year',
  TwiceAYear = 'twice_a_year',
  OnceAYear = 'once_a_year',
}

export const tipReminderFrequencies = values(TipReminderFrequency);

export enum TipSeasonKey {
  Winter = 'winter',
  EarlySpring = 'early_spring',
  Spring = 'spring',
  Summer = 'summer',
  Autumn = 'autumn',
  EarlyWinter = 'early_winter',
}

export const tipSeasonKeys = values(TipSeasonKey);

export interface TipReminderSettings {
  readonly enabled: boolean;
  readonly title: string | null;
  readonly text: string | null;
  readonly frequency: TipReminderFrequency;
  readonly defaultDateTime: DateTime;
}

export interface Tip {
  readonly id: TipId;
  readonly title: string;
  readonly image: string;
  readonly imageUrl: string;
  readonly primaryTagId: TagId;
  readonly secondaryTagIds: TagId[];
  readonly scheduledDateTime: DateTime | null;
  readonly publishedDateTime: DateTime | null;
  readonly didYouKnow?: {
    readonly text: string;
    readonly value: string;
  };
  readonly season: {
    readonly key: TipSeasonKey | TipSeasonKey[] | null;
    readonly title: string | null;
    readonly text: string | null;
    readonly image: string | null;
    readonly imageUrl: string | null;
  };
  readonly elements: TipElement[];
  readonly reminder: TipReminderSettings;
  readonly keywords: string | null;
}

export type TipFormValueElement =
  | {
      readonly type: TipElementType.Text;
      readonly content: EditorState;
    }
  | {
      readonly type: TipElementType.Image;
      readonly image: FileWithPreview | ExistingImage | null;
    }
  | {
      readonly type: TipElementType.Youtube;
      readonly videoId: string;
    };

export interface TipFormValue {
  readonly submitType: 'draft' | 'schedule';
  readonly title: string;
  readonly image: FileWithPreview | ExistingImage | null;
  readonly tagGroup: TagGroup | null;
  readonly primaryTag: Tag | null;
  readonly secondaryTags: {
    readonly ingredient: Tag[];
    readonly home: Tag[];
    readonly garden: Tag[];
    readonly food: Tag[];
    readonly crafts: Tag[];
  };
  readonly scheduledDateTime: DateTime | null;
  readonly didYouKnow: {
    readonly text?: string | null;
    readonly value?: string | null;
  };
  readonly season: {
    readonly key: TipSeasonKey[] | null;
    readonly title: string | null;
    readonly text: string | null;
    readonly image: FileWithPreview | ExistingImage | null;
  };
  readonly reminder: {
    readonly enabled: boolean;
    readonly title: string | null;
    readonly text: string | null;
    readonly frequency: TipReminderFrequency;
    readonly defaultDateTime: DateTime;
  };
  readonly elements: TipFormValueElement[];
  readonly keywords: string | null;
}

export type MassTipUpdateValues = {
  ids: TipId[];
  published?: boolean;
  seasonKeys?: TipSeasonKey[];
};

export type MassHolidayTipUpdateParams = Omit<MassTipUpdateValues, 'season'>;

export type CopyToTipsParams = {
  ids: TipId[];
  tagId: TagId;
  seasonKeys?: TipSeasonKey[];
};
