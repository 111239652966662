import { Checkbox as MaterialCheckbox, FormControlLabel, Typography } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';

interface CheckboxFieldProps {
  id: string;
  name: string;
  label: string;
  validate?: any;
  disabled?: boolean;
  required?: boolean;
  onChangeSideEffect?: (v: boolean) => void;
}

export const CheckBoxField: (props: CheckboxFieldProps) => React.ReactElement<CheckboxFieldProps> = ({
  id,
  name,
  label,
  validate,
  disabled = false,
  required = false,
  onChangeSideEffect,
}) => {
  const render = (props: any) => {
    const { input } = props;

    const change = (event: any, checked: boolean) => {
      if (onChangeSideEffect) onChangeSideEffect(checked);
      input.onChange(checked);
    };

    const checkbox = (
      <MaterialCheckbox
        {...props.input}
        id={id}
        color="primary"
        disabled={disabled}
        required={required}
        onChange={change}
      />
    );

    return <FormControlLabel control={checkbox} label={<Typography variant="h3">{label}</Typography>} />;
  };

  return <Field name={name} type="checkbox" validate={validate} render={render} />;
};
