import React, { FC, ReactElement } from 'react';
import { MenuItem, Select as MuiSelect, styled, TextField } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Delete, KeyboardArrowDown } from '@material-ui/icons';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, ImageUploader, editorStyles, editorToolbar } from '../../components/FormControls';
import { COLORS } from '../../styles';
import { TipElementType, TipFormValueElement } from '../../types';

export interface TipElementEditorProps {
  value: TipFormValueElement;
  error?: string;
  onChange: (value: TipFormValueElement) => void;
  onRemove?: () => void;
  onMove: (direction: 'up' | 'down') => void;
  onFocus?: (ev: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (ev: React.FocusEvent<HTMLElement>) => void;
}

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '5px',
  '& > *': {
    borderWidth: 0,
  },
  '& > *:not(:last-child)': {
    marginRight: '5px',
  },
  '& > *:last-child': {
    marginLeft: 'auto',
  },
});

const Select = styled(MuiSelect)({
  '& .MuiSelect-outlined': {
    paddingRight: 42,
  },
  '& .MuiSelect-icon': {
    fontSize: 22,
    top: 'calc(50% - 10px)',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey,
  },
});

export const TipElementEditor: FC<TipElementEditorProps> = ({
  value,
  error,
  onChange,
  onRemove,
  onMove,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();
  let component: ReactElement | null = null;

  const onChangeType = (type: TipElementType) => {
    switch (type) {
      case TipElementType.Text:
        onChange({ type, content: EditorState.createEmpty() });
        return;
      case TipElementType.Image:
        onChange({ type, image: null });
        return;
      case TipElementType.Youtube:
        onChange({ type, videoId: '' });
        return;
    }
  };

  const typeSelect = (
    <Select
      variant="outlined"
      value={value.type}
      onChange={(ev) => onChangeType(ev.target.value as TipElementType)}
      IconComponent={KeyboardArrowDown}
    >
      <MenuItem value={TipElementType.Text}>Wysiwyg</MenuItem>
      <MenuItem value={TipElementType.Image}>Kuva (max 2MB)</MenuItem>
      <MenuItem value={TipElementType.Youtube}>Youtube</MenuItem>
    </Select>
  );

  switch (value.type) {
    case TipElementType.Text:
      component = (
        <Editor
          toolbar={editorToolbar}
          editorState={value.content}
          onEditorStateChange={(content) => onChange({ type: TipElementType.Text, content })}
          {...editorStyles}
        />
      );
      break;

    case TipElementType.Youtube:
      component = (
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('tip.element.youtubeId')}
          value={value.videoId}
          error={error !== undefined}
          helperText={error ? t(error) : undefined}
          onChange={(ev) => onChange({ type: TipElementType.Youtube, videoId: ev.target.value })}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      );
      break;

    case TipElementType.Image:
      component = (
        <ImageUploader
          error={error}
          value={value.image}
          onChange={(image) => onChange({ type: TipElementType.Image, image })}
        />
      );
      break;
  }

  return (
    <div>
      <ButtonsContainer>
        {typeSelect}
        <IconButton size={50} icon={ArrowUpward} onClick={() => onMove('up')} />
        <IconButton size={50} icon={ArrowDownward} onClick={() => onMove('down')} />
        {onRemove ? <Button label={t('tip.element.remove')} iconRight={Delete} onClick={onRemove} /> : <span />}
      </ButtonsContainer>
      {component}
    </div>
  );
};

export interface TipElementEditorFieldProps {
  name: string;
  onRemove?: () => void;
  onMove: (direction: 'up' | 'down') => void;
}

export const TipElementEditorField: FC<TipElementEditorFieldProps> = ({ name, onRemove, onMove }) => {
  const render = (props: FieldRenderProps<any, HTMLElement>) => (
    <TipElementEditor
      value={props.input.value}
      error={props.meta.touched ? props.meta.error : undefined}
      onChange={props.input.onChange}
      onRemove={onRemove}
      onMove={onMove}
      onFocus={props.input.onFocus}
      onBlur={props.input.onBlur}
    />
  );

  return (
    <Field
      name={name}
      required={true}
      render={render}
      validate={(value: TipFormValueElement) => {
        switch (value?.type) {
          case TipElementType.Image:
            if (value.image?.valueType === 'file') {
              const filesizeMb = value.image.file.size / 1024 / 1024;
              if (filesizeMb > 2) return 'errors.validation.filesizeTooLarge';
            }
            return value.image === null ? 'errors.validation.required' : undefined;
          case TipElementType.Youtube:
            return !value.videoId ? 'errors.validation.required' : undefined;
          default:
            return undefined;
        }
      }}
    />
  );
};
