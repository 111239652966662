import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Grid, styled } from '@material-ui/core';

import { ComboBox } from '../../components/FormControls/ComboBox';
import { Button, SearchField } from '../../components/FormControls';
import { ConfirmDialog, CopyToTipDialog } from '../../components/Dialog';
import { useStores } from '../../stores';
import { Tag, ArticleStateKey, articleStateKeys, HolidayTagGroupKey } from '../../types';
import { DARK_GREY, PRIMARY } from '../../styles';

const MassActionHeader = styled('span')({
  fontWeight: 'bold',
  fontSize: 18,
});

const ConfirmButtonContainer = styled(Grid)({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
});

const ConfirmButton = styled(Button)({
  backgroundColor: DARK_GREY,
  padding: '6px 32px',
});

const CopyButton = styled(Button)({
  padding: '6px 32px',
});

const ControlsGrid = styled(Grid)({
  marginBottom: '35px',
  '& > :not(:last-child)': {
    marginRight: '10px',
  },
  alignItems: 'center',
});

const ControlBeforeSeparator = styled(Grid)({
  paddingRight: '16px',
});

const ControlWithLeftSeparator = styled(Grid)({
  borderLeft: `2px solid ${PRIMARY}`,
  paddingLeft: '16px',
});

export const HolidayListActions: FC = observer(() => {
  const {
    holidayTipStore: {
      listingFilters: { group: groupFilter, tag: tagFilter },
      setListingGroupFilter,
      setListingTagFilter,
      selectedTipRows,
      massUpdateHolidayTips,
      setSelectedTipRows,
      setListingKeywordFilter,
    },
    tipStore: { copyToTips },
    tagStore: { tags },
  } = useStores();
  const { t } = useTranslation();

  const [toState, setToState] = useState<ArticleStateKey | null>(null);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [copyVisible, setCopyVisible] = useState<boolean>(false);

  const allowedTagGroups = useMemo(() => {
    return tags.state === 'Fetched'
      ? tags.data.groups.filter((g) =>
          Object.values(HolidayTagGroupKey).includes((g.key as unknown) as HolidayTagGroupKey),
        )
      : [];
  }, [tags]);

  const onKeywordChange = (value: string) => {
    setListingKeywordFilter(value);
  };

  const ConfirmModal = () =>
    confirmVisible ? (
      <ConfirmDialog
        open
        title={t('massActions.confirm.title', { count: selectedTipRows.length })}
        message={toState ? t('massActions.confirm.state', { state: t(`massActions.state.${toState}`) }) : ''}
        onAccept={() => {
          setConfirmVisible(false);
          massUpdateHolidayTips({
            ids: selectedTipRows,
            published: toState ? toState === ArticleStateKey.Publish : undefined,
          }).then(() => {
            setToState(null);
            setSelectedTipRows([]);
          });
        }}
        onCancel={() => setConfirmVisible(false)}
      />
    ) : null;

  return (
    <>
      <CopyToTipDialog
        isVisible={copyVisible}
        onAccept={(tagId, toSeasons) => {
          copyToTips({
            ids: selectedTipRows,
            tagId,
            seasonKeys: toSeasons,
          }).then(() => {
            setSelectedTipRows([]);
            setCopyVisible(false);
          });
        }}
        onClose={() => setCopyVisible(false)}
      />
      <ConfirmModal />
      {selectedTipRows.length ? (
        // Mass action selections
        <ControlsGrid container>
          <Grid item>
            <MassActionHeader>{t('massActions.title')}</MassActionHeader>
          </Grid>
          <Grid item xs={3}>
            <ComboBox<ArticleStateKey>
              placeholder={t('massActions.selectState')}
              options={articleStateKeys}
              value={toState}
              getOptionLabel={(v) => t(`massActions.state.${v}`)}
              onChange={(value) => setToState(value)}
              altStyle
              narrow
            />
          </Grid>
          <ConfirmButtonContainer>
            <ControlBeforeSeparator>
              <ConfirmButton
                label={t('massActions.confirm.button')}
                onClick={() => setConfirmVisible(true)}
                disabled={!toState}
              />
            </ControlBeforeSeparator>
            <ControlWithLeftSeparator>
              <CopyButton label={t('massActions.copy.button')} onClick={() => setCopyVisible(true)} />
            </ControlWithLeftSeparator>
          </ConfirmButtonContainer>
        </ControlsGrid>
      ) : (
        // Filters
        <ControlsGrid container>
          <Grid item xs={3}>
            <SearchField onChange={onKeywordChange} />
          </Grid>
          <Grid item xs={3}>
            <ComboBox
              placeholder={t('holidayTip.list.selectCategory')}
              value={groupFilter}
              options={allowedTagGroups}
              getOptionSelected={(a, b) => a?.id === b?.id}
              getOptionLabel={(g) => g.name}
              onChange={(value) => setListingGroupFilter(value ?? null)}
              narrow
            />
          </Grid>
          <Grid item xs={3}>
            <ComboBox<Tag>
              placeholder={t('holidayTip.list.selectSubCategory')}
              disabled={groupFilter === null}
              value={tagFilter}
              options={
                tags.state === 'Fetched' && groupFilter
                  ? tags.data.tags
                      .filter((t) => t.groupId === groupFilter.id)
                      .filter((it) => !it.name.includes('leivonta') && !it.name.includes('ruokaohjeet') && it.active) ??
                    []
                  : []
              }
              getOptionSelected={(a, b) => a?.id === b?.id}
              getOptionLabel={(t) => t?.name ?? ''}
              onChange={(value) => setListingTagFilter(value)}
              narrow
            />
          </Grid>
        </ControlsGrid>
      )}
    </>
  );
});
