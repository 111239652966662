import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, CircularProgress, styled } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { AdState, AdTableRow, MaterialTableCell, ScreenAction } from '../../types';

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const AdsScreen: FC = observer(() => {
  const {
    adStore: { ads, adsByState, deleteAd, fetchAds, stateFilter, setStateFilter },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [toDelete, setToDelete] = useState<AdTableRow | null>(null);

  useEffect(() => {
    fetchAds();
  }, [fetchAds]);

  const fetching = ads.state === 'Fetching';

  const data: AdTableRow[] = useMemo(() => {
    return adsByState.state !== 'Fetched' ? [] : adsByState.data[stateFilter];
  }, [adsByState, stateFilter]);

  const actions: ScreenAction[] = [
    {
      label: t('ad.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newAd.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'text',
        Header: t('ad.list.text'),
      },
      {
        accessor: 'tagText',
        Header: t('ad.list.tagText'),
      },
      {
        accessor: 'role',
        Header: t('membershipBenefit.list.role'),
        Cell: ({ cell: { value } }: MaterialTableCell<AdTableRow, 'role'>) => {
          return t(`ad.filter.${value}`);
        },
      },
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<AdTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setToDelete(original)} />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.ad.link(original.id))} />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t],
  );

  const deleteModal =
    toDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('ad.list.deleteMessage')}
        message={toDelete.text}
        onAccept={() => {
          setToDelete(null);
          deleteAd(toDelete.id);
        }}
        onCancel={() => setToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}
      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <Tabs value={stateFilter} onChange={(ev, value) => setStateFilter(value)}>
            <Tab label={t('ad.list.active')} value={AdState.Active} />
            <Tab label={t('ad.list.inactive')} value={AdState.Inactive} />
          </Tabs>
          <TableContainer>
            <MaterialTable
              data={data}
              columns={columns}
              onPageChange={() => {}}
              pagination={null}
              onSortingChange={() => {}}
              initialSortBy={[{ id: 'createdAt', desc: false }]}
            />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});
