import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { FieldValidator } from 'final-form';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPickerDTTabs-tabs .MuiTab-root': {
      marginRight: '10px',
      width: 'auto',
      color: 'black',
      '&:first-child': {
        marginLeft: '10px',
      },
    },
  },
});

export type Props = KeyboardDatePickerProps & {
  readonly id?: string;
  readonly name: string;
  readonly className?: string;
  readonly value: DateTime;
  readonly label?: string;
  readonly required: boolean;
  readonly error?: boolean;
  readonly helperText?: string | null;
  readonly disablePast?: boolean;
  readonly hideError?: boolean;
  readonly onChange: (value: DateTime | null) => void;
  readonly minDate?: DateTime;
  readonly shouldDisableDate?: (date: DateTime | null) => boolean;
};

export const DatePicker: FC<Props> = ({
  label,
  required,
  error,
  helperText,
  hideError,
  onChange,
  shouldDisableDate,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleShouldDisableDate = (date: DateTime | null) => (shouldDisableDate ? shouldDisableDate(date) : false);

  return (
    <span>
      {label ? <Typography variant="h3">{`${label}${required ? '*' : ''}`}</Typography> : null}
      <KeyboardDatePicker
        {...rest}
        fullWidth
        okLabel={t('common.select')}
        cancelLabel={t('common.cancel')}
        onChange={(date) => onChange(date ? DateTime.fromJSDate(date?.toJSDate()) : null)}
        inputVariant="outlined"
        format="dd.MM.yyyy"
        error={hideError ? undefined : error}
        helperText={hideError ? undefined : helperText}
        DialogProps={{ className: classes.dialog }}
        shouldDisableDate={(date) => handleShouldDisableDate(date ? DateTime.fromJSDate(date?.toJSDate()) : null)}
      />
    </span>
  );
};

export type DatePickerFieldProps = Omit<Omit<KeyboardDatePickerProps, 'onChange'>, 'value'> & {
  readonly name: string;
  readonly label?: string;
  readonly required: boolean;
  readonly hideError?: boolean;
  readonly validate?: FieldValidator<any>;
  readonly minDate?: DateTime;
  readonly shouldDisableDate?: (date: DateTime | null) => boolean;
};

export const DatePickerField: FC<DatePickerFieldProps> = ({
  name,
  required,
  disablePast,
  hideError,
  validate,
  shouldDisableDate,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Field name={name} required={required} validate={validate}>
      {({ input, meta }) => (
        <DatePicker
          {...rest}
          {...input}
          name={name}
          value={input.value}
          required={required}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error && t(meta.error)}
          onChange={input.onChange}
          shouldDisableDate={shouldDisableDate}
        />
      )}
    </Field>
  );
};
