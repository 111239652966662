import { styled } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import React, { FC, useCallback } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ComboBoxField, ImageUploaderField, InputField, SubmitButton, Wysiwyg } from '../../components/FormControls';
import { BaseForm, FormBox, FormBoxGroup } from '../../components/FormLayout';
import { COLORS } from '../../styles';
import {
  FrontPageSettingsFormValue,
  PollListItem,
  PopularComment,
  RemotePaginatedData,
  TagGroup,
  TipListItem,
} from '../../types';
import { validators } from '../../utils';
import { PopularCommentsEditorField } from './PopularCommentsEditor';

const FrontPageBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const ImgBox = styled('div')({
  backgroundColor: COLORS.white,
  padding: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
});

const Img = styled('img')({
  width: '100%',
});

const InputBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
  '& > *': {
    marginBottom: '25px',
  },
});

const SubmitButtonBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

export interface FrontPageFormProps {
  readonly initial: FrontPageSettingsFormValue;
  readonly polls: PollListItem[];
  readonly tips: TipListItem[];
  readonly popularComments: RemotePaginatedData<PopularComment>;
  readonly holidayTagGroups: TagGroup[];
  readonly fetchMorePopularComments: () => Promise<void>;
  readonly onSubmit: (value: FrontPageSettingsFormValue) => Promise<void>;
}

export const FrontPageForm: FC<FrontPageFormProps> = ({
  initial,
  polls,
  tips,
  holidayTagGroups,
  popularComments: comments,
  fetchMorePopularComments,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const render = useCallback(
    ({ handleSubmit, submitting, pristine }: FormRenderProps<FrontPageSettingsFormValue>) => {
      return (
        <FrontPageBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox title={t('frontPage.holidayTagGroup.title')} flexDirection="column" flexSpacing="40px">
              <ComboBoxField
                name="activeHolidayTagGroup"
                label={t('frontPage.holidayTagGroup.label')}
                options={holidayTagGroups}
                getOptionLabel={(o) => o.name}
                getOptionSelected={(a, b) => a.id === b.id}
              />
            </FormBox>

            <FormBox title={t('frontPage.poll')} flexDirection="column" flexSpacing="40px">
              <ComboBoxField
                name="frontPagePoll"
                label={t('frontPage.activePoll')}
                options={polls}
                getOptionLabel={(o) => o.title}
                getOptionSelected={(a, b) => a.id === b.id}
              />
            </FormBox>

            <FormBox title={t('frontPage.riddle.group')} flexDirection="column" flexSpacing="40px">
              <InputField
                id="frontPageRiddleName"
                name="frontPageRiddleName"
                label={t('frontPage.riddle.name')}
                validate={validators.maximumLength(255)}
              />
              <InputField
                id="frontPageRiddleLink"
                name="frontPageRiddleLink"
                label={t('frontPage.riddle.link')}
                validate={validators.maximumLength(255)}
              />
            </FormBox>

            <FormBox title={t('frontPage.season.title')} flexDirection="column" flexSpacing="40px">
              <ComboBoxField
                name="frontPageSeasonTip"
                label={t('frontPage.season.tip')}
                options={tips}
                getOptionLabel={(o) => `${o.title} (${t(`tip.list.seasonLabel.${o.season}`)})`}
                getOptionSelected={(a, b) => a.id === b.id}
              />
              <InputField
                id="frontPageSeasonIngredient"
                name="frontPageSeasonIngredient"
                label={t('frontPage.season.ingredient')}
                validate={validators.maximumLength(255)}
              />
            </FormBox>

            <FormBox title={t('frontPage.highlight.heading')} flexDirection="row" flexSpacing="40px">
              <InputBox>
                <InputField
                  id="frontPageHighlightTitle"
                  name="frontPageHighlightTitle"
                  label={t('frontPage.highlight.title')}
                  validate={validators.maximumLength(80)}
                />
                <InputField
                  id="frontPageHighlightText"
                  name="frontPageHighlightText"
                  label={t('frontPage.highlight.text')}
                  validate={validators.maximumLength(255)}
                />
                <InputField
                  id="frontPageHighlightButton"
                  name="frontPageHighlightButton"
                  label={t('frontPage.highlight.button')}
                  validate={validators.maximumLength(80)}
                />
                <InputField
                  id="frontPageHighlightLink"
                  name="frontPageHighlightLink"
                  label={t('frontPage.highlight.link')}
                />
                <InputField
                  id="frontPageHighlightOptionalButton"
                  name="frontPageHighlightOptionalButton"
                  label={t('frontPage.highlight.optionalButton')}
                  validate={validators.maximumLength(80)}
                />
                <InputField
                  id="frontPageHighlightOptionalLink"
                  name="frontPageHighlightOptionalLink"
                  label={t('frontPage.highlight.optionalLink')}
                />
                <ImageUploaderField
                  name="frontPageHighlightImage"
                  label={t('frontPage.highlight.image')}
                  validate={validators.maximumFilesizeMb(2)}
                />
              </InputBox>
              <ImgBox>
                <Img src="/geneerinen_nosto.png" alt={t('frontPage.highlight.heading')} />
              </ImgBox>
            </FormBox>
            <FormBox title="Kommenttinostot">
              <PopularCommentsEditorField
                name="ignorePopularComments"
                comments={comments}
                fetchMoreComments={fetchMorePopularComments}
              />
            </FormBox>
            <FormBox title="Uutta sovelluksessa">
              <Wysiwyg name="newAppFeatures" />
            </FormBox>
          </FormBoxGroup>
          <SubmitButtonBox>
            <SubmitButton label={t('frontPage.save')} loading={submitting} disabled={pristine} onClick={handleSubmit} />
          </SubmitButtonBox>
        </FrontPageBaseForm>
      );
    },
    [t, polls, tips, holidayTagGroups, comments, fetchMorePopularComments],
  );

  return (
    <Form
      initialValues={initial}
      onSubmit={onSubmit}
      render={render}
      mutators={{
        ...arrayMutators,
      }}
    />
  );
};
