import { flow, makeAutoObservable } from 'mobx';
import { makeTagData, TagData } from '../types';
import { RemoteData } from '../types';
import { tagApi } from '../services/api';
import { fetchRemoteData } from './storeUtils';
import { RootStore } from './Stores';

export class TagStore {
  rootStore: RootStore;
  tags: RemoteData<TagData> = { state: 'NotFetched' };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  fetchTagsIfNotFetched = () => {
    if (this.tags.state === 'NotFetched') {
      this.fetchTags();
    }
  };

  refetchTags = () => {
    this.tags = { state: 'NotFetched' };
    this.fetchTags();
  };

  fetchTags: () => Promise<void> = flow(function* (this: TagStore) {
    yield fetchRemoteData(this, 'tags', async () => {
      const { groups, tags } = await tagApi.getTags();
      const tagData = makeTagData(tags, groups);
      this.rootStore.questionStore.questionTags = {
        state: 'Fetched',
        data: tagData.tagsByGroupKey.question,
      };
      return tagData;
    });
  }).bind(this);
}
