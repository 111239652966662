import React, { FC } from 'react';
import { Box, styled } from '@material-ui/core';
import { COLORS } from '../../styles';

const TabBox = styled(Box)({
  borderColor: COLORS.primaryDarker,
});

export const FormTabBox: FC = ({ children }) => {
  return <TabBox>{children}</TabBox>;
};
