import { makeAutoObservable, toJS } from 'mobx';
import { IToast } from '../types';
import Toast from './Toast';

const SUCCESS_TIMEOUT = 5000;
class ToastStore {
  toasts: IToast[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  showSuccess = (msg: string) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0;
    const newToasts = this.toasts.slice();
    const newToast = new Toast(msg, 'success', index);
    newToasts.push(newToast);
    this.toasts = newToasts;

    // Removes toast after SUCCESS_TIMEOUT
    setTimeout(() => {
      this.toasts = this.toasts.filter((t) => t.id !== newToast.id);
    }, SUCCESS_TIMEOUT);
  };

  showError = (msg: string) => {
    const index = this.toasts.length ? this.toasts.length - 1 : 0;
    if (this.toasts.length > 2) this.toasts.pop();
    this.toasts.push(new Toast(msg, 'error', index));
  };

  closeToast = (index: number) => {
    if (index > -1) {
      const newToasts = [...toJS(this.toasts)].splice(1, index);
      this.toasts = newToasts;
    }
  };

  closeToasts = () => {
    this.toasts = [];
  };
}

export default new ToastStore();
