import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';

import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { MembershipBenefitFormValue } from '../../types';
import { MembershipBenefitForm } from './MembershipBenefitForm';

export const MembershipBenefitScreen: FC = observer(() => {
  const {
    membershipBenefitStore: { membershipBenefit, fetchMembershipBenefit, updateMembershipBenefit },
  } = useStores();

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    if (id) fetchMembershipBenefit(id);
  }, [id, fetchMembershipBenefit]);

  const onSubmit = useCallback(
    async (values: MembershipBenefitFormValue): Promise<void> => {
      if (id) {
        return await updateMembershipBenefit(id, values);
      }
    },
    [id, updateMembershipBenefit],
  );

  const fetching = membershipBenefit.state === 'Fetching';

  const formData: [MembershipBenefitFormValue] | null = useMemo(() => {
    if (membershipBenefit.state !== 'Fetched') return null;

    const { image, ...mb } = membershipBenefit.data;

    const membershipBenefitFormValue: MembershipBenefitFormValue = {
      ...mb,
      image: { valueType: 'existing', name: membershipBenefit.data.image, url: membershipBenefit.data.imageUrl },
    };

    return [membershipBenefitFormValue];
  }, [membershipBenefit]);

  return (
    <Layout allowOverflow title={membershipBenefit.state === 'Fetched' ? membershipBenefit.data.text : ''}>
      {(() => {
        if (fetching) return <CircularProgress size="3rem" />;
        if (id === null || formData === null) return null;

        return (
          <>
            <MembershipBenefitForm initial={formData[0]} onSubmit={onSubmit} />
          </>
        );
      })()}
    </Layout>
  );
});
