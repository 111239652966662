import { flow, makeAutoObservable } from 'mobx';
import { pollApi } from '../services/api';
import { PollUpdateRequest } from '../services/api/poll';
import { mapRemoteData, RemoteData, RequestState, Poll, PollFormValue, PollListItem, PollId } from '../types';
import { collect } from '../utils';
import { executeRequest, fetchRemoteData } from './storeUtils';

export class PollStore {
  polls: RemoteData<PollListItem[]> = { state: 'NotFetched' };
  poll: RemoteData<Poll> = { state: 'NotFetched' };
  savingPoll: RequestState = 'Initial';
  deletingPoll: RequestState = 'Initial';

  constructor() {
    makeAutoObservable(this);
  }

  fetchPolls: () => Promise<void> = flow(function* (this: PollStore) {
    yield fetchRemoteData(this, 'polls', async () => await pollApi.getPolls());
  }).bind(this);

  fetchPoll: (id: PollId) => Promise<void> = flow(function* (this: PollStore, id: PollId) {
    yield fetchRemoteData(this, 'poll', () => pollApi.getPoll(id));
  }).bind(this);

  createPoll: (values: PollFormValue) => Promise<PollId | undefined> = flow(function* (
    this: PollStore,
    values: PollFormValue,
  ) {
    const request = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingPoll', () => pollApi.createPoll(request));
    return result;
  }).bind(this);

  updatePoll: (id: PollId, values: PollFormValue) => Promise<void> = flow(function* (
    this: PollStore,
    id: PollId,
    values: PollFormValue,
  ) {
    const request = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingPoll', () => pollApi.updatePoll(id, request));
    this.poll = { state: 'Fetched', data: result };
  }).bind(this);

  deletePoll: (id: PollId) => Promise<void> = flow(function* (this: PollStore, id: PollId) {
    yield executeRequest(this, 'deletingPoll', () => pollApi.deletePoll(id));
    this.polls = mapRemoteData(this.polls, (polls) => polls.filter((t) => t.id !== id));
  }).bind(this);
}

function formValueToRequest(values: PollFormValue): PollUpdateRequest {
  const request: PollUpdateRequest = {
    title: values.title.trim(),
    infoText: values.infoText && values.infoText?.trim().length > 0 ? values.infoText.trim() : null,
    link: values.link && values.link?.trim().length > 0 ? values.link.trim() : null,
    options: collect((value: string | null | undefined) => {
      if (value === null || value === undefined) {
        return undefined;
      }

      const trimmed = value.trim();
      if (trimmed === '') {
        return undefined;
      } else {
        return trimmed;
      }
    })(values.options),
  };

  return request;
}
