import LuxonUtils from '@date-io/luxon';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { Settings } from 'luxon';
import { observer, Provider } from 'mobx-react';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastProvider } from './components/Toast';
import { UnsavedWarningDialogProvider } from './components/UnsavedWarningDialogProvider';
import { ROUTE, ROUTES } from './constants';
import { initLang } from './i18n/index';
import { useStores } from './stores';
import stores from './stores/Stores';
import { MUI_THEME } from './styles/muiTheme';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
export const history = createBrowserHistory();
initLang('fi');
Settings.defaultLocale = 'fi';
const queryClient = new QueryClient();

const Routes = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  const routes = user ? ROUTES(user.role) : [ROUTE.login, ROUTE.tips];

  return (
    <Switch>
      {routes.map((route) => {
        const Screen = route.component;
        return (
          <Route key={route.href} path={route.href}>
            <Screen />
          </Route>
        );
      })}
      <Redirect to={ROUTE.tips.link()} />
    </Switch>
  );
});

const App = () => {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <MuiThemeProvider theme={MUI_THEME}>
            <Provider {...stores}>
              <MuiPickersUtilsProvider utils={LuxonUtils} locale="fi">
                <Routes />
                <UnsavedWarningDialogProvider />
                <ToastProvider />
                <CssBaseline />
              </MuiPickersUtilsProvider>
            </Provider>
          </MuiThemeProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
};

export default App;
