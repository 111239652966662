import React from 'react';
import {
  getDecorationStyles,
  holidayImages,
  HolidayTagGroupKeyEnum,
  holidayThemes,
} from '../../constants/holidayThemes';

const Logo = (props: { color: string }) => (
  <div style={{ justifyContent: 'center' }}>
    <svg width="54" height="60" viewBox="0 0 54 60" fill={props.color} xmlns="http://www.w3.org/2000/svg">
      <path d="M53.6849 15.1371C53.5041 6.74369 47.5678 0 40.2646 0C32.9615 0 27.0316 6.74369 26.8508 15.1371H26.8165C26.6348 6.74369 20.704 0 13.3981 0C6.09213 0 0.180817 6.74369 0 15.1371V52.3407C0.049145 56.5778 4.04844 60 8.95553 60C13.8608 60 17.8406 56.5621 17.8906 52.325H17.8999C17.95 56.5621 21.9307 60 26.8378 60C31.7458 60 35.7266 56.5621 35.7767 52.325H35.8138C35.8638 56.5621 39.8455 60 44.7526 60C49.6606 60 53.6349 56.5778 53.6849 52.3407V15.1371Z" />
    </svg>
  </div>
);

interface HolidayCardPreviewProps {
  activeGroup: HolidayTagGroupKeyEnum;
  title: string | null;
  text: string | null;
  button: string | null;
}

const HolidayCardPreview = (props: HolidayCardPreviewProps) => {
  const decorationStyles = getDecorationStyles(props.activeGroup);
  const holidayTheme = holidayThemes[props.activeGroup];
  const images = holidayImages[props.activeGroup];

  if (!holidayTheme) return <p>Teemalle {props.activeGroup} ei löytynyt värejä / kuvia</p>;

  return (
    <div>
      <div style={{ maxWidth: 400, position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <span style={{ maxWidth: 320, margin: '0 auto', zIndex: 99999999 }}>
            <h1 style={{ color: holidayTheme.titleColor, marginTop: '20px', marginBottom: '0px', fontSize: '1.125rem', fontWeight: 700 }}>
              {props.title}
            </h1>
            <h3 style={{ color: '#6D7780', marginBottom: '20px', marginTop: '10px', fontSize: '0.875rem' }}>{props.text}</h3>
          </span>
          <img
            alt="Decoration vertical"
            style={decorationStyles.verticalStyle}
            src={images.decorationVertical}
          />
          <div style={{ position: 'relative', maxWidth: 360 }}>
            <span style={{ zIndex: 10, display: 'flex', flexDirection: 'column', position: 'relative' }}>
              <img
                alt="Kategorian kuva"
                style={{ width: '360px', maxHeight: 200, objectFit: 'cover' }}
                src={images.frontPage}
              />
              <div
                style={{
                  background: holidayTheme.primaryBackground,
                  flexDirection: 'column',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginTop: -35 }}>
                  <Logo color={holidayTheme.secondaryBackground} />
                </span>
                <button
                  style={{
                    background: holidayTheme.secondaryBackground,
                    color: holidayTheme.secondaryTextColor,
                    padding: '10px 15px',
                    border: 'none',
                    borderRadius: '2222px',
                    width: '80%',
                    margin: '25px 15px',
                    fontWeight: 'bold',
                    fontSize: 16,
                    marginBottom: '30px',
                  }}
                >
                  {props.button}
                </button>
              </div>
              <img
                alt="Decoration horizontal"
                style={decorationStyles.horizontalStyle}
                src={images.decorationHorizontal}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HolidayCardPreview };
