import { CircularProgress } from '@material-ui/core';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { FrontPageSettingsFormValue, HolidayTagGroupKey, mapRemoteData } from '../../types';
import { FrontPageForm } from './FrontPageForm';

export const FrontPageScreen = observer(() => {
  const {
    settingsStore: { settings, popularComments, fetchSettings, fetchMorePopularComments, updateFrontPageSettings },
    pollStore: { polls, fetchPolls },
    tipStore: { tips, fetchTips },
    tagStore: { tags, fetchTags },
  } = useStores();

  useEffect(() => {
    fetchSettings();
    fetchPolls();
    fetchTips();
    fetchTags();
  }, [fetchSettings, fetchPolls, fetchTips, fetchTags]);

  const fetching =
    ['Initial', 'Fetching'].includes(polls.state) ||
    ['Initial', 'Fetching'].includes(settings.state) ||
    ['Initial', 'Fetching'].includes(tips.state) ||
    ['Initial', 'Fetching'].includes(tags.state);

  const filteredTips = useMemo(() => mapRemoteData(tips, (t) => t.filter((t) => t.season !== null)), [tips]);

  const holidayTagGroups = useMemo(() => {
    return tags.state === 'Fetched'
      ? tags.data.groups.filter((g) =>
          Object.values(HolidayTagGroupKey).includes((g.key as unknown) as HolidayTagGroupKey),
        )
      : [];
  }, [tags]);

  const initial = useMemo((): FrontPageSettingsFormValue | null => {
    if (
      polls.state !== 'Fetched' ||
      filteredTips.state !== 'Fetched' ||
      settings.state !== 'Fetched' ||
      tags.state !== 'Fetched'
    ) {
      return null;
    }

    const getNewAppFeaturesValue = (): EditorState => {
      if (!settings.data.newAppFeatures) return EditorState.createEmpty();
      const blocks = convertFromHTML(settings.data.newAppFeatures);
      return EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap));
    };

    return {
      ...settings.data,
      frontPagePoll: polls.data.find((p) => p.id === settings.data.frontPagePollId) ?? null,
      frontPageSeasonTip: filteredTips.data.find((t) => t.id === settings.data.frontPageSeasonTipId) ?? null,
      frontPageHighlightImage: settings.data.frontPageHighlightImage
        ? {
            valueType: 'existing',
            name: settings.data.frontPageHighlightImage,
            url: settings.data.frontPageHighlightImageUrl!,
          }
        : null,
      activeHolidayTagGroup: tags.data.groups.find((g) => g.id === settings.data.activeHolidayTagGroupId) ?? null,
      ignorePopularComments: [],
      newAppFeatures: getNewAppFeaturesValue(),
    };
  }, [settings, polls, filteredTips, tags]);

  const submit = useCallback(
    (values: FrontPageSettingsFormValue): Promise<void> => {
      return updateFrontPageSettings(values);
    },
    [updateFrontPageSettings],
  );

  return (
    <Layout allowOverflow>
      {
        // prettier-ignore
        fetching
          ? <CircularProgress size="3rem" />
          : polls.state === 'Fetched' && filteredTips.state === 'Fetched' && initial !== null
            ? <FrontPageForm
                initial={initial}
                polls={polls.data}
                tips={filteredTips.data}
                popularComments={popularComments}
                fetchMorePopularComments={fetchMorePopularComments}
                holidayTagGroups={holidayTagGroups}
                onSubmit={submit}
              />
            : null
      }
    </Layout>
  );
});
