import axios from 'axios';
import apiErrorHandler from './apiErrorHandler';
import { rootStore } from '../stores';

// export const API_URL = process.env.REACT_APP_API_URL;

const ApiUrls = {
  Local: `http://127.0.0.1:5000`,
  Stage: 'https://martat-api-staging.peanutsgroup.com',
  Production: 'https://martat-api.peanutsgroup.com'
}

export let ApiUrl = ApiUrls.Production
if (window.location.hostname.includes('staging.peanutsgroup.com')) {
  ApiUrl = ApiUrls.Stage
}
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) {
  ApiUrl = ApiUrls.Local
}

export const ApiClient = axios.create({
  baseURL: ApiUrl,
  timeout: 60000 * 5,
});

// Errors
ApiClient.interceptors.response.use((response) => response, apiErrorHandler);

ApiClient.interceptors.request.use((config) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const accessToken = rootStore.authStore.token;
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});
