import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { ScreenAction, PartnerElement, PartnerFormValue, PartnerFormValueElement } from '../../types';
import { collect, getFirstAvailableDateTime } from '../../utils';
import { PartnerForm } from './PartnerForm';
import { PartnerAnalyticsSummaryTable } from './PartnerAnalyticsSummaryTable';
import { ConfirmDialog } from '../../components/Dialog';

export const PartnerScreen: FC = observer(() => {
  const {
    partnerStore: { partner, fetchPartner, updatePartner, scheduledIntervals, fetchPartnersIfNotFetched, copyPartners },
  } = useStores();
  const { t } = useTranslation();

  const [copyVisible, setCopyVisible] = useState<boolean>(false);

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    fetchPartnersIfNotFetched();
  }, [fetchPartnersIfNotFetched]);

  useEffect(() => {
    if (id) fetchPartner(id);
  }, [id, fetchPartner]);

  const onSubmit = useCallback(
    async (values: PartnerFormValue): Promise<void> => {
      if (id) {
        return await updatePartner(id, values);
      }
    },
    [id, updatePartner],
  );

  const actions: ScreenAction[] = [
    {
      label: t('partner.copy.actionButton'),
      handler: () => setCopyVisible(true),
    },
  ];

  const fetching = partner.state === 'Fetching';

  const firstAvailableDateTime = getFirstAvailableDateTime(
    DateTime.local().set({ hour: 0, minute: 0, second: 0 }),
    scheduledIntervals,
  );

  const formData: PartnerFormValue | null = useMemo(() => {
    if (partner.state !== 'Fetched') return null;

    const partnerFormValue: PartnerFormValue = {
      submitType: 'draft',
      title: partner.data.title,
      shortDescription: partner.data.shortDescription,
      image: { valueType: 'existing', name: partner.data.image, url: partner.data.imageUrl },
      scheduledStartDateTime: partner.data.scheduledStartDateTime ?? firstAvailableDateTime,
      scheduledEndDateTime:
        partner.data.scheduledEndDateTime ?? firstAvailableDateTime.set({ hour: 23, minute: 59, second: 59 }),

      elements: collect((element: PartnerElement): PartnerFormValueElement | undefined => ({
        text: element.text,
        image: { valueType: 'existing', name: element.image, url: element.imageUrl },
      }))(partner.data.elements),

      longDescription: partner.data.longDescription,
      link: partner.data.link,
      linkText: partner.data.linkText,
    };

    return partnerFormValue;
  }, [partner, firstAvailableDateTime]);

  return (
    <Layout allowOverflow title={partner.state === 'Fetched' ? partner.data.title : ''} screenActions={actions}>
      {(() => {
        if (fetching) return <CircularProgress size="3rem" />;

        if (id === null || formData === null) return null;

        return (
          <>
            {partner.state === 'Fetched' && (
              <PartnerAnalyticsSummaryTable analyticsSummary={partner.data.analyticsSummary} />
            )}
            <PartnerForm
              initial={formData}
              scheduled={partner.state === 'Fetched' ? partner.data.scheduledStartDateTime !== null : false}
              onSubmit={onSubmit}
              invalidIntervals={scheduledIntervals}
            />
            <ConfirmDialog
              open={copyVisible}
              title={t('partner.copy.title')}
              message={formData.title}
              onAccept={() => {
                setCopyVisible(false);
                copyPartners({ ids: [id] });
              }}
              onCancel={() => setCopyVisible(false)}
            />
          </>
        );
      })()}
    </Layout>
  );
});
