import React from 'react';
import { ScreenAction } from '../../types';
import { Layout } from '../../components';
import { useTranslation } from 'react-i18next';
import { TagGroupForm } from './TagGroupForm';

const actions: ScreenAction[] = [];

const NewTagGroupScreen = () => {
  const { t } = useTranslation();

  return (
    <Layout backButton allowOverflow title={t('newTagGroup.title')} screenActions={actions}>
      <TagGroupForm create />
    </Layout>
  );
};

export { NewTagGroupScreen };
