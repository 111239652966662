import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ConfirmDialog, CopyToTipDialog } from '../../components/Dialog';
import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import {
  ScreenAction,
  Tag,
  TagData,
  TagGroup,
  TagGroupKey,
  TagId,
  TipElement,
  TipElementType,
  TipFormValue,
  TipFormValueElement,
} from '../../types';
import { collect } from '../../utils';
import { TipForm } from './TipForm';
import { sendPushNotification } from '../../services/api/tip';

export const TipScreen: FC = observer(() => {
  const {
    tipStore: { tip, fetchTip, updateTip, copyToTips },
    tagStore: { tags, fetchTagsIfNotFetched },
    toastStore: {showError,showSuccess}
  } = useStores();
  const { t } = useTranslation();

  const [copyVisible, setCopyVisible] = useState<boolean>(false);
  const [notificationVisible, setNotificationVisible] = useState<boolean>(false);

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  useEffect(() => {
    if (id) fetchTip(id);
  }, [id, fetchTip]);

  const onSubmit = useCallback(
    async (values: TipFormValue): Promise<void> => {
      if (id) {
        return await updateTip(id, values);
      }
    },
    [id, updateTip],
  );

  const actions: ScreenAction[] = [
    {
      label: t('massActions.copy.button'),
      handler: () => setCopyVisible(true),
    },
    {
      label: t('tip.form.sendNotification'),
      handler: () => setNotificationVisible(true),
    },
  ];

  const fetching = tip.state === 'Fetching' || tags.state === 'Fetching';

  const formData: [TipFormValue, TagData] | null = useMemo(() => {
    if (tip.state !== 'Fetched' || tags.state !== 'Fetched') return null;

    const findTagAndGroup = (id: TagId): { tag: Tag; group: TagGroup } => {
      const tag = tags.data.tags.find((t) => t.id === id)!;
      const group = tags.data.groups.find((t) => t.id === tag.groupId)!;
      return { tag, group };
    };

    const { tag, group: tagGroup } = findTagAndGroup(tip.data.primaryTagId);
    const secondaryTags = tip.data.secondaryTagIds.map(findTagAndGroup);

    const tipFormValue: TipFormValue = {
      submitType: 'draft',
      title: tip.data.title,
      image: { valueType: 'existing', name: tip.data.image, url: tip.data.imageUrl },
      tagGroup: tagGroup ?? null,
      primaryTag: tag ?? null,
      secondaryTags: {
        ingredient: secondaryTags.filter((t) => t.group.key === TagGroupKey.Ingredient).map((t) => t.tag),
        home: secondaryTags.filter((t) => t.group.key === TagGroupKey.Home).map((t) => t.tag),
        garden: secondaryTags.filter((t) => t.group.key === TagGroupKey.Garden).map((t) => t.tag),
        food: secondaryTags.filter((t) => t.group.key === TagGroupKey.Food).map((t) => t.tag),
        crafts: secondaryTags.filter((t) => t.group.key === TagGroupKey.Crafts).map((t) => t.tag),
      },
      scheduledDateTime:
        tip.data.scheduledDateTime ??
        DateTime.local().set({ minute: Math.floor(DateTime.local().minute / 5) * 5, second: 0, millisecond: 0 }),
      didYouKnow: {
        text: tip.data.didYouKnow?.text ?? '',
        value: tip.data.didYouKnow?.value ?? '',
      },
      season: {
        key: tip.data.season.key
          ? tip.data.season.key instanceof Array // for backwards compatibility
            ? tip.data.season.key
            : [tip.data.season.key]
          : [],
        title: tip.data.season.title,
        text: tip.data.season.text,
        image: tip.data.season.image
          ? { valueType: 'existing', name: tip.data.season.image, url: tip.data.season.imageUrl! }
          : null,
      },
      reminder: {
        enabled: tip.data.reminder.enabled ?? false,
        title: tip.data.reminder.title ?? '',
        text: tip.data.reminder.text ?? '',
        frequency: tip.data.reminder.frequency,
        defaultDateTime: tip.data.reminder.defaultDateTime,
      },
      elements: collect((element: TipElement): TipFormValueElement | undefined => {
        switch (element.type) {
          case TipElementType.Text:
            const blocks = convertFromHTML(element.content);
            return {
              type: element.type,
              content: EditorState.createWithContent(
                ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap),
              ),
            };
          case TipElementType.Image:
            return { type: element.type, image: { valueType: 'existing', name: element.image, url: element.imageUrl } };
          case TipElementType.Youtube:
            return { type: element.type, videoId: element.videoId };
          default:
            return undefined;
        }
      })(tip.data.elements),
      keywords: tip.data.keywords ?? '',
    };

    return [tipFormValue, tags.data];
  }, [tip, tags]);

  /**
   * Sends a push new push notification about the tip
   */
  const handleSendNotification = async () => {
    if (tip.state !== 'Fetched') return;

    try {
      await sendPushNotification(tip.data.id);
      showSuccess(t('tip.form.notificationDialog.success'));
    } catch {
      showError(t('tip.form.notificationDialog.error'));
    }
    setNotificationVisible(false);
  };

  return (
    <Layout allowOverflow title={tip.state === 'Fetched' ? tip.data.title : ''} screenActions={actions}>
      {(() => {
        if (fetching) {
          return <CircularProgress size="3rem" />;
        }

        if (id === null || formData === null) {
          return null;
        }

        return (
          <>
            <TipForm
              initial={formData[0]}
              tags={formData[1]}
              scheduled={tip.state === 'Fetched' ? tip.data.scheduledDateTime !== null : false}
              onSubmit={onSubmit}
            />
            <ConfirmDialog
              open={notificationVisible}
              title={t('tip.form.notificationDialog.title')}
              message={t('tip.form.notificationDialog.body')}
              acceptOption={t('tip.form.notificationDialog.accept')}
              cancelOption={t('common.cancel')}
              onAccept={() => handleSendNotification()}
              onCancel={() => setNotificationVisible(false)}
            />
            <CopyToTipDialog
              isVisible={copyVisible}
              onClose={() => setCopyVisible(false)}
              onAccept={(tagId, toSeasons) => {
                copyToTips({
                  ids: [id],
                  tagId,
                  seasonKeys: toSeasons,
                }).then(() => {
                  setCopyVisible(false);
                });
              }}
            />
          </>
        );
      })()}
    </Layout>
  );
});
