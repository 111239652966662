import React, { FC, useCallback, useState } from 'react';
import { Grid, Typography } from '@material-ui/core/';
import styled from '@material-ui/core/styles/styled';
import arrayMutators from 'final-form-arrays';
import { DateTime } from 'luxon';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { ComboBoxField, DateTimePickerField, InputField, SubmitButton } from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { NotificationFormValue, NotificationTarget } from '../../types';
import { validators } from '../../utils';

export interface NotificationFormProps {
  initial: NotificationFormValue;
  scheduled?: boolean;
  onSubmit: (tip: NotificationFormValue) => Promise<void>;
}

const TipBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
});

const ScheduleDatePickerField = styled(DateTimePickerField)({
  '& .MuiInputBase-input': {
    padding: '14px 13px',
    width: '160px',
  },
  '& .MuiButtonBase-root': {
    padding: '0',
  },
});

export const NotificationForm: FC<NotificationFormProps> = ({ initial, scheduled = false, onSubmit }) => {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState<{
    state: 'toDraft' | 'reschedule';
    resolve: () => void;
    value: NotificationFormValue;
  } | null>();

  const renderConfirmModal = () => {
    if (!confirm) {
      return null;
    }
    return (
      <ConfirmDialog
        open
        title={t(
          confirm.state === 'toDraft' ? 'notification.form.confirmToDraft' : 'notification.form.confirmReschedule',
        )}
        onAccept={async () => {
          await onSubmit(confirm.value);
          setConfirm(null);
          confirm.resolve();
        }}
        onCancel={() => {
          setConfirm(null);
          confirm.resolve();
        }}
      />
    );
  };

  const submit = (value: NotificationFormValue) =>
    new Promise((resolve) => {
      const scheduledDateTimeChanged = (): boolean =>
        value.scheduledDateTime !== null && initial.scheduledDateTime !== null
          ? !value.scheduledDateTime.equals(initial.scheduledDateTime)
          : !value.scheduledDateTime !== !initial.scheduledDateTime;

      if (scheduled && value.submitType === 'draft') {
        setConfirm({
          state: 'toDraft',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else if (scheduled && scheduledDateTimeChanged()) {
        setConfirm({
          state: 'reschedule',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else {
        resolve(onSubmit(value));
      }
    });

  const targetOptions = Object.values(NotificationTarget).map((it) => it);

  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, values, form }: FormRenderProps<NotificationFormValue>) => {
      const getSubmitButtonText = () => {
        // Handle first scenario when there is no scheduling actions by user
        if (values.scheduledDateTime === null || initial.scheduledDateTime?.equals(values.scheduledDateTime)) {
          return scheduled ? 'notification.form.update' : 'notification.form.publish';
        }

        // Check if really scheduled to the future, use publish as default if somebody scheduling to the past
        return values.scheduledDateTime > DateTime.local() ? 'notification.form.schedule' : 'notification.form.publish';
      };

      return (
        <TipBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="title"
                name="title"
                label={t('notification.form.title')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(80))}
              />
              <InputField
                id="message"
                name="message"
                label={t('notification.form.message')}
                rows={10}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(255))}
              />
              <InputField
                id="link"
                name="link"
                label={t('notification.form.url')}
                validate={validators.maximumLength(255)}
              />
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <ComboBoxField
                    required
                    name="target"
                    label={t('notification.target')}
                    placeholder={t('notification.form.target')}
                    options={targetOptions}
                    validate={validators.required}
                    getOptionSelected={(a, b) => a === b}
                    getOptionLabel={(g) => g ? t(`notification.filter.${g.toLowerCase()}`) : ''}
                  />
                </Grid>
              </Grid>
            </FormBox>
          </FormBoxGroup>

          <StateToolRow>
            <StateToolButtonBox>
              <SubmitButton
                label={t(scheduled ? 'notification.form.toDraft' : 'notification.form.saveAsDraft')}
                loading={submitting}
                onClick={() => {
                  form.mutators.setSubmitType('draft');
                  handleSubmit();
                }}
              />
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('notification.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <ScheduleDatePickerField
                  required
                  hideError
                  id="scheduledDateTime"
                  name="scheduledDateTime"
                  minutesStep={5}
                />
                <SubmitButton
                  label={t(getSubmitButtonText())}
                  loading={submitting}
                  onClick={() => {
                    form.mutators.setSubmitType('schedule');
                    handleSubmit();
                  }}
                />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('notification.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
        </TipBaseForm>
      );
    },
    [t, scheduled, initial, targetOptions],
  );

  return (
    <>
      <Form
        initialValues={initial}
        mutators={{
          setSubmitType: ([submitType], state, utils) => {
            utils.changeValue(state, 'submitType', () => submitType);
          },
          ...arrayMutators,
        }}
        onSubmit={submit}
        render={render}
      />
      {renderConfirmModal()}
    </>
  );
};
