import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { CircularProgress } from '@material-ui/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { ScreenAction, NotificationFormValue } from '../../types';
import { NotificationForm } from './NotificationForm';

export const NotificationScreen: FC = observer(() => {
  const {
    notificationStore: { notification, fetchNotification, updateNotification },
  } = useStores();

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    if (id) fetchNotification(id);
  }, [id, fetchNotification]);

  const onSubmit = useCallback(
    async (values: NotificationFormValue): Promise<void> => {
      if (id) {
        return await updateNotification(id, values);
      }
    },
    [id, updateNotification],
  );

  const actions: ScreenAction[] = [];

  const fetching = notification.state === 'Fetching';

  const formData: NotificationFormValue | null = useMemo(() => {
    if (notification.state !== 'Fetched') return null;

    const notificationFormValue: NotificationFormValue = {
      ...notification.data,
      submitType: 'draft',
      scheduledDateTime:
        notification.data.scheduledDateTime ??
        DateTime.local().set({ minute: Math.floor(DateTime.local().minute / 5) * 5, second: 0, millisecond: 0 }),
    };
    return notificationFormValue;
  }, [notification]);

  return (
    <Layout
      allowOverflow
      title={notification.state === 'Fetched' ? notification.data.title : ''}
      screenActions={actions}
    >
      {(() => {
        if (fetching) {
          return <CircularProgress size="3rem" />;
        }

        if (id === null || formData === null) {
          return null;
        }

        return (
          <NotificationForm
            initial={formData}
            scheduled={notification.state === 'Fetched' ? notification.data.scheduledDateTime !== null : false}
            onSubmit={onSubmit}
          />
        );
      })()}
    </Layout>
  );
});
