import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { CircularProgress, styled, Tab, Tabs } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { flow } from 'lodash/fp';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { ScreenAction, PartnerListItem, PartnerState, PartnerTableRow } from '../../types';
import { collect } from '../../utils';
import { ActivePartner } from './ActivePartner';

interface MaterialTableCell<T, K extends keyof T | undefined = undefined> {
  cell: {
    value: K extends keyof T ? T[K] : undefined;
  };
  row: {
    original: T;
  };
}

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const PartnersScreen: FC = observer(() => {
  const {
    partnerStore: {
      partnersByState,
      listingFilters: { state: stateFilter },
      setListingStateFilter,
      fetchPartners,
      deletePartner,
    },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [partnerToDelete, setPartnerToDelete] = useState<PartnerTableRow | null>(null);

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  const fetching = partnersByState.state === 'Fetching';

  const data: PartnerTableRow[] = useMemo(() => {
    return partnersByState.state !== 'Fetched'
      ? []
      : flow(
          collect((partner: PartnerListItem): PartnerTableRow | undefined => {
            return {
              id: partner.id,
              title: partner.title,
              scheduledStartDateTime: partner.scheduledStartDateTime,
              scheduledEndDateTime: partner.scheduledEndDateTime,
            };
          }),
        )(partnersByState.data[stateFilter]);
  }, [partnersByState, stateFilter]);

  const actions: ScreenAction[] = [
    {
      label: t('partner.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newPartner.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        Header: t('partner.title'),
      },
      ...(stateFilter !== PartnerState.Draft
        ? [
            {
              accessor: 'scheduledStartDateTime',
              Header: t('partner.list.scheduledStartDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<PartnerTableRow, 'scheduledStartDateTime'>) => {
                return value ? value.toFormat('d.L.yyyy') : '';
              },
              sortType: 'luxondate',
            },
          ]
        : []),
      ...(stateFilter !== PartnerState.Draft
        ? [
            {
              accessor: 'scheduledEndDateTime',
              Header: t('partner.list.scheduledEndDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<PartnerTableRow, 'scheduledEndDateTime'>) => {
                return value ? value.toFormat('d.L.yyyy') : '';
              },
              sortType: 'luxondate',
            },
          ]
        : []),
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<PartnerTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setPartnerToDelete(original)} />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.partner.link(original.id))} />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t, stateFilter],
  );

  const deleteModal =
    partnerToDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('partner.list.deleteMessage')}
        message={partnerToDelete.title}
        onAccept={() => {
          setPartnerToDelete(null);
          deletePartner(partnerToDelete.id);
        }}
        onCancel={() => setPartnerToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}

      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <Tabs value={stateFilter} onChange={(ev, value) => setListingStateFilter(value)}>
            <Tab label={t('partner.list.active')} value={PartnerState.Active} />
            <Tab label={t('partner.list.scheduled')} value={PartnerState.Scheduled} />
            <Tab label={t('partner.list.drafts')} value={PartnerState.Draft} />
            <Tab label={t('partner.list.finished')} value={PartnerState.Finished} />
          </Tabs>
          {stateFilter === PartnerState.Active ? (
            <div>
              <ActivePartner rowItem={data[0]} setToDelete={setPartnerToDelete} />
            </div>
          ) : (
            <TableContainer>
              <MaterialTable
                data={data}
                columns={columns}
                onPageChange={() => {}}
                onSortingChange={() => {}}
                pagination={null}
                initialSortBy={[
                  { id: stateFilter === PartnerState.Scheduled ? 'scheduledStartDateTime' : 'createdAt', desc: false },
                ]}
              />
            </TableContainer>
          )}
        </>
      )}
    </Layout>
  );
});
