import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { adApi } from '../services/api';
import { AdUpdateRequest } from '../services/api/ad';
import {
  mapRemoteData,
  RemoteData,
  RequestState,
  Ad,
  AdFormValue,
  AdId,
  AdRole,
  AdState,
  AdTableRow,
  ContentType,
} from '../types';
import { executeRequest, fetchRemoteData } from './storeUtils';

interface AdListingFilters {
  role: AdRole | null;
}

export class AdStore {
  ads: RemoteData<AdTableRow[]> = { state: 'NotFetched' };
  contentTypes: RemoteData<ContentType[]> = { state: 'NotFetched' };
  listingFilters: AdListingFilters = { role: null };
  stateFilter: AdState = AdState.Active;

  ad: RemoteData<Ad> = { state: 'NotFetched' };
  savingAd: RequestState = 'Initial';
  deletingAd: RequestState = 'Initial';

  constructor() {
    makeAutoObservable(this);
  }

  get adsByState(): RemoteData<Record<AdState, AdTableRow[]>> {
    return mapRemoteData(
      this.ads,
      (ads): Record<AdState, AdTableRow[]> =>
        ads.reduce(
          (acc: Record<AdState, AdTableRow[]>, ad: AdTableRow) => {
            const state: AdState = ad.active ? AdState.Active : AdState.Inactive;
            return { ...acc, [state]: [...acc[state], ad] };
          },
          { [AdState.Active]: [], [AdState.Inactive]: [] },
        ),
    );
  }

  fetchContentTypesIfNotFetched = () => {
    if (this.contentTypes.state === 'NotFetched') {
      this.fetchContentTypes();
    }
  };

  fetchContentTypes: () => Promise<void> = flow(function* (this: AdStore) {
    yield fetchRemoteData(this, 'contentTypes', async () => await adApi.getContentTypes());
  }).bind(this);

  setListingRoleFilter = (role: AdRole) => {
    this.listingFilters.role = role;
  };

  setStateFilter = (state: AdState) => (this.stateFilter = state);

  fetchAds: () => Promise<void> = flow(function* (this: AdStore) {
    yield fetchRemoteData(this, 'ads', async () => await adApi.getAds());
  }).bind(this);

  fetchAd: (id: AdId) => Promise<void> = flow(function* (this: AdStore, id: AdId) {
    yield fetchRemoteData(this, 'ad', () => adApi.getAd(id));
  }).bind(this);

  createAd: (values: AdFormValue) => Promise<AdId | undefined> = flow(function* (this: AdStore, values: AdFormValue) {
    const [request, image] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingAd', () => adApi.createAd(request, image!));
    return result;
  }).bind(this);

  updateAd: (id: AdId, values: AdFormValue) => Promise<void> = flow(function* (
    this: AdStore,
    id: AdId,
    values: AdFormValue,
  ) {
    const [request, image] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingAd', () => adApi.updateAd(id, request, image));
    this.ad = { state: 'Fetched', data: result };
  }).bind(this);

  deleteAd: (id: AdId) => Promise<void> = flow(function* (this: AdStore, id: AdId) {
    yield executeRequest(this, 'deletingAd', () => adApi.deleteAd(id));
    this.ads = mapRemoteData(this.ads, (ads) => ads.filter((t) => t.id !== id));
  }).bind(this);
}

function formValueToRequest({ image, ...values }: AdFormValue): [AdUpdateRequest, FileWithPreview | null] {
  const request = {
    text: values.text.trim(),
    link: values.link.trim(),
    tagText: values.tagText.trim(),
    active: values.active,
    contentTypeIds: values.contentTypeIds,
    role: values.role,
    image: image!.name,
  };
  const requestImage = image && image.valueType === 'file' ? image : null;
  return [request, requestImage];
}
