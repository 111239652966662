import Grid from '@material-ui/core/Grid/Grid';
import styled from '@material-ui/core/styles/styled';
import Typography from '@material-ui/core/Typography/Typography';
import { Add } from '@material-ui/icons';
import { EditorState } from 'draft-js';
import arrayMutators from 'final-form-arrays';
import { DateTime } from 'luxon';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import {
  Button,
  CheckBoxField,
  ComboBoxField,
  DateTimePickerField,
  ExistingImage,
  FileWithPreview,
  ImageUploaderField,
  InputField,
  MultiSelectComboBoxField,
  SubmitButton,
} from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { COLORS } from '../../styles';
import {
  Tag,
  TagData,
  TagGroupKey,
  TipElementType,
  TipFormValue,
  TipFormValueElement,
  tipReminderFrequencies,
  tipSeasonKeys,
} from '../../types';
import { validators } from '../../utils';
import { TipElementEditorField } from './TipElementEditor';

export interface TipFormProps {
  initial: TipFormValue;
  tags: TagData;
  scheduled?: boolean;
  onSubmit: (tip: TipFormValue) => Promise<void>;
}

const TipBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const HalfWidthField = styled('div')({
  maxWidth: '50%',
});

const DykImgBox = styled('div')({
  backgroundColor: COLORS.white,
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '50%',
});

const DykInputBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
});

const DykSeasonInputBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '50%',
  '& > *': {
    marginBottom: '25px',
  },
});

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
});

const ScheduleDatePickerField = styled(DateTimePickerField)({
  '& .MuiInputBase-input': {
    padding: '14px 13px',
    width: '160px',
    border: 'none',
  },
  '& .MuiButtonBase-root': {
    padding: '0',
  },
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
});

export const TipForm: FC<TipFormProps> = ({ initial, tags, scheduled = false, onSubmit }) => {
  const { t } = useTranslation();

  const [confirm, setConfirm] = useState<{
    state: 'toDraft' | 'reschedule';
    resolve: () => void;
    value: TipFormValue;
  } | null>();

  const renderConfirmModal = () => {
    if (!confirm) {
      return null;
    }
    return (
      <ConfirmDialog
        open
        title={t(confirm.state === 'toDraft' ? 'tip.form.confirmToDraft' : 'tip.form.confirmReschedule')}
        onAccept={async () => {
          await onSubmit(confirm.value);
          setConfirm(null);
          confirm.resolve();
        }}
        onCancel={() => {
          setConfirm(null);
          confirm.resolve();
        }}
      />
    );
  };

  const submit = (value: TipFormValue) =>
    new Promise((resolve) => {
      const scheduledDateTimeChanged = (): boolean =>
        value.scheduledDateTime !== null && initial.scheduledDateTime !== null
          ? !value.scheduledDateTime.equals(initial.scheduledDateTime)
          : !value.scheduledDateTime !== !initial.scheduledDateTime;

      if (scheduled && value.submitType === 'draft') {
        setConfirm({
          state: 'toDraft',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else if (scheduled && scheduledDateTimeChanged()) {
        setConfirm({
          state: 'reschedule',
          resolve: () => {
            resolve(undefined);
          },
          value,
        });
      } else {
        resolve(onSubmit(value));
      }
    });

  const allowedGroups = useMemo(() => {
    return tags.groups.filter((g) =>
      [TagGroupKey.Home, TagGroupKey.Garden, TagGroupKey.Food, TagGroupKey.Crafts].includes(g.key),
    );
  }, [tags]);

  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, values, form }: FormRenderProps<TipFormValue>) => {
      const secondaryTagsForGroup = (group: TagGroupKey) =>
        (tags.tagsByGroupKey[group] ?? []).filter((t) => t.id !== values.primaryTag?.id);

      const secondaryTagSelect = (group: TagGroupKey) => (
        <MultiSelectComboBoxField
          name={`secondaryTags.${group}`}
          label={tags.groupsByGroupKey[group].name}
          placeholder={''}
          options={secondaryTagsForGroup(group)}
          validate={validators.required}
          getOptionSelected={(a, b) => a?.id === b?.id}
          getOptionLabel={(g) => g.name}
        />
      );

      const getSubmitButtonText = () => {
        // Handle first scenario when there is no scheduling actions by user
        if (values.scheduledDateTime === null || initial.scheduledDateTime?.equals(values.scheduledDateTime)) {
          return scheduled ? 'tip.form.update' : 'tip.form.publish';
        }

        // Check if really scheduled to the future, use publish as default if somebody scheduling to the past
        return values.scheduledDateTime > DateTime.local() ? 'tip.form.schedule' : 'tip.form.publish';
      };

      return (
        <TipBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="title"
                name="title"
                label={t('tip.title')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(255))}
              />
              <ImageUploaderField
                name="image"
                label={t('tip.form.image')}
                required={true}
                validate={validators.and(validators.required, validators.maximumFilesizeMb(2))}
              />
            </FormBox>

            <FormBox title="Ensisijainen kategoriatieto" flexDirection="row" flexSpacing="40px">
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <ComboBoxField
                    required
                    name="tagGroup"
                    label={t('tip.mainCategory')}
                    placeholder={t('tip.form.selectMainCategory')}
                    options={allowedGroups}
                    validate={validators.required}
                    getOptionSelected={(a, b) => a?.id === b?.id}
                    getOptionLabel={(g) => g.name}
                    onChangeSideEffect={() => {
                      form.mutators.clearSelectedTag();
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ComboBoxField
                    required
                    disabled={values.tagGroup === null}
                    name="primaryTag"
                    label={t('tip.subCategory')}
                    placeholder={
                      values.tagGroup === null ? t('tip.form.selectFirstMainCategory') : t('tip.form.selectSubCategory')
                    }
                    options={values.tagGroup ? tags.tagsByGroupKey[values.tagGroup.key] : []}
                    validate={validators.required}
                    getOptionSelected={(a, b) => a?.id === b?.id}
                    getOptionLabel={(t) => t?.name ?? ''}
                    onChangeSideEffect={(tag) => {
                      form.mutators.filterSecondaryTags(tag);
                    }}
                  />
                </Grid>
              </Grid>
            </FormBox>

            <FormBox title="Toissijainen kategoriatieto">
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  {secondaryTagSelect(TagGroupKey.Ingredient)}
                </Grid>
                <Grid item xs={6}>
                  {secondaryTagSelect(TagGroupKey.Home)}
                </Grid>
                <Grid item xs={6}>
                  {secondaryTagSelect(TagGroupKey.Garden)}
                </Grid>
                <Grid item xs={6}>
                  {secondaryTagSelect(TagGroupKey.Food)}
                </Grid>
                <Grid item xs={6}>
                  {secondaryTagSelect(TagGroupKey.Crafts)}
                </Grid>
              </Grid>
            </FormBox>

            <FormBox title={t('tip.form.seasonTitle')}>
              <MultiSelectComboBoxField
                name={'season.key'}
                label={t('tip.form.season')}
                placeholder={''}
                options={tipSeasonKeys}
                getOptionLabel={(v) => t(`tip.form.seasonLabel.${v}`)}
              />
            </FormBox>

            <FieldArray name="elements">
              {({ fields }) =>
                fields.map((name, ix) => (
                  <FormBox key={name} title={ix === 0 ? t('tip.form.content') : undefined}>
                    <TipElementEditorField
                      key={name}
                      name={name}
                      onRemove={fields.length === 1 ? undefined : () => form.mutators.removeElement(ix)}
                      onMove={(direction) => form.mutators.onMove(ix, direction)}
                    />
                  </FormBox>
                ))
              }
            </FieldArray>
            <Button label={t('tip.form.addBlock')} iconRight={Add} onClick={() => form.mutators.addElement()} />

            <FormBox title={t('tip.form.didYouKnow')} flexDirection="row" flexSpacing="40px">
              <DykInputBox>
                <InputField
                  id="didYouKnow.text"
                  name="didYouKnow.text"
                  label={t('tip.form.didYouKnowText')}
                  rows={5}
                  validate={validators.maximumLength(100)}
                />
                <InputField
                  id="didYouKnow.value"
                  name="didYouKnow.value"
                  label={t('tip.form.didYouKnowValue')}
                  validate={validators.maximumLength(31)}
                />
              </DykInputBox>
              <DykImgBox>
                <img src="/tiesitko.png" alt={t('tip.form.didYouKnow')} />
              </DykImgBox>
            </FormBox>

            <FormBox title={t('tip.form.seasonDidYouKnow')} flexDirection="row" flexSpacing="40px">
              <DykSeasonInputBox>
                <InputField
                  id="season.title"
                  name="season.title"
                  label={t('tip.form.seasonDidYouKnowTitle')}
                  validate={validators.maximumLength(80)}
                />
                <InputField
                  id="season.text"
                  name="season.text"
                  label={t('tip.form.seasonDidYouKnowText')}
                  validate={validators.maximumLength(80)}
                />
                <ImageUploaderField
                  name="season.image"
                  label={t('tip.form.seasonDidYouKnowImage')}
                  validate={validators.maximumFilesizeMb(2)}
                />
              </DykSeasonInputBox>
              <DykImgBox>
                <img src="/sesonki.png" alt={t('tip.form.didYouKnow')} />
              </DykImgBox>
            </FormBox>

            <FormBox title={t('tip.form.reminder')} flexDirection="column" flexSpacing="40px">
              <CheckBoxField
                id="reminder.enabled"
                name="reminder.enabled"
                label={t('tip.form.reminderEnabled')}
                onChangeSideEffect={(value) => {
                  if (!value) {
                    form.mutators.resetReminderFields();
                  }
                }}
              />
              {values.reminder.enabled && (
                <>
                  <InputField
                    id="reminder.title"
                    name="reminder.title"
                    label={t('tip.form.reminderTitle')}
                    validate={validators.and(validators.required, validators.maximumLength(255))}
                  />
                  <InputField
                    id="reminder.text"
                    name="reminder.text"
                    label={t('tip.form.reminderText')}
                    validate={validators.maximumLength(1023)}
                  />
                  <HalfWidthField>
                    <ComboBoxField
                      required
                      name="reminder.frequency"
                      label={t('tip.form.reminderFrequency')}
                      options={tipReminderFrequencies}
                      validate={validators.required}
                      disableClearable={true}
                      getOptionSelected={(a, b) => a === b}
                      getOptionLabel={(v) => (v ? t(`tip.reminderFrequency.${v}`) : '')}
                    />
                  </HalfWidthField>
                  <HalfWidthField>
                    <DateTimePickerField
                      required
                      id="reminder.defaultDateTime"
                      name="reminder.defaultDateTime"
                      label={t('tip.form.reminderDateTime')}
                      validate={validators.and(validators.required, validators.dateTime)}
                    />
                  </HalfWidthField>
                </>
              )}
            </FormBox>

            <FormBox title={t('tip.form.keywords')}>
              <InputField id="keywords" name="keywords" />
            </FormBox>
          </FormBoxGroup>

          <StateToolRow>
            <StateToolButtonBox>
              <SubmitButton
                label={t(scheduled ? 'tip.form.toDraft' : 'tip.form.saveAsDraft')}
                loading={submitting}
                onClick={() => {
                  form.mutators.setSubmitType('draft');
                  handleSubmit();
                }}
              />
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('tip.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <ScheduleDatePickerField
                  required
                  hideError
                  id="scheduledDateTime"
                  name="scheduledDateTime"
                  minutesStep={5}
                />
                <SubmitButton
                  label={t(getSubmitButtonText())}
                  loading={submitting}
                  onClick={() => {
                    form.mutators.setSubmitType('schedule');
                    handleSubmit();
                  }}
                />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('tip.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
        </TipBaseForm>
      );
    },
    [tags, allowedGroups, t, scheduled, initial],
  );

  return (
    <>
      <Form
        initialValues={initial}
        mutators={{
          setSubmitType: ([submitType], state, utils) => {
            utils.changeValue(state, 'submitType', () => submitType);
          },
          resetReminderFields: (args, state, utils) => {
            utils.changeValue(state, 'reminder.title', () => initial.reminder.title);
            utils.changeValue(state, 'reminder.text', () => initial.reminder.text);
            utils.changeValue(state, 'reminder.frequency', () => initial.reminder.frequency);
            utils.changeValue(state, 'reminder.defaultDateTime', () => initial.reminder.defaultDateTime);
          },
          clearSelectedTag: (args, state, utils) => {
            utils.changeValue(state, 'primaryTag', () => null);
          },
          filterSecondaryTags: ([tag], state, utils) => {
            const filterGroup = (key: TagGroupKey) =>
              utils.changeValue(state, `secondaryTags.${key}`, () => {
                const secondaryTags = (state.formState.values as TipFormValue).secondaryTags as { [k: string]: Tag[] };
                return secondaryTags[key]?.filter((v) => v.id !== tag.id);
              });

            [TagGroupKey.Ingredient, TagGroupKey.Home, TagGroupKey.Garden].forEach(filterGroup);
          },
          onMove: ([ix, direction], state, utils) => {
            const elements: TipFormValueElement[] = (state.formState.values as any).elements.concat();
            const nextIx = ix + (direction === 'up' ? -1 : 1);
            if (nextIx < 0 || nextIx >= elements.length) {
              return;
            }

            // Update object URL when image is moved. Component frees old url on unmount
            const process = (element: TipFormValueElement) => {
              if (element.type === TipElementType.Image) {
                const image: FileWithPreview | ExistingImage | null =
                  element.image?.valueType === 'file'
                    ? {
                        valueType: 'file',
                        name: element.image.name,
                        file: element.image.file,
                        preview: URL.createObjectURL(element.image.file),
                      }
                    : element.image;

                return {
                  type: element.type,
                  image,
                };
              } else {
                return element;
              }
            };

            const value = process(elements[ix]);
            const other = process(elements[nextIx]);

            elements[ix] = other;
            elements[nextIx] = value;
            utils.changeValue(state, 'elements', () => elements);
          },
          addElement: (args, state, utils) =>
            utils.changeValue(state, 'elements', () => [
              ...(state.formState.values as any).elements,
              { type: TipElementType.Text, content: EditorState.createEmpty() },
            ]),
          removeElement: ([ix], state, utils) =>
            utils.changeValue(state, 'elements', () => {
              const value = (state.formState.values as any).elements;
              return [...value.slice(0, ix), ...value.slice(ix + 1)];
            }),
          ...arrayMutators,
        }}
        onSubmit={submit}
        render={render}
      />
      {renderConfirmModal()}
    </>
  );
};
