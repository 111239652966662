import React, { FC, useCallback } from 'react';
import { styled, Typography } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { Form, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  CheckBoxField,
  ComboBoxField,
  ImageUploaderField,
  InputField,
  SubmitButton,
} from '../../components/FormControls';
import { FormBox, FormBoxGroup } from '../../components/FormLayout';
import { BaseForm } from '../../components/FormLayout/BaseForm';
import { MembershipBenefitFormValue, MembershipBenefitRole } from '../../types';
import { validators } from '../../utils';
import { MembershipBenefitCardPreview } from './MembershipBenefitPreview';

export interface MembershipBenefitFormProps {
  initial: MembershipBenefitFormValue;
  onSubmit: (c: MembershipBenefitFormValue) => Promise<void>;
}

const TipBaseForm = styled(BaseForm)(({ theme }) => ({
  width: '70%',
  [`${theme.breakpoints.down('lg')}`]: {
    width: '85%',
  },
  [`${theme.breakpoints.down('md')}`]: {
    width: '100%',
  },
  marginBottom: '40px',
}));

const StateToolRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '80px',
});

const StateToolButtonBox = styled('div')({});

const ScheduleButtonBox = styled('div')({
  display: 'flex',
});

export const MembershipBenefitForm: FC<MembershipBenefitFormProps> = ({ initial, onSubmit }) => {
  const { t } = useTranslation();

  const roleOptions = Object.values(MembershipBenefitRole).map((it) => it);

  const submit = (value: MembershipBenefitFormValue) => new Promise((resolve) => resolve(onSubmit(value)));
  const render = useCallback(
    ({ handleSubmit, submitting, submitFailed, invalid, values }: FormRenderProps<MembershipBenefitFormValue>) => {
      return (
        <TipBaseForm onSubmit={handleSubmit}>
          <FormBoxGroup>
            <FormBox flexDirection="column" flexSpacing="40px">
              <InputField
                id="text"
                name="text"
                label={t('membershipBenefit.text')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(60))}
              />
              <InputField
                id="buttonText"
                name="buttonText"
                label={t('membershipBenefit.buttonText')}
                required={true}
                validate={validators.and(validators.required, validators.maximumLength(30))}
              />
              <InputField
                id="link"
                name="link"
                label={t('membershipBenefit.link')}
                required={true}
                validate={validators.and(validators.required, validators.isLink, validators.maximumLength(1024))}
              />
              <ImageUploaderField
                name="image"
                label={t('membershipBenefit.image')}
                required={true}
                validate={validators.and(validators.required, validators.maximumFilesizeMb(2))}
              />
            </FormBox>
            <FormBox flexDirection="column" flexSpacing="40px">
              <ComboBoxField
                required
                name="role"
                label={t('membershipBenefit.role')}
                options={roleOptions}
                validate={validators.required}
                getOptionSelected={(a, b) => a === b}
                getOptionLabel={(g) => (g ? t(`notification.filter.${g.toLowerCase()}`) : '')}
              />
              <CheckBoxField id="active" name="active" label={t('membershipBenefit.status')} />
            </FormBox>
          </FormBoxGroup>
          <StateToolRow>
            <StateToolButtonBox>
              <ScheduleButtonBox>
                <SubmitButton label={t('membershipBenefit.save')} loading={submitting} onClick={handleSubmit} />
              </ScheduleButtonBox>
              {submitFailed && invalid && (
                <Typography variant="body1" color="error">
                  {t('tip.form.errors')}
                </Typography>
              )}
            </StateToolButtonBox>
          </StateToolRow>
          <MembershipBenefitCardPreview {...values} />
        </TipBaseForm>
      );
    },
    [t, roleOptions],
  );

  return (
    <>
      <Form initialValues={initial} mutators={{ ...arrayMutators }} onSubmit={submit} render={render} />
    </>
  );
};
