import { values } from 'lodash/fp';

export interface IToast {
  msg: string;
  type: 'error' | 'success' | 'info';
  id: string;
}

export interface NotFetched {
  state: 'NotFetched';
}

export interface Fetching {
  state: 'Fetching';
}

export interface Fetched<Data> {
  state: 'Fetched';
  data: Data;
}

export interface ErrorFetching {
  state: 'ErrorFetching';
  error?: string;
}

export type RemoteData<Data> = NotFetched | Fetching | Fetched<Data> | ErrorFetching;

export type RequestState = 'Initial' | 'Executing' | 'Complete' | 'Error';

export type RemotePaginatedData<Data> =
  | {
      state: 'Initial';
    }
  | {
      state: 'Fetching' | 'ErrorFetching' | 'Fetched';
      fetched: number;
      data: Data[];
    };

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export const mapRemoteData = <T1, T2>(value: RemoteData<T1>, fn: (data: T1) => T2) =>
  value.state === 'Fetched' ? { state: value.state, data: fn(value.data) } : value;

export enum ArticleStateKey {
  Publish = 'publish',
  Draft = 'draft',
}

export const articleStateKeys = values(ArticleStateKey);

export interface MaterialTableCell<T, K extends keyof T | undefined = undefined> {
  cell: {
    value: K extends keyof T ? T[K] : undefined;
  };
  row: {
    original: T;
  };
}
