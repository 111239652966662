import { AdId, NotificationId, PartnerId, TagId, TipId } from '../../types';

export const PATHS = {
  auth: {
    login: `/admin/auth/login`,
    logout: `/admin/auth/logout`,
  },
  tags: {
    root: `/admin/tags`,
    single: (id: TagId) => `/admin/tags/${id}`,
    getSingle: (id: TagId) => `/admin/tags/question/${id}`,
    info: (id: TagId) => `/admin/tags/info/${id}`,
  },
  tips: {
    root: `/admin/tips`,
    single: (id: TipId) => `/admin/tips/${id}`,
    massUpdate: `/admin/tips/massUpdate`,
    copy: `/admin/tips/holidayToNormal`,
    pushNotification: (id: TipId) => `/admin/tips/notification/${id}`,
  },
  polls: {
    root: `/admin/polls`,
    single: (id: TipId) => `/admin/polls/${id}`,
  },
  settings: {
    root: `/admin/settings`,
    frontPage: `/admin/settings/frontPage`,
    popularComments: `/admin/settings/popularComments`,
  },
  notifications: {
    root: `/admin/notifications`,
    single: (id: NotificationId) => `/admin/notifications/${id}`,
  },
  holidayTips: {
    root: `/admin/holidayTips`,
    single: (id: TipId) => `/admin/holidayTips/${id}`,
  },
  partner: {
    root: `/admin/partner-content`,
    single: (id: PartnerId) => `/admin/partner-content/${id}`,
    copy: `/admin/partner-content/copy`,
  },
  recipes: {
    root: `/admin/recipes`,
  },
  firebase: {
    dynamicLink: `/admin/firebase/dynamicLink`,
  },
  questions: {
    root: `/admin/questions`,
  },
  ads: {
    root: `/admin/ads`,
    single: (id: AdId) => `/admin/ads/${id}`,
    contentTypes: `/contentTypes`,
  },
  membershipBenefits: {
    root: `/admin/membershipBenefits`,
    single: (id: AdId) => `/admin/membershipBenefits/${id}`,
  },
};
