import { styled } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FC } from 'react';
import { Field } from 'react-final-form';
import { IconButton } from '../../components/FormControls/IconButton';
import Spinner from '../../components/MaterialTable/MaterialTableSpinner';
import { COLORS } from '../../styles';
import { RemotePaginatedData } from '../../types';
import { CommentId, PopularComment } from '../../types/Settings';

export interface PopularCommentFieldProps {
  message: string;
  imageUrl: string;
  onDelete: () => void;
}

const CommentText = styled('div')({
  fontSize: 22,
  width: '80%',
  marginLeft: '20px',
});

const PopularCommentFieldBase = styled('div')({
  display: 'flex',
  margin: '20px',
  padding: '20px',
  backgroundColor: COLORS.greyLightest,
  borderRadius: '4px',
});

const PopularCommentImageBlock = styled('div')({
  width: '20%',
  margin: '20px',
});

const PopularCommentImage = styled('img')({
  width: '100%',
  height: 'auto',
});

export const PopularCommentField: FC<PopularCommentFieldProps> = ({ message, imageUrl, onDelete }) => (
  <PopularCommentFieldBase>
    <IconButton icon={Delete} size={32} onClick={() => onDelete()} />
    <CommentText>{message}</CommentText>
    <PopularCommentImageBlock>
      <PopularCommentImage src={imageUrl} alt="kuva" />
    </PopularCommentImageBlock>
  </PopularCommentFieldBase>
);

interface PopularCommentsEditorProps {
  readonly comments: RemotePaginatedData<PopularComment>;
  readonly value: CommentId[];
  readonly fetchMoreComments: () => Promise<void>;
  readonly onChange: (id: CommentId[]) => void;
}

export const PopularCommentsEditor: FC<PopularCommentsEditorProps> = ({
  comments,
  value,
  fetchMoreComments,
  onChange,
}) => {
  if (comments.state === 'Initial') {
    return <Spinner />;
  }

  const filteredComments = comments.data.filter((v) => !value.includes(v.commentId));
  const visibleComments = filteredComments.slice(0, 5);
  const showingLastLoadedComment = filteredComments.length - 1 < 5;

  return (
    <>
      {visibleComments.map(({ commentId, imageUrl, message }) =>
        // prettier-ignore
        <PopularCommentField 
          key={commentId}
          message={message}
          imageUrl={imageUrl}
          onDelete={() => {
            if(showingLastLoadedComment) {
              fetchMoreComments()
            }
            
            onChange([...value, commentId])
          }}
        />,
      )}
      {comments.state === 'Fetching' && <Spinner />}
    </>
  );
};

interface PopularCommentsEditorFieldProps {
  readonly name: string;
  readonly comments: RemotePaginatedData<PopularComment>;
  readonly fetchMoreComments: () => Promise<void>;
}

export const PopularCommentsEditorField: FC<PopularCommentsEditorFieldProps> = ({
  name,
  comments,
  fetchMoreComments,
}) => {
  return (
    <Field name={name} required={false}>
      {({ input, meta }) =>
        // prettier-ignore
        <PopularCommentsEditor 
          comments={comments}
          value={input.value}
          fetchMoreComments={fetchMoreComments}
          onChange={input.onChange} 
        />
      }
    </Field>
  );
};
