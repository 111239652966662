import { DateTime } from 'luxon';
import { Tag, TagGroup } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export interface GetTagsResponse {
  groups: TagGroup[];
  tags: Tag[];
}

export const getTags = async (): Promise<GetTagsResponse> => {
  const result = await ApiClient.get<GetTagsResponse>(PATHS.tags.root);
  result.data.tags.forEach((data: any) => {
    data.scheduledDateTime = data.scheduledDateTime ? DateTime.fromISO(data.scheduledDateTime) : null;
  });
  return result.data;
};
