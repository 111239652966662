import { makeStyles, styled, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField/TextField';
import { Close, KeyboardArrowDown } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldValidator } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { DARK_GREY, GREY, PRIMARY } from '../../styles';

const useStyles = makeStyles({
  inputRoot: {
    border: 'none',
    width: '100%',
    paddingRight: '8px !important',
  },
  narrow: {
    padding: '0 0 0 8px !important',
  },
  endAdornment: {
    top: 'calc(50% - 16px)',
    right: 0,
    position: 'relative',
  },
  altInputRoot: {
    backgroundColor: PRIMARY,
    color: 'white',
  },
  textField: {
    borderColor: GREY,
  },
  altTextField: {
    borderColor: PRIMARY,
  },
});

export interface MultiSelectComboBoxProps<T> {
  label?: string;
  placeholder?: string;
  value: T[];
  options: T[];
  required?: boolean;
  disabled?: boolean;
  error?: string;
  disableClearable?: boolean;
  getOptionSelected?: (a: T, b: T) => boolean;
  getOptionLabel: (t: T) => string;
  onChange: (value: T[]) => void;
  onChangeSideEffect?: (value: T[]) => void;
  onFocus?: (ev: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (ev: React.FocusEvent<HTMLElement>) => void;
  altStyle?: boolean;
  narrow?: boolean;
}

const MultiSelectComboBoxWrapper = styled('span')({
  width: '100%',
});

export const MultiSelectComboBox = <T,>({
  label,
  placeholder,
  value,
  options,
  required,
  disabled,
  error,
  disableClearable,
  getOptionSelected,
  getOptionLabel,
  onChange,
  onChangeSideEffect,
  onFocus,
  onBlur,
  altStyle,
  narrow,
}: MultiSelectComboBoxProps<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const iconStyle = {
    style: { opacity: disabled ? 0.5 : 1, fontSize: 22 },
    htmlColor: altStyle ? 'white' : DARK_GREY,
  };

  return (
    <MultiSelectComboBoxWrapper>
      {label ? <Typography variant="h3">{`${label}${required ? '*' : ''}`}</Typography> : null}
      <Autocomplete
        classes={{
          endAdornment: classes.endAdornment,
          inputRoot: `${classes.inputRoot}
          ${altStyle ? classes.altInputRoot : undefined}
          ${narrow ? classes.narrow : undefined}`,
        }}
        fullWidth
        multiple
        value={value}
        options={options}
        disabled={disabled}
        disableClearable={disableClearable}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(ev, value) => {
          if (onChangeSideEffect) {
            onChangeSideEffect(value as T[]);
          }
          onChange(value as T[]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={error !== undefined}
            helperText={error ? t(error) : undefined}
            placeholder={placeholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: { notchedOutline: altStyle ? classes.altTextField : classes.textField },
            }}
          />
        )}
        popupIcon={<KeyboardArrowDown {...iconStyle} />}
        closeIcon={<Close {...iconStyle} />}
      />
    </MultiSelectComboBoxWrapper>
  );
};

export interface MultiSelectComboBoxFieldProps<T> {
  name: string;
  label: string;
  placeholder?: string;
  options: T[];
  required?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  getOptionSelected?: (a: T, b: T) => boolean;
  getOptionLabel: (t: T) => string;
  onChangeSideEffect?: (value: T[]) => void;
  validate?: FieldValidator<any>;
}

export const MultiSelectComboBoxField = <T,>({
  name,
  label,
  placeholder,
  options,
  required,
  disabled,
  disableClearable,
  getOptionSelected,
  getOptionLabel,
  onChangeSideEffect,
  validate,
}: MultiSelectComboBoxFieldProps<T>) => {
  return (
    <Field name={name} required={required} validate={validate}>
      {({ input, meta }) => {
        return (
          <MultiSelectComboBox
            {...input}
            label={label}
            placeholder={placeholder}
            options={options}
            required={required}
            disabled={disabled}
            disableClearable={disableClearable}
            error={meta.touched ? meta.error : undefined}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            onChangeSideEffect={onChangeSideEffect}
          />
        );
      }}
    </Field>
  );
};
