import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import { RootStore } from '../../stores/Stores';
import { ConfirmDialog } from '../Dialog/ConfirmDialog';

export const UnsavedWarningDialogProvider: FC<{}> = observer(() => {
  const {
    unsavedWarningStore: { showWarning, accept, cancel }
  } = useStores() as RootStore;

  const { t } = useTranslation();

  return <ConfirmDialog
    open={showWarning}
    title={t('unsavedWarning.title')}
    message={t('unsavedWarning.message')}
    acceptOption={t('unsavedWarning.accept')}
    cancelOption={t('unsavedWarning.cancel')}
    onAccept={accept}
    onCancel={cancel}
  />
});