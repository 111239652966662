import { makeAutoObservable } from 'mobx';
import { history } from '../App';

export default class UnsavedWarningStore {
  warningEnabled: boolean = false;
  onAccept: (() => void) | null = null;
  unregisterHistoryBlock: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  get showWarning() {
    return this.onAccept !== null;
  }

  setUnsavedWarningState = (warningEnabled: boolean): void => {
    this.warningEnabled = warningEnabled;
    if (warningEnabled) {
      this.registerEvents();
    } else {
      this.unregisterEvents();
    }
  };

  promptUnsaved = (onAccept: () => void): void => {
    if (this.warningEnabled) this.onAccept = onAccept;
    else onAccept();
  };

  skipCheck = (next: () => void): void => {
    this.unregisterEvents();
    next();
  };

  accept = (): void => {
    this.unregisterEvents();
    if (this.onAccept) this.onAccept();
    this.onAccept = null;
    this.warningEnabled = false;
  };

  cancel = (): void => {
    this.onAccept = null;
  };

  private registerEvents = () => {
    this.unregisterHistoryBlock = history.block((location) => {
      this.promptUnsaved(() => history.push(location));
      return false;
    });
    window.addEventListener('beforeunload', unloadWarningEvent);
  };

  private unregisterEvents = () => {
    if (this.unregisterHistoryBlock) {
      this.unregisterHistoryBlock();
      this.unregisterHistoryBlock = null;
    }
    window.removeEventListener('beforeunload', unloadWarningEvent);
  };
}

const unloadWarningEvent = (ev: any) => {
  ev.preventDefault();
  ev.returnValue = '';
  return '';
};
