import React, { FC } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

export type ButtonProps = MuiButtonProps & {
  label?: string;
  iconLeft?: SvgIconComponent;
  iconRight?: SvgIconComponent;
  onClick?: () => void;
};

const BaseButton = styled(MuiButton)({
  border: 'none',
  '& .iconRight': {
    fontSize: '1rem',
    marginLeft: '8px',
  },
  '& .iconLeft': {
    fontSize: '1rem',
    marginRight: '8px',
  },
});

export const Button: FC<ButtonProps> = ({ label, iconLeft: IconLeft, iconRight: IconRight, onClick, ...props }) => {
  return (
    <BaseButton variant="outlined" onClick={onClick} {...props}>
      {IconLeft ? <IconLeft className="iconLeft" /> : null}
      {label ?? null}
      {IconRight ? <IconRight className="iconRight" /> : null}
    </BaseButton>
  );
};
