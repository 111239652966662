import React, { FC } from 'react';
import { styled, TextField as MuiTextField, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Delete } from '@material-ui/icons';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, ImageUploader } from '../../components/FormControls';
import { PartnerFormValueElement } from '../../types';
import { COLORS } from '../../styles';

export interface PartnerElementEditorProps {
  value: PartnerFormValueElement;
  error?: string;
  onChange: (value: PartnerFormValueElement) => void;
  onRemove?: () => void;
  onMove: (direction: 'up' | 'down') => void;
  onFocus?: (ev: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (ev: React.FocusEvent<HTMLElement>) => void;
}

const ButtonsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  '& > *': {
    borderWidth: 0,
  },
  '& > *:not(:last-child)': {
    marginRight: '5px',
  },
  '& > *:last-child': {
    marginLeft: 'auto',
  },
});

const TextFieldLabel = styled(Typography)({
  marginTop: 10,
});

const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey,
  },
});

export const PartnerElementEditor: FC<PartnerElementEditorProps> = ({
  value,
  error,
  onChange,
  onRemove,
  onMove,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ButtonsContainer>
        <IconButton size={50} icon={ArrowUpward} onClick={() => onMove('up')} />
        <IconButton size={50} icon={ArrowDownward} onClick={() => onMove('down')} />
        {onRemove ? <Button label={t('partner.element.remove')} iconRight={Delete} onClick={onRemove} /> : <span />}
      </ButtonsContainer>
      <>
        <ImageUploader
          label={t('partner.element.image')}
          error={error}
          value={value.image}
          onChange={(image) => onChange({ image, text: value.text })}
        />

        <TextFieldLabel variant="h3">{t('partner.element.text')}</TextFieldLabel>
        <TextField
          fullWidth
          variant="outlined"
          value={value.text}
          error={error !== undefined}
          helperText={error ? t(error) : undefined}
          onChange={(ev) => onChange({ image: value.image, text: ev.target.value })}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </>
    </div>
  );
};

export interface PartnerElementEditorFieldProps {
  name: string;
  onRemove?: () => void;
  onMove: (direction: 'up' | 'down') => void;
}

export const PartnerElementEditorField: FC<PartnerElementEditorFieldProps> = ({ name, onRemove, onMove }) => {
  const render = (props: FieldRenderProps<any, HTMLElement>) => (
    <PartnerElementEditor
      value={props.input.value}
      error={props.meta.touched ? props.meta.error : undefined}
      onChange={props.input.onChange}
      onRemove={onRemove}
      onMove={onMove}
      onFocus={props.input.onFocus}
      onBlur={props.input.onBlur}
    />
  );

  return (
    <Field
      name={name}
      required={true}
      render={render}
      validate={(value: PartnerFormValueElement) => {
        if (value?.image && value.image.valueType === 'file') {
          const filesizeMb = value.image.file.size / 1024 / 1024;
          if (filesizeMb > 2) return 'errors.validation.filesizeTooLarge';
        }
        return value?.image === null
          ? 'errors.validation.required'
          : !value?.text
          ? 'errors.validation.required'
          : value.text.length > 100
          ? 'errors.validation.tooLong'
          : undefined;
      }}
    />
  );
};
