import { SvgIconComponent } from '@material-ui/icons';
import { ButtonProps } from '@material-ui/core';

export interface ScreenAction {
  label: string;
  icon?: SvgIconComponent;
  handler: () => void;
  variant?: ButtonProps['variant'];
}

export interface RouteFields {
  name: string;
  path: string;
  params?: string[];
  component: any;
  icon?: any;
  menuItem?: boolean;
  managerOnly?: boolean;
}

export class Route {
  readonly href: string;

  constructor(
    readonly name: string,
    readonly path: string,
    readonly params: string[],
    readonly component: any,
    readonly managerOnly: boolean,
    readonly icon?: SvgIconComponent,
    readonly menuItem?: boolean,
  ) {
    const paramsString = params.length > 0 ? params.slice(1).reduce((p) => `/:${p}`, `/:${params[0]}`) : '';
    this.href = `${path}${paramsString}`;
  }

  link(...params: any[]): string {
    const paramsString = params.length > 0 ? params.slice(1).reduce((p) => `/${p}`, `/${params[0]}`) : '';
    return `${this.path}${paramsString}`;
  }

  static create({ name, path, params = [], component, icon, menuItem, managerOnly }: RouteFields): Route {
    return new Route(name, path, params, component, managerOnly ?? false, icon, menuItem);
  }

  matchesLocation(pathname: string): boolean {
    const path = pathname.split('/');
    const startsWithPath = pathname.startsWith(this.path);
    return startsWithPath && path.length === this.path.split('/').length + this.params.length;
  }
}
