import { flow, makeAutoObservable } from 'mobx';
import { authApi } from '../services/api';
import { PATHS } from '../services/api/paths';
import { ApiUrl } from '../services/client';
import { deleteAccessToken, readAccessToken, storeAccessToken } from '../services/sessionStorage';
import { AccessTokenData, UserId, AdminRole } from '../types/Auth';

export class AuthStore {
  public user: {
    id: UserId;
    role: AdminRole;
  } | null = null;

  constructor(private readonly resetAppState: () => void) {
    makeAutoObservable(this);
    if (this.token) {
      this.setSession(this.token);
    }
  }

  get isAuthenticated(): boolean {
    return this.user !== null;
  }

  get token(): string | null {
    return readAccessToken();
  }

  login: () => void = () => {
    // For DEV purposes
    // this.setSession(this.token!);
    document.location.href = `${ApiUrl}${PATHS.auth.login}`;
  };

  logout: () => Promise<void> = flow(function* (this: AuthStore) {
    yield authApi.logout();
    this.clearSession();
  }).bind(this);

  setSession = (token: string) => {
    const data: AccessTokenData = JSON.parse(atob(token.split('.')[1]));
    this.user = {
      id: parseInt(data.userId),
      role: data.role,
    };
    storeAccessToken(token);
  };

  clearSession = () => {
    deleteAccessToken();
    this.user = null;
    this.resetAppState();
  };
}
