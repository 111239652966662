import React, { FC, useCallback, useEffect } from 'react';
import { EditorState } from 'draft-js';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { ScreenAction, TipElementType, TipFormValue, TipReminderFrequency } from '../../types';
import { HolidayTipForm } from './HolidayTipForm';

export const NewHolidayTipScreen: FC = observer(() => {
  const {
    tipStore: { createTip },
    tagStore: { tags, fetchTagsIfNotFetched },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: TipFormValue) => {
      return createTip(values, true).then((tipId) => {
        if (tipId) {
          skipCheck(() => history.push(ROUTE.holidayTip.link(tipId)));
        }
      });
    },
    [createTip, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: TipFormValue = {
    submitType: 'draft',
    title: '',
    image: null,
    tagGroup: null,
    primaryTag: null,
    secondaryTags: {
      ingredient: [],
      home: [],
      garden: [],
      food: [],
      crafts: [],
    },
    scheduledDateTime: DateTime.local().set({
      minute: Math.floor(DateTime.local().minute / 5) * 5,
      second: 0,
      millisecond: 0,
    }),
    didYouKnow: {
      text: '',
      value: '',
    },
    season: {
      key: [],
      title: null,
      text: null,
      image: null,
    },
    reminder: {
      enabled: false,
      title: '',
      text: '',
      frequency: TipReminderFrequency.OnceAWeek,
      defaultDateTime: DateTime.local(),
    },
    elements: [{ type: TipElementType.Text, content: EditorState.createEmpty() }],
    keywords: '',
  };

  return (
    <Layout allowOverflow title={t('holidayTip.addNew')} screenActions={actions}>
      {tags.state === 'Fetched' && <HolidayTipForm initial={initial} tags={tags.data} onSubmit={onSubmit} />}
    </Layout>
  );
});
