import { styled, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { COLORS } from '../../styles';
import { AdFormValue } from '../../types';

const Container = styled('div')({
  position: 'absolute',
  bottom: 20,
  right: 20,
});

const Card = styled('div')({
  backgroundColor: COLORS.primary,
  display: 'flex',
  flexDirection: 'column',
  width: 140,
  height: 192,
  position: 'relative',
  borderRadius: 5,
  filter: 'drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.25))',
});

const Image = styled('img')({
  width: '100%',
  height: 107,
  borderRadius: 5,
  objectFit: 'cover',
});

const ImagePlaceholder = styled('div')({
  width: '100%',
  height: 107,
  borderRadius: 5,
});

const TagText = styled(Typography)({
  position: 'absolute',
  left: 8,
  top: 8,
  color: COLORS.white,
  backgroundColor: COLORS.orange,
  borderRadius: 100,
  padding: '4px 15px',
  fontSize: 12,
  lineHeight: '115%',
});

const TextContainer = styled('div')({
  height: 85,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 10,
});

const Text = styled(Typography)({
  fontFamily: 'Rooney',
  color: COLORS.white,
  fontSize: 14,
  lineHeight: '115%',
  fontWeight: 500,
  textAlign: 'center',
});

export const AdCardPreview: FC<AdFormValue> = ({ text, tagText, image }) => (
  <Container>
    <Card>
      {!!tagText && <TagText>{tagText}</TagText>}
      {image ? <Image src={image.valueType === 'existing' ? image.url : image.preview} /> : <ImagePlaceholder />}
      <TextContainer>
        <Text>{text}</Text>
      </TextContainer>
    </Card>
  </Container>
);
