import React, { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { QuestionTagFormValue, ScreenAction } from '../../types';
import { QuestionTagForm } from './QuestionTagForm';

export const NewQuestionTagScreen: FC = observer(() => {
  const {
    questionStore: { createQuestionTag },
    tagStore: { tags, fetchTagsIfNotFetched },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  useEffect(() => {
    fetchTagsIfNotFetched();
  }, [fetchTagsIfNotFetched]);

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: QuestionTagFormValue) => {
      return createQuestionTag(values).then((tagId) => {
        if (tagId) {
          skipCheck(() => history.push(ROUTE.questionTag.link(tagId)));
        }
      });
    },
    [createQuestionTag, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: QuestionTagFormValue = {
    name: '',
    image: null,
  };

  return (
    <Layout allowOverflow title={t('questionTag.addNew')} screenActions={actions}>
      {tags.state === 'Fetched' && <QuestionTagForm initial={initial} onSubmit={onSubmit} />}
    </Layout>
  );
});
