import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { questionApi } from '../services/api';
import { QuestionTagUpdateRequest } from '../services/api/question';
import {
  QuestionTagFormValue,
  QuestionTagTableRow,
  mapRemoteData,
  RemoteData,
  RequestState,
  Tag,
  TagState,
  TagId,
  QuestionTagInfoRequest,
  Question,
} from '../types';
import { RootStore } from './Stores';
import { executeRequest, fetchRemoteData } from './storeUtils';

export class QuestionStore {
  rootStore: RootStore;
  stateFilter: TagState = TagState.Active;

  questionTags: RemoteData<Tag[]> = { state: 'NotFetched' };
  questionTag: RemoteData<Tag> = { state: 'NotFetched' };

  questions: RemoteData<Question[]> = { state: 'NotFetched' };

  savingTag: RequestState = 'Initial';
  deletingTag: RequestState = 'Initial';

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  get questionTagsByState(): RemoteData<Record<TagState, QuestionTagTableRow[]>> {
    return mapRemoteData(
      this.questionTags,
      (questionTags): Record<TagState, QuestionTagTableRow[]> =>
        questionTags.reduce(
          (acc: Record<TagState, QuestionTagTableRow[]>, questionTag: QuestionTagTableRow) => {
            const state: TagState = questionTag.active ? TagState.Active : TagState.Inactive;
            return { ...acc, [state]: [...acc[state], questionTag] };
          },
          { [TagState.Active]: [], [TagState.Inactive]: [] },
        ),
    );
  }

  setStateFilter = (state: TagState) => (this.stateFilter = state);

  fetchQuestionTag: (id: TagId) => Promise<void> = flow(function* (this: QuestionStore, id: TagId) {
    yield fetchRemoteData(this, 'questionTag', () => questionApi.getQuestionTag(id));
  }).bind(this);

  fetchQuestions: () => Promise<void> = flow(function* (this: QuestionStore) {
    yield fetchRemoteData(this, 'questions', () => questionApi.getQuestions());
  }).bind(this);

  createQuestionTag: (values: QuestionTagFormValue) => Promise<TagId | undefined> = flow(function* (
    this: QuestionStore,
    values: QuestionTagFormValue,
  ) {
    const [request, image] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingTag', () => questionApi.createQuestionTag(request, image!));
    yield this.rootStore.tagStore.fetchTags();
    return result;
  }).bind(this);

  updateQuestionTag: (id: TagId, values: QuestionTagFormValue) => Promise<void> = flow(function* (
    this: QuestionStore,
    id: TagId,
    values: QuestionTagFormValue,
  ) {
    const [request, image] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingTag', () => questionApi.updateQuestionTag(id, request, image));
    yield this.rootStore.tagStore.fetchTags();
    this.questionTag = { state: 'Fetched', data: result };
  }).bind(this);

  updateTagInfo: (id: TagId, data: QuestionTagInfoRequest) => Promise<void> = flow(function* (
    this: QuestionStore,
    id: TagId,
    data: QuestionTagInfoRequest,
  ) {
    yield executeRequest(this, 'savingTag', () => questionApi.updateTagInfo(id, data));
    this.questionTags = { state: 'Fetching' };
    yield this.rootStore.tagStore.fetchTags();
  }).bind(this);
}

function formValueToRequest(values: QuestionTagFormValue): [QuestionTagUpdateRequest, FileWithPreview | null] {
  const request: QuestionTagUpdateRequest = {
    name: values.name.trim(),
    image: values.image!.name,
  };
  const image = values.image && values.image.valueType === 'file' ? values.image : null;

  return [request, image];
}
