import { IconButton, styled, Typography } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import { transitions, transparentize } from 'polished';
import React, { FC, useEffect, useState } from 'react';
import { COLORS } from '../../styles';
import { IToast } from '../../types';

const getBackground = (type: string) => {
  switch (type) {
    case 'success':
      return COLORS.toastGreen;
    case 'error':
      return COLORS.toastRed;
    default:
      return COLORS.white;
  }
};

interface IContainer {
  type: string;
  theme: any;
  visible: boolean;
  index: number;
  id: string;
}

interface Props extends IToast {
  index: number;
  onCloseClick: (index: number) => void;
}

const Container = styled('div')({
  position: 'fixed',
  top: (props: { index: number }) => `${props.index * 80 + 10}px`,
  padding: '1rem 2rem',
  minWidth: '200px',
  maxWidth: '400px',
  minHeight: '40px',
  opacity: (props: IContainer) => (props.visible ? '1' : '0'),
  right: (props: IContainer) => (props.visible ? '1rem' : '-20rem'),
  background: (props: IContainer) => getBackground(props.type),
  border: `1px solid ${COLORS.white}`,
  borderRadius: '2px',
  boxShadow: '1px 1px 5px rgba(0,0,0,0.05)',
  alignItems: 'center',
  display: 'flex',
  color: `${COLORS.white}`,
  '& svg': {
    height: '40px',
    width: '40px',
  },
  ...transitions(['opacity', 'right', 'top'], '0.5s ease-in 0s'),
});

const CloseButton = styled(IconButton)({
  marginLeft: '2rem',
  marginRight: '-1rem',
  cursor: 'pointer',
  '& svg': {
    height: '2rem',
    color: transparentize(0.4, COLORS.white),
  },
});

const Toast: FC<Props> = ({ msg, type, index, onCloseClick }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const visibleTimeout = setTimeout(() => {
      setVisible(true);
    }, 250);

    return () => {
      setVisible(false);
      clearTimeout(visibleTimeout);
    };
  }, []);

  const onCloseButtonClick = () => {
    onCloseClick(index);
  };

  return (
    <Container id="toast" index={index} visible={visible} type={type}>
      <CheckCircle />
      <Typography color="textSecondary">{msg}</Typography>
      {type === 'error' && (
        <CloseButton aria-label="delete" onClick={onCloseButtonClick} id={`close-toast-${index}`}>
          <Close />
        </CloseButton>
      )}
    </Container>
  );
};

export { Toast };
