import { FileWithPreview } from '../../components/FormControls';
import { MembershipBenefit, MembershipBenefitId, MembershipBenefitTableRow, MembershipBenefitRole } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getMembershipBenefits = async (): Promise<MembershipBenefitTableRow[]> => {
  const result = await ApiClient.get<MembershipBenefitTableRow[]>(PATHS.membershipBenefits.root);
  return result.data;
};

export const getMembershipBenefit = async (id: MembershipBenefitId): Promise<MembershipBenefit> => {
  const result = await ApiClient.get<any>(PATHS.membershipBenefits.single(id));
  return result.data;
};

export interface MembershipBenefitUpdateRequest {
  readonly text: string;
  readonly image: string;
  readonly link: string;
  readonly buttonText: string;
  readonly active: boolean;
  readonly role: MembershipBenefitRole;
}

export const createMembershipBenefit = async (
  request: MembershipBenefitUpdateRequest,
  image: FileWithPreview,
): Promise<MembershipBenefitId> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  data.append('image', image.file);

  const result = await ApiClient.post<{ id: number }>(PATHS.membershipBenefits.root, data);
  return result.data.id;
};

export const updateMembershipBenefit = async (
  id: MembershipBenefitId,
  request: MembershipBenefitUpdateRequest,
  image: FileWithPreview | null,
): Promise<MembershipBenefit> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  if (image) data.append('image', image.file);

  const result = await ApiClient.put<any>(PATHS.membershipBenefits.single(id), data);
  return result.data;
};

export const deleteMembershipBenefit = async (id: MembershipBenefitId): Promise<void> => {
  await ApiClient.delete(PATHS.membershipBenefits.single(id));
};
