import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { TextField, Radio, styled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';

interface RadioInputFieldProps {
  id: string;
  name: string;
  validate?: FieldValidator<any>;
  checked?: boolean;
  onClick?: () => void;
  placeholder?: string;
}

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const RadioInputField: FC<RadioInputFieldProps> = ({ id, name, validate, checked, onClick, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Radio checked={checked} onClick={onClick} />
      <Field name={name} validate={validate}>
        {({ input, meta }) => (
          <TextField
            {...input}
            value={checked ? input.value.title : ''}
            onChange={(e) => input.onChange({ title: e.target.value })}
            id={id}
            variant="outlined"
            fullWidth
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error && t(meta.error)}
            disabled={!checked}
            {...rest}
          />
        )}
      </Field>
    </Wrapper>
  );
};
