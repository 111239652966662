import React, { FC, useEffect } from 'react';
import { CircularProgress, styled, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ArrowRightAlt, Delete, Edit } from '@material-ui/icons';
import { useHistory } from 'react-router';

import { useStores } from '../../stores';
import { PartnerElement, PartnerTableRow } from '../../types';
import { COLORS } from '../../styles';
import { ROUTE } from '../../constants';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { PartnerAnalyticsSummaryTable } from './PartnerAnalyticsSummaryTable';

const ActiveContainer = styled('div')({
  border: `1px solid ${COLORS.grey}`,
  backgroundColor: '#fafafa',
  padding: '1rem',
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
});

const TopRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 20,
});

const Logo = styled('img')({
  width: 100,
  height: 100,
  borderRadius: '50%',
  marginBottom: 20,
});

const ElementContainer = styled('div')({
  margin: '20px 0',
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
});

const Element = styled('div')({
  display: 'inline-flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '20px 20px 0 0',
  height: 'auto',
  width: 320,
  minHeight: 470,
  textAlign: 'center',
  backgroundColor: COLORS.white,
});

const ElementImage = styled('img')({
  width: 320,
  height: 400,
  objectFit: 'cover',
});

const ElementText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 70,
  width: '100%',
  padding: '10px 15px',
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '115%',
  backgroundColor: '#F0F0F0',
});

const LastElementText = styled(ElementText)({
  backgroundColor: COLORS.white,
});

const Button = styled('button')({
  backgroundColor: '#EB5C2E',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 100,
  border: 'none',
  color: COLORS.white,
  fontFamily: 'Nunito',
  fontSize: 16,
  fontWeight: 400,
  padding: '12px 24px',
});

type Props = {
  rowItem: PartnerTableRow;
  setToDelete: (partner: PartnerTableRow) => void;
};

export const ActivePartner: FC<Props> = observer(({ rowItem, setToDelete }) => {
  const {
    partnerStore: { fetchPartner, partner },
  } = useStores();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (rowItem) fetchPartner(rowItem.id);
  }, [rowItem, fetchPartner]);

  if (!rowItem) {
    return (
      <ActiveContainer>
        <Typography variant="h4" align="left">
          {t('partner.list.noActivePartners')}
        </Typography>
      </ActiveContainer>
    );
  }

  const renderElements = () => {
    if (partner.state !== 'Fetched') return null;

    return (
      <ElementContainer>
        {partner.data.elements.map((it: PartnerElement) => (
          <Element key={it.order}>
            <ElementImage src={it.imageUrl} />
            <ElementText>{it.text}</ElementText>
          </Element>
        ))}
        <Element>
          <LastElementText>{partner.data.longDescription}</LastElementText>
          <Button>{partner.data.linkText}</Button>
        </Element>
      </ElementContainer>
    );
  };

  return (
    <ActiveContainer>
      {(() => {
        switch (partner.state) {
          case 'NotFetched':
          case 'Fetching':
            return <CircularProgress />;
          case 'ErrorFetching':
            return <span>{t('errors.error')}</span>;
          case 'Fetched':
            const {
              title,
              shortDescription,
              imageUrl,
              scheduledStartDateTime,
              scheduledEndDateTime,
              analyticsSummary,
            } = partner.data;
            return (
              <>
                <TopRow>
                  <IconButtonRow>
                    <Typography variant="h2">{scheduledStartDateTime?.toFormat('dd.MM.yyyy')}</Typography>
                    <ArrowRightAlt />
                    <Typography variant="h2">{scheduledEndDateTime?.toFormat('dd.MM.yyyy')}</Typography>
                  </IconButtonRow>
                  <IconButtonRow>
                    <IconButton size={40} icon={Delete} onClick={() => setToDelete(rowItem)} />
                    <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.partner.link(rowItem.id))} />
                  </IconButtonRow>
                </TopRow>
                <Logo src={imageUrl} />
                <Typography variant="h1">{title}</Typography>
                <Typography>{shortDescription}</Typography>
                <PartnerAnalyticsSummaryTable analyticsSummary={analyticsSummary} />
                {renderElements()}
              </>
            );
          default:
            return <div>unknown state</div>;
        }
      })()}
    </ActiveContainer>
  );
});
