import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Layout } from '../../components/Layout';
import { useStores } from '../../stores';
import { ScreenAction, PollFormValue } from '../../types';
import { PollForm } from './PollForm';
import { Results } from './Results';
import { range } from 'lodash/fp';

export const PollScreen: FC = observer(() => {
  const {
    pollStore: { poll, fetchPoll, updatePoll },
  } = useStores();

  const { id: idString } = useParams<{ id: string }>();
  const id: number | null = useMemo(() => {
    const parsed = parseInt(idString);
    return Number.isInteger(parsed) ? parsed : null;
  }, [idString]);

  useEffect(() => {
    if (id) fetchPoll(id);
  }, [id, fetchPoll]);

  const onSubmit = useCallback(
    async (values: PollFormValue): Promise<void> => {
      if (id) {
        return await updatePoll(id, values);
      }
    },
    [id, updatePoll],
  );

  const actions: ScreenAction[] = [];

  const fetching = poll.state === 'Fetching';

  const formData: PollFormValue | null = useMemo(() => {
    if (poll.state !== 'Fetched') return null;

    const pollFormValue: PollFormValue = {
      title: poll.data.title,
      infoText: poll.data.infoText ?? '',
      link: poll.data.link ?? '',
      options: [...poll.data.options.map((o) => o.title), ...range(poll.data.options.length, 4).map(() => '')] as any,
    };

    return pollFormValue;
  }, [poll]);

  return (
    <Layout allowOverflow title={poll.state === 'Fetched' ? poll.data.title : ''} screenActions={actions}>
      {fetching ? (
        <CircularProgress size="3rem" />
      ) : id === null || formData === null ? null : (
        <>
          <PollForm initial={formData} onSubmit={onSubmit} />
          {poll.state === 'Fetched' && <Results options={poll.data.options} />}
        </>
      )}
    </Layout>
  );
});
