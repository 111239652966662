import { convertToHTML } from 'draft-convert';
import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { settingsApi } from '../services/api';
import { UpdateFrontPageSettingsRequest } from '../services/api/settings';
import {
  FrontPageSettingsFormValue,
  PopularComment,
  RemoteData,
  RemotePaginatedData,
  RequestState,
  Settings,
} from '../types';
import { executeRequest, fetchRemoteData } from './storeUtils';

export class SettingsStore {
  settings: RemoteData<Settings> = { state: 'NotFetched' };
  popularComments: RemotePaginatedData<PopularComment> = { state: 'Initial' };
  savingSettings: RequestState = 'Initial';

  constructor() {
    makeAutoObservable(this);
  }

  fetchSettings: () => Promise<void> = flow(function* (this: SettingsStore) {
    yield fetchRemoteData(this, 'settings', async () => {
      const settings = await settingsApi.getSettings();
      this.popularComments = {
        state: 'Fetched',
        fetched: settings.popularComments.length,
        data: settings.popularComments,
      };
      return settings;
    });
  }).bind(this);

  fetchMorePopularComments: () => Promise<void> = flow(function* (this: SettingsStore) {
    let [fetched, data] =
      this.popularComments.state === 'Initial' ? [0, []] : [this.popularComments.fetched, this.popularComments.data];

    this.popularComments.state = 'Fetching';
    const newData = yield settingsApi.getPopularComments(fetched, 10);
    const nextData = [...data, ...newData];

    this.popularComments = { state: 'Fetched', fetched: nextData.length, data: nextData };
  }).bind(this);

  updateFrontPageSettings: (values: FrontPageSettingsFormValue) => Promise<void> = flow(function* (
    this: SettingsStore,
    values: FrontPageSettingsFormValue,
  ) {
    const [request, image] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingSettings', () =>
      settingsApi.updateFrontPageSettings(request, image),
    );
    this.settings = { state: 'Fetched', data: result };
    this.popularComments = { state: 'Fetched', fetched: result.popularComments.length, data: result.popularComments };
  }).bind(this);
}

function formValueToRequest({
  frontPageHighlightImage: image,
  ...values
}: FrontPageSettingsFormValue): [UpdateFrontPageSettingsRequest, FileWithPreview | null] {
  const frontPageSeasonIngredient = values.frontPageSeasonIngredient?.trim();
  const request = {
    frontPagePollId: values.frontPagePoll?.id ?? null,
    frontPageSeasonTipId: values.frontPageSeasonTip?.id ?? null,
    frontPageSeasonIngredient: frontPageSeasonIngredient === '' ? null : frontPageSeasonIngredient ?? null,
    frontPageRiddleName: values.frontPageRiddleName ?? null,
    frontPageRiddleLink: values.frontPageRiddleLink ?? null,
    frontPageHighlightTitle: values.frontPageHighlightTitle ?? null,
    frontPageHighlightText: values.frontPageHighlightText ?? null,
    frontPageHighlightButton: values.frontPageHighlightButton ?? null,
    frontPageHighlightLink: values.frontPageHighlightLink ?? null,
    frontPageHighlightOptionalButton: values.frontPageHighlightOptionalButton ?? null,
    frontPageHighlightOptionalLink: values.frontPageHighlightOptionalLink ?? null,
    frontPageHighlightImage: image?.name ?? null,
    activeHolidayTagGroup: values.activeHolidayTagGroup?.key ?? null,
    ignorePopularComments: values.ignorePopularComments,
    newAppFeatures: htmlConvert(values.newAppFeatures!.getCurrentContent()),
    activeHolidayTagGroupId: values.activeHolidayTagGroup?.id ?? null,
  };
  const requestImage = image && image.valueType === 'file' ? image : null;
  return [request, requestImage];
}

const htmlConvert = convertToHTML({
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return `<a href=${entity.data.url}>${originalText}</a>`;
    }
    return originalText;
  },
});
