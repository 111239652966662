import React, { FC } from 'react';
import { styled, TextField as MuiTextField, OutlinedTextFieldProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GREY } from '../../styles';

interface SearchFieldProps extends Omit<OutlinedTextFieldProps, 'onChange' | 'variant'> {
  onChange: (value: string) => void;
}

const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-input': {
    padding: '0px 14px',
    height: 38,
    border: `1px solid ${GREY}`,
    borderRadius: '6px',
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
  },
});

export const SearchField: FC<SearchFieldProps> = ({ onChange, ...rest }) => {
  const { t } = useTranslation();

  return (
    <TextField
      id="search-field"
      fullWidth
      placeholder={t('common.search')}
      onChange={(e) => onChange(e.target.value)}
      {...rest}
      variant="outlined"
    />
  );
};
