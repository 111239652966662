import { DialogContent, DialogContentText, Grid, styled } from '@material-ui/core';
import { observer } from 'mobx-react';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmDialog, CopyToTipDialog } from '../../components/Dialog';
import { Button, MultiSelectComboBox, SearchField } from '../../components/FormControls';
import { ComboBox } from '../../components/FormControls/ComboBox';
import { useStores } from '../../stores';
import { DARK_GREY, PRIMARY } from '../../styles';
import { ArticleStateKey, Tag, TagGroupKey, TipSeasonKey, articleStateKeys, tipSeasonKeys } from '../../types';

const MassActionHeader = styled('span')({
  fontWeight: 'bold',
  fontSize: 18,
});

const ConfirmButtonContainer = styled(Grid)({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
});

const ConfirmButton = styled(Button)({
  backgroundColor: DARK_GREY,
  padding: '6px 32px',
});

const CopyButton = styled(Button)({
  padding: '6px 32px',
});

const ControlsGrid = styled(Grid)({
  marginBottom: '35px',
  '& > :not(:last-child)': {
    marginRight: '12px',
  },
  alignItems: 'center',
});

const ControlBeforeSeparator = styled(Grid)({
  paddingRight: '15px',
});

const ControlWithLeftSeparator = styled(Grid)({
  borderLeft: `2px solid ${PRIMARY}`,
  paddingLeft: '15px',
});

export const TipListActions: FC = observer(() => {
  const {
    tipStore: {
      listingFilters: { group: groupFilter, tag: tagFilter, season: seasonFilter },
      setListingGroupFilter,
      setListingSeasonFilter,
      setListingTagFilter,
      setKeywordFilter,
      selectedTipRows,
      massUpdateTips,
      setSelectedTipRows,
      copyToTips,
    },
    tagStore: { tags },
  } = useStores();
  const { t } = useTranslation();

  const [toSeason, setToSeason] = useState<TipSeasonKey[]>([]);
  const [toState, setToState] = useState<ArticleStateKey | null>(null);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [copyVisible, setCopyVisible] = useState<boolean>(false);

  const allowedTagGroups = useMemo(() => {
    return tags.state === 'Fetched'
      ? tags.data.groups.filter((g) =>
          [TagGroupKey.Home, TagGroupKey.Garden, TagGroupKey.Food, TagGroupKey.Crafts].includes(g.key),
        )
      : [];
  }, [tags]);

  const ConfirmModal = () => {
    const messageRows = () => {
      const rows: string[] = [];
      if (toSeason.length) {
        rows.push(
          t('massActions.confirm.season', { season: toSeason.map((s) => t(`tip.list.seasonLabel.${s}`)).join(', ') }),
        );
      }
      if (toState) rows.push(t('massActions.confirm.state', { state: t(`massActions.state.${toState}`) }));
      return rows;
    };
    return confirmVisible ? (
      <ConfirmDialog
        open
        title={t('massActions.confirm.title', { count: selectedTipRows.length })}
        onAccept={() => {
          setConfirmVisible(false);
          massUpdateTips({
            ids: selectedTipRows,
            seasonKeys: toSeason.length ? toSeason : undefined,
            published: toState ? toState === ArticleStateKey.Publish : undefined,
          }).then(() => {
            setToSeason([]);
            setToState(null);
            setSelectedTipRows([]);
          });
        }}
        onCancel={() => setConfirmVisible(false)}
      >
        <DialogContent>
          {messageRows().map((row, i) => (
            <DialogContentText key={i}>{row}</DialogContentText>
          ))}
        </DialogContent>
      </ConfirmDialog>
    ) : null;
  };

  return (
    <>
      <ConfirmModal />
      <CopyToTipDialog
        isVisible={copyVisible}
        onAccept={(tagId, toSeasons) => {
          copyToTips({
            ids: selectedTipRows,
            tagId,
            seasonKeys: toSeasons,
          }).then(() => {
            setSelectedTipRows([]);
            setCopyVisible(false);
          });
        }}
        onClose={() => setCopyVisible(false)}
      />
      {selectedTipRows.length ? (
        // Mass action selections
        <ControlsGrid container>
          <Grid item>
            <MassActionHeader>{t('massActions.title')}</MassActionHeader>
          </Grid>
          <Grid item xs={3}>
            <MultiSelectComboBox<TipSeasonKey>
              placeholder={t('massActions.selectSeason')}
              options={tipSeasonKeys}
              value={toSeason}
              getOptionLabel={(v) => t(`tip.list.seasonLabel.${v}`)}
              onChange={(value) => setToSeason(value)}
              altStyle
              narrow
            />
          </Grid>
          <Grid item xs={3}>
            <ComboBox<ArticleStateKey>
              placeholder={t('massActions.selectState')}
              options={articleStateKeys}
              value={toState}
              getOptionLabel={(v) => t(`massActions.state.${v}`)}
              onChange={(value) => setToState(value)}
              altStyle
              narrow
            />
          </Grid>
          <ConfirmButtonContainer>
            <ControlBeforeSeparator>
              <ConfirmButton
                label={t('massActions.confirm.button')}
                onClick={() => setConfirmVisible(true)}
                disabled={!toState}
              />
            </ControlBeforeSeparator>
            <ControlWithLeftSeparator>
              <CopyButton label={t('massActions.copy.button')} onClick={() => setCopyVisible(true)} />
            </ControlWithLeftSeparator>
          </ConfirmButtonContainer>
        </ControlsGrid>
      ) : (
        // Filters
        <ControlsGrid container>
          <Grid item xs={2}>
            <SearchField onChange={(value) => setKeywordFilter(value)} />
          </Grid>
          <Grid item xs={3}>
            <ComboBox
              placeholder={t('tip.list.selectCategory')}
              value={groupFilter}
              options={allowedTagGroups}
              getOptionSelected={(a, b) => a?.id === b?.id}
              getOptionLabel={(g) => g.name}
              onChange={(value) => setListingGroupFilter(value ?? null)}
              narrow
            />
          </Grid>
          <ControlBeforeSeparator item xs={3}>
            <ComboBox<Tag>
              placeholder={t('tip.list.selectSubCategory')}
              disabled={groupFilter === null}
              value={tagFilter}
              options={tags.state === 'Fetched' && groupFilter ? tags.data.tagsByGroupKey[groupFilter.key] ?? [] : []}
              getOptionSelected={(a, b) => a?.id === b?.id}
              getOptionLabel={(t) => t?.name ?? ''}
              onChange={(value) => setListingTagFilter(value)}
              narrow
            />
          </ControlBeforeSeparator>
          <ControlWithLeftSeparator item xs={3}>
            <ComboBox<TipSeasonKey>
              placeholder={t('tip.list.selectSeason')}
              options={tipSeasonKeys}
              value={seasonFilter}
              getOptionLabel={(v) => t(`tip.list.seasonLabel.${v}`)}
              onChange={(value) => setListingSeasonFilter(value)}
              narrow
            />
          </ControlWithLeftSeparator>
        </ControlsGrid>
      )}
    </>
  );
});
