import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { Button, ComboBox, FormBox, Layout } from '../components';
import { firebaseApi, recipeApi } from '../services/api';
import { AppScreens, DynamicLinkType, RecipeListItem } from '../types';
import { COLORS } from '../styles';
import { useStores } from '../stores';
import { useTranslation } from 'react-i18next';

const Section = styled('div')({
  marginBottom: '40px',
});

const HalfWidthField = styled('div')({
  maxWidth: '50%',
  position: 'relative',
});

const AbsoluteView = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flex: 1,
  backgroundColor: COLORS.white,
  transition: 'all 100ms ease-in-out',
  border: `1px solid ${COLORS.grey}`,
  borderRadius: 4,
  paddingLeft: 10,
  pointerEvents: 'none',
});

const Typography = styled(MuiTypography)({
  marginBottom: 5,
});

const TextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    borderWidth: 0,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey,
  },
});

type Item = {
  id: number;
  name: string;
};

export const DynamicLinkScreen: FC = observer(() => {
  const {
    toastStore: { showSuccess, showError },
    tipStore: { fetchTips, tips },
    questionStore: { questionTagsByState, fetchQuestions, questions },
    tagStore: { fetchTagsIfNotFetched },
  } = useStores();
  const { t } = useTranslation();

  const [recipes, setRecipes] = useState<RecipeListItem[]>([]);

  const [radio, setRadio] = useState<DynamicLinkType>(DynamicLinkType.Recipe);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');

  useEffect(() => {
    const handleFetch = async () => {
      fetchTagsIfNotFetched();
      fetchTips();
      fetchQuestions();
      const recipes = await recipeApi.getRecipes();
      setRecipes(recipes.sort((a, b) => a.title.localeCompare(b.title)));
    };
    handleFetch();
  }, [fetchQuestions, fetchTagsIfNotFetched, fetchTips]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRadio(event.target.value as DynamicLinkType);
    setSelectedItem(null);
  };

  const handleCreateLink = async () => {
    if (!selectedItem) return;
    setFetching(true);
    const res = await firebaseApi.createDynamicLink({ type: radio, id: selectedItem?.id });
    if (!res) showError(t('errors.createDynamicLink'));
    setFetching(false);
    setLink(res ?? '');
  };

  const handleTextFieldFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (!link) return;
    event.target.select();
    navigator.clipboard.writeText(link);
    showSuccess(t('deepLink.copiedToClipboard'));
  };

  const getOptions = (): Item[] => {
    switch (radio) {
      case DynamicLinkType.Recipe:
        return recipes.map((it) => ({ id: it.id, name: it.title }));
      case DynamicLinkType.Tip:
        return tips.state === 'Fetched' ? tips.data.map((it) => ({ id: it.id, name: it.title })) : [];
      case DynamicLinkType.Question:
        return questions.state === 'Fetched'
          ? questions.data.map((it) => ({ id: it.id, name: it.title ?? it.questionMessage }))
          : [];
      case DynamicLinkType.QuestionCategory:
        return questionTagsByState.state === 'Fetched'
          ? questionTagsByState.data.active.map((it) => ({ id: it.id, name: it.name }))
          : [];
      case DynamicLinkType.Screen:
        return AppScreens;
      default:
        return [];
    }
  };

  const radios = Object.values(DynamicLinkType).map((key) => (
    <FormControlLabel key={key} value={key} control={<Radio color="primary" />} label={t(`deepLink.type.${key}`)} />
  ));

  return (
    <Layout allowOverflow>
      <FormBox>
        <Section>
          <Typography variant="h2">{t('deepLink.selectType')}</Typography>
          <RadioGroup name="content-type" value={radio} onChange={handleRadioChange}>
            {radios}
          </RadioGroup>
        </Section>
        <Section>
          <Typography variant="h2">{t('deepLink.selectContent')}</Typography>
          <Typography>{t('deepLink.selectContentInstruction')}</Typography>
          <HalfWidthField>
            <ComboBox
              value={selectedItem}
              options={getOptions()}
              getOptionLabel={(it) => it.name}
              onChange={setSelectedItem}
            />
          </HalfWidthField>
        </Section>
        <Section>
          <Typography variant="h2">{t('deepLink.pressButton')}</Typography>
          <Button label={t('deepLink.button')} onClick={handleCreateLink} disabled={!selectedItem} />
        </Section>
        <Section>
          <Typography variant="h2">{t('deepLink.copyLink')}</Typography>
          <HalfWidthField>
            <TextField
              variant="outlined"
              fullWidth
              value={link}
              contentEditable={false}
              onFocus={handleTextFieldFocus}
            />
            <AbsoluteView style={{ opacity: fetching ? 1 : 0 }}>
              <CircularProgress color="primary" />
            </AbsoluteView>
          </HalfWidthField>
        </Section>
      </FormBox>
    </Layout>
  );
});
