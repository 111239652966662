import { DateTime, Interval } from 'luxon';
import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { partnerApi } from '../services/api';
import { PartnerUpdateRequest } from '../services/api/partner';
import {
  mapRemoteData,
  RemoteData,
  RequestState,
  Partner,
  PartnerFormValue,
  PartnerFormValueElement,
  PartnerId,
  PartnerListItem,
  PartnerState,
  PartnerTableRow,
  CopyPartnersParams,
} from '../types';
import { collect } from '../utils';
import { executeRequest, fetchRemoteData } from './storeUtils';

interface PartnerListingFilters {
  state: PartnerState;
}

export class PartnerStore {
  partners: RemoteData<PartnerListItem[]> = { state: 'NotFetched' };
  listingFilters: PartnerListingFilters = { state: PartnerState.Active };
  selectedPartnerRows: PartnerId[] = [];

  partner: RemoteData<Partner> = { state: 'NotFetched' };
  savingPartner: RequestState = 'Initial';
  deletingPartner: RequestState = 'Initial';

  get partnersByState(): RemoteData<Record<PartnerState, PartnerListItem[]>> {
    const now = DateTime.local();
    return mapRemoteData(
      this.partners,
      (partners): Record<PartnerState, PartnerListItem[]> =>
        partners.reduce(
          (acc: Record<PartnerState, PartnerListItem[]>, partner: PartnerListItem) => {
            const state: PartnerState =
              partner.scheduledStartDateTime === null || partner.scheduledEndDateTime === null
                ? PartnerState.Draft
                : partner.scheduledStartDateTime <= now && partner.scheduledEndDateTime >= now
                ? PartnerState.Active
                : partner.scheduledEndDateTime < now
                ? PartnerState.Finished
                : PartnerState.Scheduled;

            return { ...acc, [state]: [...acc[state], partner] };
          },
          {
            [PartnerState.Draft]: [],
            [PartnerState.Scheduled]: [],
            [PartnerState.Active]: [],
            [PartnerState.Finished]: [],
          },
        ),
    );
  }

  get scheduledIntervals(): Interval[] {
    if (this.partners.state !== 'Fetched') return [];
    const intervals: Interval[] = [];
    this.partners.data?.forEach((partner) => {
      if (partner.scheduledStartDateTime !== null && partner.scheduledEndDateTime !== null) {
        intervals.push(
          Interval.fromDateTimes(
            // To ensure that scheduling is correct
            partner.scheduledStartDateTime.set({ hour: 0, minute: 0, second: 0 }),
            partner.scheduledEndDateTime.set({ hour: 23, minute: 59, second: 59 }),
          ),
        );
      }
    });
    return intervals;
  }

  constructor() {
    makeAutoObservable(this);
  }

  fetchPartnersIfNotFetched = () => {
    if (this.partners.state === 'NotFetched') {
      this.fetchPartners();
    }
  };

  setListingStateFilter = (state: PartnerState) => {
    this.listingFilters.state = state;
  };

  setSelectedPartnerRows = (partnerRows?: { original: PartnerTableRow }[]) => {
    this.selectedPartnerRows = (partnerRows ?? []).map(({ original }) => original.id);
  };

  fetchPartners: () => Promise<void> = flow(function* (this: PartnerStore) {
    yield fetchRemoteData(this, 'partners', async () => await partnerApi.getPartners());
  }).bind(this);

  fetchPartner: (id: PartnerId) => Promise<void> = flow(function* (this: PartnerStore, id: PartnerId) {
    yield fetchRemoteData(this, 'partner', () => partnerApi.getPartner(id));
  }).bind(this);

  createPartner: (values: PartnerFormValue) => Promise<PartnerId | undefined> = flow(function* (
    this: PartnerStore,
    values: PartnerFormValue,
  ) {
    const [request, images] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingPartner', () => partnerApi.createPartner(request, images));
    return result;
  }).bind(this);

  updatePartner: (id: PartnerId, values: PartnerFormValue) => Promise<void> = flow(function* (
    this: PartnerStore,
    id: PartnerId,
    values: PartnerFormValue,
  ) {
    const [request, images] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingPartner', () => partnerApi.updatePartner(id, request, images));
    this.partner = { state: 'Fetched', data: result };
  }).bind(this);

  deletePartner: (id: PartnerId) => Promise<void> = flow(function* (this: PartnerStore, id: PartnerId) {
    yield executeRequest(this, 'deletingPartner', () => partnerApi.deletePartner(id));
    this.partners = mapRemoteData(this.partners, (partners) => partners.filter((t) => t.id !== id));
  }).bind(this);

  copyPartners: (params: CopyPartnersParams) => Promise<void> = flow(function* (
    this: PartnerStore,
    params: CopyPartnersParams,
  ) {
    this.partners = { state: 'Fetching' };
    yield executeRequest(this, 'savingPartner', () => partnerApi.copyPartners(params));
    yield this.fetchPartners();
  }).bind(this);
}

function formValueToRequest(values: PartnerFormValue): [PartnerUpdateRequest, FileWithPreview[]] {
  const request: PartnerUpdateRequest = {
    title: values.title.trim(),
    shortDescription: values.shortDescription.trim(),
    longDescription: values.longDescription.trim(),
    link: values.link.trim(),
    linkText: values.linkText.trim(),
    image: values.image!.name,
    scheduledStartDateTime: values.submitType === 'draft' ? null : values.scheduledStartDateTime,
    scheduledEndDateTime: values.submitType === 'draft' ? null : values.scheduledEndDateTime,
    elements: collect((element: PartnerFormValueElement) => {
      return {
        text: element.text,
        image: element.image!.name,
      };
    })(values.elements),
  };

  const images = [
    ...(values.image && values.image.valueType === 'file' ? [values.image] : []),
    ...collect((element: PartnerFormValueElement) => {
      return element.image?.valueType === 'file' ? element.image : undefined;
    })(values.elements),
  ];

  return [request, images];
}
