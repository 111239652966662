import { flow, makeAutoObservable } from 'mobx';
import { notificationApi } from '../services/api';
import { NotificationUpdateRequest } from '../services/api/notification';
import {
  mapRemoteData,
  RemoteData,
  RequestState,
  Notification,
  NotificationFormValue,
  NotificationId,
  NotificationListItem,
  NotificationState,
  NotificationTarget,
} from '../types';
import { executeRequest, fetchRemoteData } from './storeUtils';

interface NotificationListingFilters {
  state: NotificationState;
  target: NotificationTarget | null;
  keyword: string | null;
}

export class NotificationStore {
  notifications: RemoteData<NotificationListItem[]> = { state: 'NotFetched' };
  listingFilters: NotificationListingFilters = { state: NotificationState.Published, target: null, keyword: null };

  notification: RemoteData<Notification> = { state: 'NotFetched' };
  savingNotification: RequestState = 'Initial';
  deletingNotification: RequestState = 'Initial';

  get notificationsByState(): RemoteData<Record<NotificationState, NotificationListItem[]>> {
    return mapRemoteData(
      this.notifications,
      (notifications): Record<NotificationState, NotificationListItem[]> =>
        notifications.reduce(
          (acc: Record<NotificationState, NotificationListItem[]>, notification: NotificationListItem) => {
            const state: NotificationState =
              notification.publishedDateTime !== null
                ? NotificationState.Published
                : notification.scheduledDateTime !== null
                ? NotificationState.Scheduled
                : NotificationState.Draft;

            return { ...acc, [state]: [...acc[state], notification] };
          },
          { [NotificationState.Draft]: [], [NotificationState.Scheduled]: [], [NotificationState.Published]: [] },
        ),
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  setListingKeywordFilter = (keyword: string | null) => {
    this.listingFilters.keyword = keyword;
  };

  setListingStateFilter = (state: NotificationState) => {
    this.listingFilters.state = state;
  };

  setListingTargetFilter = (target: NotificationTarget | null) => {
    this.listingFilters.target = target;
  };

  fetchNotifications: () => Promise<void> = flow(function* (this: NotificationStore) {
    yield fetchRemoteData(this, 'notifications', async () => await notificationApi.getNotifications());
  }).bind(this);

  fetchNotification: (id: NotificationId) => Promise<void> = flow(function* (
    this: NotificationStore,
    id: NotificationId,
  ) {
    yield fetchRemoteData(this, 'notification', () => notificationApi.getNotification(id));
  }).bind(this);

  createNotification: (values: NotificationFormValue) => Promise<NotificationId | undefined> = flow(function* (
    this: NotificationStore,
    values: NotificationFormValue,
  ) {
    const request = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingNotification', () => notificationApi.createNotification(request));
    return result;
  }).bind(this);

  updateNotification: (id: NotificationId, values: NotificationFormValue) => Promise<void> = flow(function* (
    this: NotificationStore,
    id: NotificationId,
    values: NotificationFormValue,
  ) {
    const request = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingNotification', () =>
      notificationApi.updateNotification(id, request),
    );
    this.notification = { state: 'Fetched', data: result };
  }).bind(this);

  deleteNotification: (id: NotificationId) => Promise<void> = flow(function* (
    this: NotificationStore,
    id: NotificationId,
  ) {
    yield executeRequest(this, 'deletingNotification', () => notificationApi.deleteNotification(id));
    this.notifications = mapRemoteData(this.notifications, (notifications) => notifications.filter((t) => t.id !== id));
  }).bind(this);
}

function formValueToRequest(values: NotificationFormValue): NotificationUpdateRequest {
  return {
    title: values.title.trim(),
    link: values.link,
    message: values.message.trim(),
    target: values.target,
    scheduledDateTime: values.submitType === 'draft' ? null : values.scheduledDateTime,
  };
}
