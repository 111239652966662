import { DateTime } from 'luxon';
import { ExistingImage, FileWithPreview } from '../components';

export const and = (...validators: any[]) => (value: any) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value: any) =>
  (typeof value === 'string' ? value.trim() : value) ? undefined : 'errors.validation.required';

export const maximumLength = (max: number) => (value: any) =>
  !value || value.length <= max ? undefined : 'errors.validation.tooLong';

const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/;
export const isEmail = (value: string) => (emailRegexp.test(value) ? undefined : 'errors.validation.email');

export const passwordLength = (value: string) =>
  value && value.length < 8 ? 'errors.validation.passwordLength' : undefined;

const digitOrSpecialCharRegexp = /(?=.*[\d!"#€%&/=§°',\-;:_<>@±£©()[\]{}*+?|^$.\\])/;
export const hasDigitOrSpecialChar = (value: string) =>
  digitOrSpecialCharRegexp.test(value) ? undefined : 'errors.validation.digitOrSpecialChar';

export const nullable = (validator: any) => (value: any) => (value === null ? undefined : validator(value));

export const dateTime = (value: any) =>
  !DateTime.isDateTime(value)
    ? 'errors.validation.invalidDateTime'
    : value.invalidReason
    ? 'errors.validation.invalidDateTime'
    : undefined;

export const dateTimeShouldBeInTheFuture = (value: any) =>
  !DateTime.isDateTime(value)
    ? 'errors.validation.invalidDateTime'
    : value.invalidReason
    ? 'errors.validation.invalidDateTime'
    : value < DateTime.local()
    ? 'errors.validation.shouldBeInTheFuture'
    : undefined;

export const dateTimeInTheFutureOrInitial = (initial: DateTime | null) => (value: any) => {
  if (!DateTime.isDateTime(value)) {
    return 'errors.validation.invalidDateTime';
  } else if (value.invalidReason) {
    return 'errors.validation.invalidDateTime';
  } else if (initial !== null && initial.equals(value)) {
    return undefined;
  } else if (value < DateTime.local()) {
    return 'errors.validation.shouldBeInTheFuture';
  }

  return undefined;
};

export const isLink = (value: string) =>
  value
    ? value.startsWith('http://') || value.startsWith('https://')
      ? undefined
      : 'errors.validation.link'
    : undefined;

export const dateShouldBeInTheFuture = (value: any) =>
  !DateTime.isDateTime(value)
    ? 'errors.validation.invalidDateTime'
    : value.invalidReason
    ? 'errors.validation.invalidDateTime'
    : value < DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    ? 'errors.validation.shouldBeInTheFuture'
    : undefined;

export const maximumFilesizeMb = (max: number) => (file: FileWithPreview | ExistingImage | null) => {
  if (!file || file.valueType === 'existing') return undefined;
  const filesizeMb = file.file.size / 1024 / 1024;
  if (filesizeMb > max) {
    return 'errors.validation.filesizeTooLarge';
  }
};
