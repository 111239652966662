import { FileWithPreview } from '../../components/FormControls';
import { CommentId, PollId, PopularComment, Settings, TipId } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getSettings = async (): Promise<Settings> => {
  const result = await ApiClient.get<Settings>(PATHS.settings.root);
  return result.data;
};

export interface UpdateFrontPageSettingsRequest {
  readonly frontPagePollId: PollId | null;
  readonly frontPageSeasonTipId: TipId | null;
  readonly frontPageSeasonIngredient: string | null;
  readonly frontPageRiddleName: string | null;
  readonly frontPageRiddleLink: string | null;
  readonly frontPageHighlightTitle: string | null;
  readonly frontPageHighlightText: string | null;
  readonly frontPageHighlightButton: string | null;
  readonly frontPageHighlightLink: string | null;
  readonly frontPageHighlightImage: string | null;
  readonly activeHolidayTagGroup: string | null;
  readonly ignorePopularComments: CommentId[];
  readonly newAppFeatures: string | null;
}

export const getPopularComments = async (skip: number, take: number): Promise<PopularComment[]> => {
  const result = await ApiClient.get<PopularComment[]>(PATHS.settings.popularComments, { params: { skip, take } });
  return result.data;
};

export const updateFrontPageSettings = async (
  request: UpdateFrontPageSettingsRequest,
  image: FileWithPreview | null,
): Promise<Settings> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  image && data.append('image', image.file);
  const result = await ApiClient.put<Settings>(PATHS.settings.frontPage, data);
  return result.data;
};
