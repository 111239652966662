import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { PollFormValue, ScreenAction } from '../../types';
import { PollForm } from './PollForm';

export const NewPollScreen: FC = observer(() => {
  const {
    pollStore: { createPoll },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: PollFormValue) => {
      return createPoll(values).then((pollId) => {
        if (pollId) {
          skipCheck(() => history.push(ROUTE.poll.link(pollId)));
        }
      });
    },
    [createPoll, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const initial: PollFormValue = {
    title: '',
    infoText: '',
    link: '',
    options: [null, null, null, null],
  };

  return (
    <Layout allowOverflow title={t('poll.addNew')} screenActions={actions}>
      <PollForm initial={initial} onSubmit={onSubmit} />
    </Layout>
  );
});
