import React from 'react';
import { ExistingImage, FileWithPreview, Layout } from '../../components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { ApiClient } from '../../services/client';
import { TagGroupWithTags } from './EditTagGroupsScreen';
import { CircularProgress, Divider } from '@material-ui/core';
import { HolidayTagGroupKeyEnum } from '../../constants/holidayThemes';
import { TagGroupForm } from './TagGroupForm';
import { ITag, TagForm } from './TagForm';
import { MapOrEntries } from 'usehooks-ts';

export type HolidayTags = { key: HolidayTagGroupKeyEnum; id: number; text: string };

// id has no meaning, it's just to make unique keys for the dropdown
export const holidayOptions: HolidayTags[] = [
  {
    id: 100,
    key: 'christmas',
    text: 'Joulu',
  },
  {
    id: 200,
    key: 'spring',
    text: 'Kevät',
  },
  {
    id: 300,
    key: 'summer',
    text: 'Kesä',
  },
  {
    id: 400,
    key: 'mayday',
    text: 'Vappu',
  },
  {
    id: 500,
    key: 'laskiainen',
    text: 'Laskiainen',
  },
  {
    id: 600,
    key: 'mothersday',
    text: 'Äitienpäivä',
  },
  {
    id: 700,
    key: 'fathersday',
    text: 'Isänpäivä',
  },
  {
    id: 800,
    key: 'midsummer',
    text: 'Juhannus',
  },
  {
    id: 900,
    key: 'independenceday',
    text: 'Itsenäisyyspäivä',
  },
  {
    id: 1000,
    key: 'harvest',
    text: 'Sadonkorjuu',
  },
  {
    id: 1100,
    key: 'forest',
    text: 'Metsä',
  }
] as HolidayTags[];
const groupFetcher = async (id: string) => {
  const response = await ApiClient.get<TagGroupWithTags>(`/admin/tagGroups/v1/group/${id}`);
  return response.data;
};

const EditTagGroupScreen = () => {
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();

  const query = useQuery({
    queryKey: ['tagGroup', params.id],
    enabled: !!params.id,
    queryFn: () => groupFetcher(params.id),
  });

  const tagit = query.data?.tags?.map((tagi) => {
    const image: ExistingImage = {
      name: tagi.name,
      url: ((tagi.image as unknown) as string) || '',
      valueType: 'existing',
    };

    const t: ITag = {
      id: tagi.id,
      name: tagi.name,
      image: (image as unknown) as FileWithPreview,
      active: tagi.active,
      groupId: +params.id,
      sfInterest: tagi.sfInterest,
      scheduledDateTime: tagi.scheduledDateTime,
    };

    return [String(tagi.id), t];
  }) as MapOrEntries<string, ITag>;

  return (
    <Layout backButton allowOverflow title={t('editTagGroup.title')} screenActions={[]}>
      {query.isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div>
      )}
      {query.isSuccess && (
        <div>
          <TagGroupForm create={false} body={query.data} id={params.id} />

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <h1>Alakategoriat</h1>

          <TagForm tagGroupId={params.id} tags={tagit} />
        </div>
      )}
    </Layout>
  );
};

export { EditTagGroupScreen };
