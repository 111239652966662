import { Box, styled, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../styles';
import { ScreenAction, Route } from '../../types';
import { Button } from '../FormControls';
import { ArrowBack } from '@material-ui/icons';
import { history } from '../../App';

interface ScreenHeaderProps {
  title?: string;
  route: Route;
  screenActions?: Array<ScreenAction>;
  backButton?: boolean;
}

const HeaderBox = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '50px 50px 0px 40px',
  paddingBottom: '40px',
  borderBottom: `1px solid ${COLORS.grey}`,
});

const ActionButton: FC<ScreenAction> = ({ label, icon, handler, variant = 'outlined' }) => (
  <Button label={label} iconRight={icon} onClick={handler} variant={variant} />
);

export const ScreenHeader: FC<ScreenHeaderProps> = ({ title, route, screenActions, backButton = false }) => {
  const { t } = useTranslation();

  const titleText = title ? title : t(`screens.${route.name}`);

  return (
    <HeaderBox>
      <span style={{ display: 'flex', gap: '10px' }}>
        {backButton && <ActionButton label={''} variant="text" icon={ArrowBack} handler={history.goBack} />}
        <Typography variant="h1">{titleText}</Typography>
      </span>
      <Box display="flex">
        {screenActions && (
          <>
            {screenActions.map((action, index) => (
              <Box key={action.label} mr={index !== screenActions.length && 2}>
                <ActionButton key={action.label} {...action} />
              </Box>
            ))}
          </>
        )}
      </Box>
    </HeaderBox>
  );
};
