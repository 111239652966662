import { FileWithPreview } from '../../components/FormControls';
import { Question, QuestionTagInfoRequest, Tag, TagId } from '../../types';
import { ApiClient } from '../client';
import { PATHS } from './paths';

export const getQuestionTag = async (id: TagId): Promise<Tag> => {
  const result = await ApiClient.get<Tag>(PATHS.tags.getSingle(id));
  return result.data;
};

export interface QuestionTagUpdateRequest {
  readonly name: string;
  readonly image: string;
}

export const createQuestionTag = async (request: QuestionTagUpdateRequest, image: FileWithPreview): Promise<TagId> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  data.append('image', image.file);

  const result = await ApiClient.post<{ id: number }>(PATHS.tags.root, data);
  return result.data.id;
};

export const updateQuestionTag = async (
  id: TagId,
  request: QuestionTagUpdateRequest,
  image: FileWithPreview | null,
): Promise<Tag> => {
  const data = new FormData();
  data.append('request', JSON.stringify(request));
  if (image) data.append('image', image.file);

  const result = await ApiClient.put<Tag>(PATHS.tags.single(id), data);

  return result.data;
};

export const updateTagInfo = async (id: TagId, data: QuestionTagInfoRequest): Promise<number> => {
  const res = await ApiClient.put<number>(PATHS.tags.info(id), data);
  return res.data;
};

export const toggleQuestionTagState = async (id: TagId, deactivate: boolean): Promise<number> => {
  const res = await ApiClient.put<number>(PATHS.tags.single(id), { deactivate });
  return res.data;
};

export const getQuestions = async (): Promise<Question[]> => {
  const res = await ApiClient.get<Question[]>(PATHS.questions.root);
  return res.data;
};
