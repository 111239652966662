import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { membershipBenefitApi } from '../services/api';
import { MembershipBenefitUpdateRequest } from '../services/api/membershipBenefit';
import {
  mapRemoteData,
  RemoteData,
  RequestState,
  MembershipBenefit,
  MembershipBenefitFormValue,
  MembershipBenefitId,
  MembershipBenefitRole,
  MembershipBenefitState,
  MembershipBenefitTableRow,
} from '../types';
import { executeRequest, fetchRemoteData } from './storeUtils';

interface MembershipBenefitListingFilters {
  role: MembershipBenefitRole | null;
}

export class MembershipBenefitStore {
  membershipBenefits: RemoteData<MembershipBenefitTableRow[]> = { state: 'NotFetched' };
  listingFilters: MembershipBenefitListingFilters = { role: null };
  stateFilter: MembershipBenefitState = MembershipBenefitState.Active;

  membershipBenefit: RemoteData<MembershipBenefit> = { state: 'NotFetched' };
  savingMembershipBenefit: RequestState = 'Initial';
  deletingMembershipBenefit: RequestState = 'Initial';

  constructor() {
    makeAutoObservable(this);
  }

  get membershipBenefitsByState(): RemoteData<Record<MembershipBenefitState, MembershipBenefitTableRow[]>> {
    return mapRemoteData(
      this.membershipBenefits,
      (membershipBenefits): Record<MembershipBenefitState, MembershipBenefitTableRow[]> =>
        membershipBenefits.reduce(
          (
            acc: Record<MembershipBenefitState, MembershipBenefitTableRow[]>,
            membershipBenefit: MembershipBenefitTableRow,
          ) => {
            const state: MembershipBenefitState = membershipBenefit.active
              ? MembershipBenefitState.Active
              : MembershipBenefitState.Inactive;
            return { ...acc, [state]: [...acc[state], membershipBenefit] };
          },
          { [MembershipBenefitState.Active]: [], [MembershipBenefitState.Inactive]: [] },
        ),
    );
  }

  setListingRoleFilter = (role: MembershipBenefitRole) => {
    this.listingFilters.role = role;
  };

  setStateFilter = (state: MembershipBenefitState) => (this.stateFilter = state);

  fetchMembershipBenefits: () => Promise<void> = flow(function* (this: MembershipBenefitStore) {
    yield fetchRemoteData(this, 'membershipBenefits', async () => await membershipBenefitApi.getMembershipBenefits());
  }).bind(this);

  fetchMembershipBenefit: (id: MembershipBenefitId) => Promise<void> = flow(function* (
    this: MembershipBenefitStore,
    id: MembershipBenefitId,
  ) {
    yield fetchRemoteData(this, 'membershipBenefit', () => membershipBenefitApi.getMembershipBenefit(id));
  }).bind(this);

  createMembershipBenefit: (values: MembershipBenefitFormValue) => Promise<MembershipBenefitId | undefined> = flow(
    function* (this: MembershipBenefitStore, values: MembershipBenefitFormValue) {
      const [request, image] = formValueToRequest(values);
      const result = yield executeRequest(this, 'savingMembershipBenefit', () =>
        membershipBenefitApi.createMembershipBenefit(request, image!),
      );
      return result;
    },
  ).bind(this);

  updateMembershipBenefit: (id: MembershipBenefitId, values: MembershipBenefitFormValue) => Promise<void> = flow(
    function* (this: MembershipBenefitStore, id: MembershipBenefitId, values: MembershipBenefitFormValue) {
      const [request, image] = formValueToRequest(values);
      const result = yield executeRequest(this, 'savingMembershipBenefit', () =>
        membershipBenefitApi.updateMembershipBenefit(id, request, image),
      );
      this.membershipBenefit = { state: 'Fetched', data: result };
    },
  ).bind(this);

  deleteMembershipBenefit: (id: MembershipBenefitId) => Promise<void> = flow(function* (
    this: MembershipBenefitStore,
    id: MembershipBenefitId,
  ) {
    yield executeRequest(this, 'deletingMembershipBenefit', () => membershipBenefitApi.deleteMembershipBenefit(id));
    this.membershipBenefits = mapRemoteData(this.membershipBenefits, (membershipBenefits) =>
      membershipBenefits.filter((t) => t.id !== id),
    );
  }).bind(this);
}

function formValueToRequest({
  image,
  ...values
}: MembershipBenefitFormValue): [MembershipBenefitUpdateRequest, FileWithPreview | null] {
  const request = {
    text: values.text.trim(),
    link: values.link.trim(),
    buttonText: values.buttonText.trim(),
    active: values.active,
    role: values.role,
    image: image!.name,
  };
  const requestImage = image && image.valueType === 'file' ? image : null;
  return [request, requestImage];
}
