import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, CircularProgress, styled } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow } from '../../components/FormControls';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { MembershipBenefitState, MembershipBenefitTableRow, MaterialTableCell, ScreenAction } from '../../types';

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const MembershipBenefitsScreen: FC = observer(() => {
  const {
    membershipBenefitStore: {
      membershipBenefits,
      membershipBenefitsByState,
      deleteMembershipBenefit,
      fetchMembershipBenefits,
      stateFilter,
      setStateFilter,
    },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [toDelete, setToDelete] = useState<MembershipBenefitTableRow | null>(null);

  useEffect(() => {
    fetchMembershipBenefits();
  }, [fetchMembershipBenefits]);

  const fetching = membershipBenefits.state === 'Fetching';

  const data: MembershipBenefitTableRow[] = useMemo(() => {
    return membershipBenefitsByState.state !== 'Fetched' ? [] : membershipBenefitsByState.data[stateFilter];
  }, [membershipBenefitsByState, stateFilter]);

  const actions: ScreenAction[] = [
    {
      label: t('membershipBenefit.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newMembershipBenefit.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'text',
        Header: t('membershipBenefit.list.text'),
      },
      {
        accessor: 'role',
        Header: t('membershipBenefit.list.role'),
        Cell: ({ cell: { value } }: MaterialTableCell<MembershipBenefitTableRow, 'role'>) => {
          return t(`membershipBenefit.filter.${value}`);
        },
      },
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<MembershipBenefitTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setToDelete(original)} />
              <IconButton
                size={40}
                icon={Edit}
                onClick={() => history.push(ROUTE.membershipBenefit.link(original.id))}
              />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t],
  );

  const deleteModal =
    toDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('membershipBenefit.list.deleteMessage')}
        message={toDelete.text}
        onAccept={() => {
          setToDelete(null);
          deleteMembershipBenefit(toDelete.id);
        }}
        onCancel={() => setToDelete(null)}
      />
    );

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}
      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <Tabs value={stateFilter} onChange={(ev, value) => setStateFilter(value)}>
            <Tab label={t('membershipBenefit.list.active')} value={MembershipBenefitState.Active} />
            <Tab label={t('membershipBenefit.list.inactive')} value={MembershipBenefitState.Inactive} />
          </Tabs>
          <TableContainer>
            <MaterialTable
              data={data}
              columns={columns}
              onPageChange={() => {}}
              pagination={null}
              onSortingChange={() => {}}
              initialSortBy={[{ id: 'createdAt', desc: false }]}
            />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});
