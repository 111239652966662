export type AccessToken = string;

export enum AdminRole {
  Manager = 'manager',
  Editor = 'editor',
}

export type UserId = number;

export type AccessTokenData = {
  userId: string;
  role: AdminRole;
};
