import { convertToHTML } from 'draft-convert';
import { flow, makeAutoObservable } from 'mobx';
import { FileWithPreview } from '../components/FormControls';
import { tipApi } from '../services/api';
import { TipUpdateRequest } from '../services/api/tip';
import {
  CopyToTipsParams,
  mapRemoteData,
  MassTipUpdateValues,
  RemoteData,
  RequestState,
  Tag,
  TagGroup,
  Tip,
  TipElementType,
  TipFormValue,
  TipFormValueElement,
  TipId,
  TipListItem,
  TipReminderFrequency,
  TipSeasonKey,
  TipState,
  TipTableRow,
} from '../types';
import { collect } from '../utils';
import { executeRequest, fetchRemoteData } from './storeUtils';
import { DateTime } from 'luxon';

interface TipListingFilters {
  state: TipState;
  group: TagGroup | null;
  keyword: string | null;
  tag: Tag | null;
  season: TipSeasonKey | null;
}

export class TipStore {
  tips: RemoteData<TipListItem[]> = { state: 'NotFetched' };
  listingFilters: TipListingFilters = { state: TipState.Published, group: null, tag: null, season: null, keyword: null };
  selectedTipRows: TipId[] = [];

  tip: RemoteData<Tip> = { state: 'NotFetched' };
  savingTip: RequestState = 'Initial';
  deletingTip: RequestState = 'Initial';

  get tipsByState(): RemoteData<Record<TipState, TipListItem[]>> {
    return mapRemoteData(
      this.tips,
      (tips): Record<TipState, TipListItem[]> =>
        tips.reduce(
          (acc: Record<TipState, TipListItem[]>, tip: TipListItem) => {
            const state: TipState =
              tip.publishedDateTime !== null
                ? TipState.Published
                : tip.scheduledDateTime !== null
                ? TipState.Scheduled
                : TipState.Draft;

            return { ...acc, [state]: [...acc[state], tip] };
          },
          { [TipState.Draft]: [], [TipState.Scheduled]: [], [TipState.Published]: [] },
        ),
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  setListingStateFilter = (state: TipState) => {
    this.listingFilters.state = state;
  };

  setKeywordFilter = (keyword: string | null) => {
    this.listingFilters.keyword = keyword;
  }

  setListingGroupFilter = (group: TagGroup | null) => {
    this.listingFilters.group = group;
    this.listingFilters.tag = null;
  };

  setListingTagFilter = (tag: Tag | null) => {
    this.listingFilters.tag = tag;
  };

  setListingSeasonFilter = (season: TipSeasonKey | null) => {
    this.listingFilters.season = season;
  };

  setSelectedTipRows = (tipRows?: { original: TipTableRow }[]) => {
    this.selectedTipRows = (tipRows ?? []).map(({ original }) => original.id);
  };

  fetchTips: () => Promise<void> = flow(function* (this: TipStore) {
    yield fetchRemoteData(this, 'tips', async () => await tipApi.getTips());
  }).bind(this);

  fetchTip: (id: TipId) => Promise<void> = flow(function* (this: TipStore, id: TipId) {
    yield fetchRemoteData(this, 'tip', () => tipApi.getTip(id));
  }).bind(this);

  createTip: (values: TipFormValue, isHolidayTip?: boolean) => Promise<TipId | undefined> = flow(function* (
    this: TipStore,
    values: TipFormValue,
    isHolidayTip: boolean = false,
  ) {
    const [request, images] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingTip', () => tipApi.createTip(request, images, isHolidayTip));
    return result;
  }).bind(this);

  updateTip: (id: TipId, values: TipFormValue, isHolidayTip?: boolean) => Promise<void> = flow(function* (
    this: TipStore,
    id: TipId,
    values: TipFormValue,
    isHolidayTip: boolean = false,
  ) {
    const [request, images] = formValueToRequest(values);
    const result = yield executeRequest(this, 'savingTip', () => tipApi.updateTip(id, request, images, isHolidayTip));
    this.tip = { state: 'Fetched', data: result };
  }).bind(this);

  deleteTip: (id: TipId) => Promise<void> = flow(function* (this: TipStore, id: TipId) {
    yield executeRequest(this, 'deletingTip', () => tipApi.deleteTip(id));
    this.tips = mapRemoteData(this.tips, (tips) => tips.filter((t) => t.id !== id));
  }).bind(this);

  massUpdateTips: (params: MassTipUpdateValues) => Promise<void> = flow(function* (
    this: TipStore,
    params: MassTipUpdateValues,
  ) {
    this.tips = { state: 'Fetching' };
    yield executeRequest(this, 'savingTip', () => tipApi.massUpdateTips(params));
    yield this.fetchTips();
  }).bind(this);

  copyToTips: (params: CopyToTipsParams) => Promise<void> = flow(function* (this: TipStore, params: CopyToTipsParams) {
    this.tips = { state: 'Fetching' };
    yield executeRequest(this, 'savingTip', () => tipApi.copyToTips(params));
    yield this.fetchTips();
  }).bind(this);
}

function formValueToRequest(values: TipFormValue): [TipUpdateRequest, FileWithPreview[]] {
  const request: TipUpdateRequest = {
    title: values.title.trim(),
    image: values.image!.name,
    primaryTagId: values.primaryTag!.id,
    secondaryTagIds: [
      ...values.secondaryTags.garden,
      ...values.secondaryTags.home,
      ...values.secondaryTags.ingredient,
      ...values.secondaryTags.crafts,
    ].map((t) => t.id),
    scheduledDateTime: values.submitType === 'draft' ? null : values.scheduledDateTime,
    didYouKnow: {
      text: values.didYouKnow.text && values.didYouKnow.text.trim().length > 0 ? values.didYouKnow.text.trim() : null,
      value:
        values.didYouKnow.value && values.didYouKnow.value.trim().length > 0 ? values.didYouKnow.value.trim() : null,
    },
    season: {
      key: values.season.key,
      title: values.season.title,
      text: values.season.text,
      image: values.season.image?.name ?? null,
    },
    reminder: {
      enabled: values.reminder.enabled,
      title: values.reminder.title && values.reminder.title.trim().length > 0 ? values.reminder.title.trim() : null,
      text: values.reminder.text && values.reminder.text.trim().length > 0 ? values.reminder.text.trim() : null,
      frequency: values.reminder?.frequency ?? TipReminderFrequency.OnceAWeek,
      defaultDateTime: values.reminder?.defaultDateTime?.toISO() ?? DateTime.local().toISO(),
    },
    elements: collect((element: TipFormValueElement) => {
      switch (element.type) {
        case TipElementType.Image:
          return {
            type: element.type,
            image: element.image!.name,
          };
        case TipElementType.Text:
          const html = htmlConvert(element.content!.getCurrentContent());
          return { type: element.type, content: html };
        case TipElementType.Youtube:
          return { type: element.type, videoId: element.videoId };
        default:
          return undefined;
      }
    })(values.elements),
    keywords: values.keywords ?? null,
  };

  const images = [
    ...(values.image && values.image.valueType === 'file' ? [values.image] : []),
    ...(values.season.image && values.season.image.valueType === 'file' ? [values.season.image] : []),
    ...collect((element: TipFormValueElement) => {
      if (element.type !== TipElementType.Image || element.image === null) {
        return undefined;
      }

      return element.image.valueType === 'file' ? element.image : undefined;
    })(values.elements),
  ];

  return [request, images];
}

const htmlConvert = convertToHTML({
  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return `<a href=${entity.data.url}>${originalText}</a>`;
    }
    return originalText;
  },
});
