import React, { FC, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, Grid, CircularProgress } from '@material-ui/core';
import styled from '@material-ui/core/styles/styled';
import { Add, Delete, Edit } from '@material-ui/icons';
import { flow } from 'lodash/fp';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ConfirmDialog } from '../../components/Dialog/ConfirmDialog';
import { IconButton, IconButtonRow, SearchField } from '../../components/FormControls';
import { ComboBox } from '../../components/FormControls/ComboBox';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { ScreenAction, NotificationId, NotificationListItem, NotificationState, NotificationTarget } from '../../types';
import { collect } from '../../utils';

interface MaterialTableCell<T, K extends keyof T | undefined = undefined> {
  cell: {
    value: K extends keyof T ? T[K] : undefined;
  };
  row: {
    original: T;
  };
}

interface NotificationTableRow {
  id: NotificationId;
  title: string;
  target: NotificationTarget;
  publishedDateTime: DateTime | null;
  scheduledDateTime: DateTime | null;
}

const ControlsGrid = styled(Grid)({
  marginBottom: '35px',
  '& > :not(:last-child)': {
    marginRight: '10px',
  },
});

const TableContainer = styled('div')({
  marginBottom: '40px',
});

export const NotificationsScreen: FC = observer(() => {
  const {
    notificationStore: {
      notificationsByState,
      listingFilters: { state: stateFilter, target: targetFilter, keyword: keywordFilter },
      setListingStateFilter,
      setListingTargetFilter,
      fetchNotifications,
      deleteNotification,
      setListingKeywordFilter,
    },
  } = useStores();

  const { t } = useTranslation();
  const history = useHistory();
  const [notificationToDelete, setNotificationToDelete] = useState<NotificationTableRow | null>(null);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const fetching = notificationsByState.state === 'Fetching';

  const onKeywordChange = (keyword: string) => {
    setListingKeywordFilter(keyword);
  };

  const data: NotificationTableRow[] = useMemo(() => {
    return notificationsByState.state !== 'Fetched'
      ? []
      : flow(
          collect((notification: NotificationListItem): NotificationTableRow | undefined => {
            if (targetFilter !== null && notification.target !== targetFilter) {
              return undefined;
            } else if (keywordFilter && !notification.title.toLowerCase().includes(keywordFilter.toLowerCase())) {
              return undefined;
            }
            return notification;
          }),
        )(notificationsByState.data[stateFilter]);
  }, [notificationsByState, stateFilter, targetFilter, keywordFilter]);

  const actions: ScreenAction[] = [
    {
      label: t('notification.list.addNew'),
      icon: Add,
      handler: () => history.push(ROUTE.newNotification.link()),
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        Header: t('notification.title'),
      },
      ...(stateFilter === NotificationState.Scheduled
        ? [
            {
              accessor: 'scheduledDateTime',
              Header: t('notification.list.scheduledDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<NotificationTableRow, 'scheduledDateTime'>) => {
                return value ? value.toFormat("d.L.yyyy 'klo' H:mm") : '';
              },
            },
          ]
        : []),
      ...(stateFilter === NotificationState.Published
        ? [
            {
              accessor: 'publishedDateTime',
              Header: t('notification.list.publishedDateTime'),
              Cell: ({ cell: { value } }: MaterialTableCell<NotificationTableRow, 'publishedDateTime'>) => {
                return value ? value.toFormat("d.L.yyyy 'klo' H:mm") : '';
              },
            },
          ]
        : []),
      {
        accessor: 'editedBy',
        Header: t('notification.sender'),
        width: '15%',
      },
      {
        accessor: 'target',
        Header: t('notification.target'),
        Cell: ({ cell: { value } }: MaterialTableCell<NotificationTableRow, 'target'>) => {
          return t(`notification.filter.${value}`);
        },
        width: '15%',
      },
      {
        accessor: 'tools',
        className: 'cellBorderLeft',
        width: '12%',
        Cell: ({ row: { original } }: MaterialTableCell<NotificationTableRow>) => {
          return (
            <IconButtonRow>
              <IconButton size={40} icon={Delete} onClick={() => setNotificationToDelete(original)} />
              <IconButton size={40} icon={Edit} onClick={() => history.push(ROUTE.notification.link(original.id))} />
            </IconButtonRow>
          );
        },
      },
    ],
    [history, t, stateFilter],
  );

  const deleteModal =
    notificationToDelete === null ? null : (
      <ConfirmDialog
        open
        title={t('notification.list.deleteMessage')}
        message={notificationToDelete.title}
        onAccept={() => {
          setNotificationToDelete(null);
          deleteNotification(notificationToDelete.id);
        }}
        onCancel={() => setNotificationToDelete(null)}
      />
    );

  const targetOptions = Object.keys(NotificationTarget).map((it) => it.toLowerCase());

  return (
    <Layout allowOverflow screenActions={actions}>
      {deleteModal}

      {fetching ? (
        <CircularProgress size="3rem" />
      ) : (
        <>
          <ControlsGrid container>
            <Grid item xs={3}>
              <SearchField onChange={onKeywordChange} />
            </Grid>
            <Grid item xs={3}>
              <ComboBox
                placeholder={t('notification.target')}
                value={targetFilter}
                narrow
                options={targetOptions}
                getOptionLabel={(g) => t(`notification.filter.${g.toLowerCase()}`)}
                onChange={(value) => {
                  setListingTargetFilter((value as NotificationTarget) ?? null);
                }}
              />
            </Grid>
          </ControlsGrid>
          <Tabs value={stateFilter} onChange={(ev, value) => setListingStateFilter(value)}>
            <Tab label={t('notification.list.published')} value={NotificationState.Published} />
            <Tab label={t('notification.list.scheduled')} value={NotificationState.Scheduled} />
            <Tab label={t('notification.list.drafts')} value={NotificationState.Draft} />
          </Tabs>
          <TableContainer>
            <MaterialTable
              data={data}
              columns={columns}
              onPageChange={() => {}}
              pagination={null}
              onSortingChange={() => {}}
              initialSortBy={[
                { id: stateFilter === NotificationState.Scheduled ? 'scheduledDateTime' : 'createdAt', desc: false },
              ]}
            />
          </TableContainer>
        </>
      )}
    </Layout>
  );
});
