import { Box, Grid, styled } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { COLORS } from '../../styles';

const Aside = styled(Box)({
  height: '100vh',
  backgroundColor: COLORS.primary,
});

export const UnauthLayout: FC = observer(({ children }) => {
  return (
    <Grid container>
      <Grid item sm={6}>
        {children}
      </Grid>
      <Grid item sm={6}>
        <Aside></Aside>
      </Grid>
    </Grid>
  );
});
