import { Box, makeStyles, styled, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MENU_ROUTES } from '../../constants';
import { COLORS } from '../../styles';
import { AdminRole, Route } from '../../types';

interface SideMenuItemProps {
  route: Route;
  onClick: (href: Route['href']) => void;
}

const ListItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: COLORS.white,
});

const SideMenuList = styled('ul')({
  width: '100%',
  margin: '0',
  padding: '0',
});

const useStyles = makeStyles({
  iconContainer: {
    backgroundColor: COLORS.secondary,
    color: COLORS.black,
    borderRadius: '4px',
    height: '35px',
    width: '35px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    '& > svg': {
      fontSize: '1.1rem',
    },
  },
  sideMenuListItem: {
    listStyle: 'none',
    cursor: 'pointer',
    padding: '20px 40px',
    borderBottom: `1px solid ${COLORS.white}`,
    backgroundColor: COLORS.primary,
    '&:hover': {
      backgroundColor: COLORS.primaryLighter,
    },
  },
});

const SideMenuItem: FC<SideMenuItemProps> = ({ route, onClick }) => {
  const Icon = route.icon;
  const { t } = useTranslation();

  const classes = useStyles();

  const onListItemClick = () => {
    onClick(route.href);
  };

  return (
    <li className={classes.sideMenuListItem} onClick={onListItemClick}>
      <ListItemContainer>
        <Box className={classes.iconContainer} mr="20px">
          {Icon ? <Icon /> : null}
        </Box>
        <Typography variant="button">{t(`screens.${route.name}`)}</Typography>
      </ListItemContainer>
    </li>
  );
};

interface SideMenuProps {
  role: AdminRole;
}

export const SideMenu: FC<SideMenuProps> = ({ role }) => {
  let history = useHistory();

  const onNavItemClick = (href: Route['href']) => {
    history.push(href);
  };

  return (
    <SideMenuList>
      {MENU_ROUTES(role).map((route) => (
        <SideMenuItem key={route.href} route={route} onClick={onNavItemClick} />
      ))}
    </SideMenuList>
  );
};
