import { ExistingImage, FileWithPreview } from '../components';

export type AdId = number;
export type ContentTypeId = number;

export enum AdRole {
  Users = 'users',
  Members = 'members',
  All = 'all',
}

export enum AdState {
  Active = 'active',
  Inactive = 'inactive',
}

export type ContentType = {
  readonly id: ContentTypeId;
  readonly key: string;
  readonly name: string;
};

export type Ad = {
  readonly id: AdId;
  readonly text: string;
  readonly image: string;
  readonly imageUrl: string;
  readonly link: string;
  readonly tagText: string;
  readonly active: boolean;
  readonly role: AdRole;
  readonly contentTypeIds: ContentTypeId[];
};

export type AdTableRow = {
  readonly id: AdId;
  readonly text: string;
  readonly active: boolean;
  readonly role: AdRole;
  readonly contentTypeIds: ContentTypeId[];
};

export type AdFormValue = {
  readonly text: string;
  readonly link: string;
  readonly tagText: string;
  readonly active: boolean;
  readonly role: AdRole;
  readonly contentTypeIds: number[];
  readonly image: FileWithPreview | ExistingImage | null;
};
