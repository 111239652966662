import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { FC, ReactNode } from 'react';
import { COLORS } from '../../styles';
import styled from '@material-ui/core/styles/styled';

export interface FormBoxProps {
  title?: string;
  children: ReactNode | ReactNode[];
  flexDirection?: 'row' | 'column';
  flexSpacing?: string;
}

const useStyles = makeStyles({
  formBoxWrapper: { display: 'flex', flexDirection: 'column' },
  formBoxTitle: {
    marginBottom: '10px',
  },
  formBoxContainer: ({ flexDirection, flexSpacing }: { flexDirection?: 'row' | 'column'; flexSpacing?: string }) => ({
    backgroundColor: COLORS.boxBackgroundGrey,
    borderRadius: '4px',
    ...(flexDirection && ['row', 'column'].includes(flexDirection)
      ? {
          display: 'flex',
          flexDirection: flexDirection,
          ...(flexSpacing
            ? {
                '& > :not(:last-child)':
                  flexDirection === 'row' ? { marginRight: flexSpacing } : { marginBottom: flexSpacing },
              }
            : undefined),
        }
      : {}),
  }),
});

export const FormBox: FC<FormBoxProps> = ({ title, children, flexDirection, flexSpacing }) => {
  const classes = useStyles({ flexDirection, flexSpacing });
  return (
    <div className={`formBox ${classes.formBoxWrapper}`}>
      {title ? (
        <Typography className={classes.formBoxTitle} variant="h2">
          {title}
        </Typography>
      ) : null}
      <Box p={8} className={classes.formBoxContainer}>
        {children}
      </Box>
    </div>
  );
};

export const FormBoxGroup = styled('div')({
  '& > :not(:first-child)': {
    marginTop: '40px',
  },
});
