import { RemoteData, RequestState } from '../types';

export async function executeRequest<T, S, K extends keyof S>(
  store: Record<K, RequestState>,
  stateField: K,
  requestFn: () => Promise<T>,
): Promise<T | undefined> {
  try {
    store[stateField] = 'Executing';
    const result = await requestFn();
    store[stateField] = 'Complete';
    return result;
  } catch (error) {
    store[stateField] = 'Error';
    return undefined;
  }
}

export async function fetchRemoteData<T, S, K extends keyof S>(
  store: Record<K, RemoteData<T>>,
  field: K,
  requestFn: () => Promise<T>,
): Promise<void> {
  try {
    store[field] = { state: 'Fetching' };
    const data = await requestFn();
    store[field] = { state: 'Fetched', data };
  } catch (error) {
    store[field] = { state: 'ErrorFetching' };
  }
}
