import React, { FC, useCallback, useEffect } from 'react';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { Layout } from '../../components/Layout';
import { ROUTE } from '../../constants';
import { useStores } from '../../stores';
import { ScreenAction, PartnerFormValue } from '../../types';
import { PartnerForm } from './PartnerForm';
import { getFirstAvailableDateTime } from '../../utils';

export const NewPartnerScreen: FC = observer(() => {
  const {
    partnerStore: { createPartner, scheduledIntervals, fetchPartnersIfNotFetched },
    unsavedWarningStore: { skipCheck },
  } = useStores();

  useEffect(() => {
    fetchPartnersIfNotFetched();
  }, [fetchPartnersIfNotFetched]);

  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = useCallback(
    (values: PartnerFormValue) => {
      return createPartner(values).then((partnerId) => {
        if (partnerId) {
          skipCheck(() => history.push(ROUTE.partner.link(partnerId)));
        }
      });
    },
    [createPartner, history, skipCheck],
  );

  const actions: ScreenAction[] = [];

  const firstAvailableDateTime = getFirstAvailableDateTime(
    DateTime.local().set({ hour: 0, minute: 0, second: 0 }),
    scheduledIntervals,
  );

  const initial: PartnerFormValue = {
    submitType: 'draft',
    title: '',
    shortDescription: '',
    image: null,
    elements: [{ text: '', image: null }],
    longDescription: '',
    link: '',
    linkText: '',
    scheduledStartDateTime: firstAvailableDateTime,
    scheduledEndDateTime: firstAvailableDateTime.set({ hour: 23, minute: 59, second: 59 }),
  };

  return (
    <Layout allowOverflow title={t('partner.addNew')} screenActions={actions}>
      <PartnerForm initial={initial} onSubmit={onSubmit} invalidIntervals={scheduledIntervals} />
    </Layout>
  );
});
