import { AccessToken } from '../types';

const ACCESS_TOKEN_ITEM_NAME = 'authToken';

export const storeAccessToken = (token: AccessToken): void => {
  sessionStorage.setItem(ACCESS_TOKEN_ITEM_NAME, token);
};

export const readAccessToken = (): AccessToken | null => {
  return sessionStorage.getItem(ACCESS_TOKEN_ITEM_NAME);
};

export const deleteAccessToken = () => {
  return sessionStorage.removeItem(ACCESS_TOKEN_ITEM_NAME);
};
