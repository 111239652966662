/* eslint-disable react/prop-types */
import React from 'react';

import { TablePagination } from '@material-ui/core';

const MaterialTablePagination = ({ gotoPage, totalCount, pageSize, page, setPageSize }) => {
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };

  const handlePageChange = (e, pageIndex) => {
    gotoPage(pageIndex);
  };

  return (
    <TablePagination
      component="div"
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePageSizeChange}
      page={page}
      rowsPerPage={pageSize}
      count={totalCount}
    />
  );
};

export default MaterialTablePagination;
