import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useStores } from '../stores';
import { UnauthLayout } from '../components/Layout';
import { Box, styled, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/FormControls';
import { useQuery } from '../utils';

const FormContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '400px',
  margin: 'auto',
  height: '100vh',
  justifyContent: 'center',
  '& h2': {
    margiBottom: '3rem',
    textAlign: 'center',
  },
  '& button': {
    marginTop: '1rem',
    width: '100%',
  },
});

export const LoginScreen: FC = observer(() => {
  const {
    authStore: { isAuthenticated, login, logout, setSession },
  } = useStores();

  const history = useHistory();
  const { t } = useTranslation();

  const query = useQuery();
  const queryFailed = query.get('failed');
  const queryToken = query.get('token');

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    } else if (queryToken) {
      setSession(queryToken);
    } else if (queryFailed === 'true') {
      logout();
    }
  }, [queryToken, queryFailed, logout, isAuthenticated, history, setSession]);

  if (queryToken !== null || isAuthenticated) {
    return null;
  }

  return (
    <UnauthLayout>
      <FormContainer>
        <Button label={t('login.login')} onClick={() => login()} />
        {queryFailed === 'true' ? (
          <Typography color="error" align="center">
            {t('login.failed')}
          </Typography>
        ) : null}
      </FormContainer>
    </UnauthLayout>
  );
});
