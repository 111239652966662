import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useStores } from '../../stores';
import { COLORS } from '../../styles';
import { ScreenAction } from '../../types';
import { SideMenu } from '../SideMenu';
import { Header } from './Header';
import { ScreenHeader } from './ScreenHeader';

interface LayoutProps {
  title?: string;
  screenActions?: Array<ScreenAction>;
  hideHeader?: boolean;
  allowOverflow?: boolean;
  backButton?: boolean;
}

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    display: 'flex',
    backgroundColor: COLORS.white,
  },
  contentContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  sidebar: {
    background: COLORS.primary,
    height: '100vh',
    width: '365px',
    minWidth: '365px',
    [`${theme.breakpoints.down('md')}`]: {
      width: '250px',
      minWidth: '250px',
    },
    overflowY: 'scroll',
  },
  logoContainer: {
    padding: '40px',
    color: COLORS.white,
  },
  contentWrapper: (props: { allowOverflow?: boolean }) => ({
    marginTop: '40px',
    padding: '0px 50px 40px 50px',
    height: '100%',
    width: '100%',
    overflow: props.allowOverflow ? 'auto' : 'hidden',
  }),
}));

export const Layout: FC<LayoutProps> = observer(
  ({ title, screenActions, children, hideHeader, allowOverflow, backButton = false }) => {
    const {
      authStore: { user, isAuthenticated },
    } = useStores();
    const history = useHistory();
    const classes = useStyles({ allowOverflow });

    if (!isAuthenticated || !user) {
      history.push('/login');
    }

    const location = useLocation();
    const currentRoute = user ? ROUTES(user.role).find((route) => route.matchesLocation(location.pathname)) : null;

    return user ? (
      <div className={classes.layoutContainer}>
        <div className={classes.sidebar}>
          <Box>
            <div className={classes.logoContainer}>
              <img src="/logo.svg" alt="Martat"></img>
            </div>
            <SideMenu role={user.role} />
          </Box>
        </div>
        <div className={classes.contentContainer}>
          <Header />
          {currentRoute && !hideHeader && (
            <ScreenHeader backButton={backButton} title={title} route={currentRoute} screenActions={screenActions} />
          )}
          <div className={classes.contentWrapper}>{children}</div>
        </div>
      </div>
    ) : null;
  },
);
